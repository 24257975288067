import React, { Fragment } from 'react'
import { Container, Grid, Typography, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Swal from 'sweetalert2';
const Footer = () => {
  const handleAppDownload = () => {
    Swal.fire({
      title: 'DOWNLOAD THE RAD4 APP',
      imageUrl: '/RAD4QR.png', // Replace with your image URL
      imageAlt: 'Download RAD4 App',
      html: `
        <p>Scan the QR code or click the link below to download the app:</p>
        <a href="https://drive.google.com/drive/folders/1oSZkr1OtPtKhZwrGItYBC8nIjKVr9tOM" target="_blank" rel="noopener noreferrer">Download RAD4 App</a>
      `,
      showCloseButton: true,
    });
  };
    return (
      <footer style={{ marginTop: '20px', backgroundColor: '#101a11', color: '#026736' }}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                About
              </Typography>
              <Typography variant="body2" color="#038948">
               <i>Request And Delivery (RAD4)</i> is a system made for the purpose of optmizing communication and transaction between students and faculty members. Featuring a self-navigating robot to deliver and retrieve the needs of students and professors alike.
              </Typography>
            </Grid>
  
            <Grid item xs={6} md={3}>
              <Typography variant="h6" gutterBottom>
                Platforms
              </Typography>
              <ul>
                <li><Link color='#038948' href="#">React JS</Link></li>
                <li><Link color='#038948' href="#">Javascript</Link></li>
                <li><Link color='#038948' href="#">HTML</Link></li>
                <li><Link color='#038948' href="#">CSS</Link></li>
                <li><Link color='#038948' href="#">Mongo DB</Link></li>
                <li><Link color='#038948' href="#">Cloudinary</Link></li>
              </ul>
            </Grid>
  
            <Grid item xs={6} md={3}>
              <Typography variant="h6" gutterBottom>
                Quick Links
              </Typography>
              <ul>
                <li><Link color='#038948' href="/terms-of-use">Terms Of Use</Link></li>
                <li><Link color='#038948'  href="#" onClick={handleAppDownload}>Download Mobile App</Link></li>
              </ul>
            </Grid>
          </Grid>
  
          <hr />
  
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Typography variant="body2" color="#026736">
                Copyright &copy; 2023 All Rights Reserved by <Link color='#038948' href="#">RAD4 - Request and Delivery Robot</Link>.
              </Typography>
            </Grid>
  
            <Grid item xs={12} md={4}>
              <IconButton color="primary" aria-label="Facebook"><FacebookIcon /></IconButton>
              <IconButton color="info" aria-label="Twitter"><TwitterIcon /></IconButton>
              <IconButton color="primary" aria-label="LinkedIn"><LinkedInIcon /></IconButton>
              <IconButton color="error" aria-label="Mail"><MailOutlineIcon /></IconButton>
            </Grid>
          </Grid>
        </Container>
      </footer>
    );
  };
export default Footer