import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataGrid } from "@mui/x-data-grid";
import MetaData from "../layout/MetaData";
import Loader from "../layout/loader";
import { useDispatch, useSelector } from "react-redux";
// import { allUsers, clearErrors, deleteUser, verifyUser } from '../../actions/userActions';
import {
  getAdminAnnouncements,
  deleteAnnouncement,
  clearErrors,
} from "../../actions/announcementActions";
import {
  DELETE_ANNOUNCEMENT_RESET,
  ANNOUNCEMENT_DETAILS_RESET,
  CLEAR_ERRORS,
} from "../../constants/announcementConstants";
import DashSidebar from "./sidebar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography, Box, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import { dGridStyle } from "../chart/dataGridStyles";

const AnnouncementsList = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { loading, error, announcements } = useSelector(state => state.announcements);
    const { error: deleteError, isDeleted } = useSelector(state => state.announcement);
    const successMsg = (message = '') => toast.success(message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
      const errMsg = (message = '') => toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    useEffect(() => {
      dispatch({type: ANNOUNCEMENT_DETAILS_RESET})
        dispatch(getAdminAnnouncements());
        if (error) {
            errMsg(error);
            dispatch(clearErrors())
        }
        if (deleteError) {
            errMsg(deleteError);
            dispatch(clearErrors())
        }
        if (isDeleted) {
            
            navigate('/admin/announcements');
            dispatch({ type: DELETE_ANNOUNCEMENT_RESET })
            Swal.fire({
              title: "Deleted!",
              text: "Announcement Successfully Deleted.",
              icon: "success"
            });
        }
  }, [dispatch, error, navigate, isDeleted, deleteError]);

  const deleteAnnouncementHandler = (id) => {
    Swal.fire({
      title: "Delete this Announcement?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
      dispatch(deleteAnnouncement(id));
      }
    });
   
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "title",
      headerName: "Title",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "publisher",
      headerName: "Publisher",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "createdAt",
      headerName: "createdAt",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.6,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Link
              to={`/admin/announcement/${params.row.id}`}
            className="btn btn-info py-1 px-2"
          >
            <EditIcon />
          </Link>
          <IconButton
            onClick={() => deleteAnnouncementHandler(params.row.id)}
            className="btn btn-danger py-1 px-2 ml-2"
          >
            <DeleteIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = announcements
    ? announcements.map((announcement) => ({
        id: announcement._id,
        title: announcement.name,
        description: announcement.description,
        publisher: announcement.publisher.username,
        createdAt: announcement.createdAt,
        // role: user.role,
        // isValidatedUser: user.isValidatedUser,
      }))
    : [];

  return (
    <><MetaData title={'Announcements List'}/>
      <div sx={{ flexGrow: 1 }}>
        <Grid container direction="row">
          <Grid item xs={2} md={2}>
            <DashSidebar />
          </Grid>
          <Grid item xs={10} md={10}>
            <Fragment>
              <Typography variant="h4" sx={{ margin: 2 }}>
                <div class="thirteen">
                  <h1>Announcements</h1>
                </div>
              </Typography>
              {loading ? (
                <Loader />
              ) : (
                <Box sx={{ height: 500, width: "90%", margin: "auto" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    autoPageSize
                    rowsPerPageOptions={[10, 25, 50]}
                    //   checkboxSelection
                    disableSelectionOnClick
                    sx={dGridStyle}
                  />
                </Box>
              )}
            </Fragment>
          </Grid>
        </Grid>
        <Link to="/create-announcement">
          <Fab
            color="success"
            aria-label="add"
            sx={{ position: "fixed", top: 500, right: 40, zIndex: "20" }}
          >
            <AddIcon />
          </Fab>
        </Link>
      </div>
    </>
  );
};

export default AnnouncementsList;
