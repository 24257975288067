import axios from 'axios'
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    GOOGLE_LOGIN_REQUEST,
    GOOGLE_LOGIN_SUCCESS,
    GOOGLE_LOGIN_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    SET_PROFILE_REQUEST,
    SET_PROFILE_SUCCESS,
    SET_PROFILE_FAIL,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    NEW_PASSWORD_REQUEST,
    NEW_PASSWORD_SUCCESS,
    NEW_PASSWORD_FAIL,
    ALL_USERS_REQUEST,
    ALL_USERS_SUCCESS,
    ALL_USERS_FAIL,
    ALL_ACTIVE_REQUEST,
    ALL_ACTIVE_SUCCESS,
    ALL_ACTIVE_FAIL,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    USER_SALES_REQUEST,
    USER_SALES_SUCCESS,
    USER_SALES_FAIL,
    VERIFY_USER_REQUEST,
VERIFY_USER_SUCCESS,
VERIFY_USER_RESET,
VERIFY_USER_FAIL,
UPDATE_STATUS_REQUEST,
UPDATE_STATUS_SUCCESS,
UPDATE_STATUS_RESET,
UPDATE_STATUS_FAIL,
    CLEAR_ERRORS
} from '../constants/userConstants'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const verifyUser = (userId, isValidatedUser) => async (dispatch) => {
    try {
        dispatch({ type: VERIFY_USER_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }

        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/verify-user/${userId}`, {isValidatedUser}, config)
        dispatch({
            type: VERIFY_USER_SUCCESS,
            payload: data.success
        })

         // Dispatch the CLEAR_SEND_STATE action to reset the send state
         dispatch({ type: VERIFY_USER_RESET });
    } catch (error) {
        dispatch({
            type: VERIFY_USER_FAIL,
            payload: error.response ? error.response.data.message : 'Unknown error'
        })
    }
}

export const updateStatus = (userId, status) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_STATUS_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }

        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/update-status/${userId}`, {status}, config)
        dispatch({
            type: UPDATE_STATUS_SUCCESS,
            payload: data.success
        })

         // Dispatch the CLEAR_SEND_STATE action to reset the send state
         dispatch({ type: UPDATE_STATUS_RESET });
    } catch (error) {
        dispatch({
            type: UPDATE_STATUS_FAIL,
            payload: error.response ? error.response.data.message : 'Unknown error'
        })
    }
}




export const glogin = (response) => async (dispatch) => {
    // // console.log(response);
    try {
      dispatch({ type: GOOGLE_LOGIN_REQUEST });
      const { data } = await axios.post(`${process.env.REACT_APP_API}/api/v1/googlelogin`,
        { response},
       {withCredentials:true}
      );
    //   // console.log(data)
      localStorage.setItem("user",JSON.stringify(data.user));
      dispatch({
        type:  GOOGLE_LOGIN_SUCCESS,
        payload: data.user,
      });
    } catch (error) {
        // notify(error)
        // // console.log(error.response.data.message)
      dispatch({
        type:  GOOGLE_LOGIN_FAIL,
        payload: error.response.data.message,
      });
  
    }
  };


  export const login = (email, password) => async (dispatch) => {
    const notify = (error) => toast.error(error, {
        position: toast.POSITION.BOTTOM_CENTER
    })
    try {
        dispatch({ type: LOGIN_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }

        const { data } = await axios.post(`${process.env.REACT_APP_API}/api/v1/login`, { email, password }, config)
        dispatch({
            type: LOGIN_SUCCESS,
            payload: data.user
        })
    } catch (error) {
        // console.log(error.response)
        notify(error)
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response.data.message
        })
    }
}

export const setUserProfile = (userData) => async (dispatch) => {
    try {
        dispatch({ type: SET_PROFILE_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }
        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/set-profile`, userData, config)
        dispatch({
            type: SET_PROFILE_SUCCESS,
            payload: data.user
        })
    } catch (error) {
        dispatch({
            type: SET_PROFILE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const registeruser = (userData) => async (dispatch) => {
    try {
        dispatch({ type: REGISTER_USER_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true,
        }
        const { data } = await axios.post(`${process.env.REACT_APP_API}/api/v1/register`, userData, config)
        dispatch({
            type: REGISTER_USER_SUCCESS,
            payload: data.user
        })
    } catch (error) {
        dispatch({
            type: REGISTER_USER_FAIL,
            payload: error.response.data.message
        })
    }
}



export const loadUser = () => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }
        dispatch({ type: LOAD_USER_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/me`,config)
        dispatch({
            type: LOAD_USER_SUCCESS,
            payload: data.user
        })
    } catch (error) {
        dispatch({
            type: LOAD_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

export const logout = () => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }
        
        await axios.get(`${process.env.REACT_APP_API}/api/v1/logout`,config)
        sessionStorage.clear();
    localStorage.clear();
        dispatch({
            type: LOGOUT_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: LOGOUT_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateProfile = (userData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROFILE_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true,
        }
        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/me/update`, userData, config)
        dispatch({
            type: UPDATE_PROFILE_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_PROFILE_FAIL,
            payload: error.response.data.message
        })
    }

}

export const updatePassword = (passwords) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PASSWORD_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true,
        }
        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/password/update`, passwords, config)
        dispatch({
            type: UPDATE_PASSWORD_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_PASSWORD_FAIL,
            payload: error.response.data.message
        })
    }
}

export const forgotPassword = (email) => async (dispatch) => {
    try {
        dispatch({ type: FORGOT_PASSWORD_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.post('/api/v1/password/forgot', email, config)
        dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: data.message
        })
    } catch (error) {
        dispatch({
            type: FORGOT_PASSWORD_FAIL,
            payload: error.response.data.message
        })
    }

}

export const resetPassword = (token, passwords) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PASSWORD_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.put(`/api/v1/password/reset/${token}`, passwords, config)
        dispatch({
            type: NEW_PASSWORD_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: NEW_PASSWORD_FAIL,
            payload: error.response.data.message
        })
    }

}
export const allUsers = () => async (dispatch) => {
    try {
        dispatch({ type: ALL_USERS_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/admin/users`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
        dispatch({
            type: ALL_USERS_SUCCESS,
            payload: data.users
        })
    } catch (error) {
        dispatch({
            type: ALL_USERS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const activeProfs = () => async (dispatch) => {
    try {
        // console.log('actions activProfs has run')
        dispatch({ type: ALL_ACTIVE_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/active-profs`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
        dispatch({
            type: ALL_ACTIVE_SUCCESS,
            payload: data.users
        })
    } catch (error) {
        dispatch({
            type: ALL_ACTIVE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateUser = (id, userData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_USER_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/admin/user/${id}`, userData, config)
        dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getUserDetails = (id) => async (dispatch) => {
    
    try {
        dispatch({ type: USER_DETAILS_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/admin/user/${id}`,config)
        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data.user
        })
    } catch (error) {
        dispatch({
            type: USER_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}
export const deleteUser = (id) => async (dispatch) => {

    try {
        dispatch({ type: DELETE_USER_REQUEST })
        const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/v1/admin/user/${id}`, {withCredentials: true})
        dispatch({
            type: DELETE_USER_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: DELETE_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

export const userSales = () => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }
        dispatch({ type: USER_SALES_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/orders/customer-sales`,config)
        dispatch({
            type: USER_SALES_SUCCESS,
            payload: data.customerSales,
        })
    } catch (error) {
        dispatch({
            type: USER_SALES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS

    })
}