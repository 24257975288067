
export const REQUEST_STATISTICS_REQUEST = 'REQUEST_STATISTICS_REQUEST' 
export const REQUEST_STATISTICS_SUCCESS = 'REQUEST_STATISTICS_SUCCESS'
export const REQUEST_STATISTICS_FAIL = 'REQUEST_STATISTICS_FAIL'
export const REQUEST_STATISTICS_RESET = 'REQUEST_STATISTICS_RESET'

export const REQUEST_HISTORY_REQUEST = 'REQUEST_HISTORY_REQUEST' 
export const REQUEST_HISTORY_SUCCESS = 'REQUEST_HISTORY_SUCCESS'
export const REQUEST_HISTORY_FAIL = 'REQUEST_HISTORY_FAIL'
export const REQUEST_HISTORY_RESET = 'REQUEST_HISTORY_RESET'


export const DELIVERY_STATISTICS_REQUEST = 'DELIVERY_STATISTICS_REQUEST' 
export const DELIVERY_STATISTICS_SUCCESS = 'DELIVERY_STATISTICS_SUCCESS'
export const DELIVERY_STATISTICS_FAIL = 'DELIVERY_STATISTICS_FAIL'
export const DELIVERY_STATISTICS_RESET = 'DELIVERY_STATISTICS_RESET'

export const DELIVERY_HISTORY_REQUEST = 'DELIVERY_HISTORY_REQUEST' 
export const DELIVERY_HISTORY_SUCCESS = 'DELIVERY_HISTORY_SUCCESS'
export const DELIVERY_HISTORY_FAIL = 'DELIVERY_HISTORY_FAIL'
export const DELIVERY_HISTORY_RESET = 'DELIVERY_HISTORY_RESET'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

