export const ALL_ROOMKEYS_REQUEST = 'ALL_ROOMKEYS_REQUEST'
export const ALL_ROOMKEYS_SUCCESS = 'ALL_ROOMKEYS_SUCCESS'
export const ALL_ROOMKEYS_FAIL = 'ALL_ROOMKEYS_FAIL'

export const ROOMKEY_DETAILS_REQUEST = 'ROOMKEY_DETAILS_REQUEST' 
export const ROOMKEY_DETAILS_SUCCESS = 'ROOMKEY_DETAILS_SUCCESS'
export const ROOMKEY_DETAILS_RESET = 'ROOMKEY_DETAILS_RESET'
export const ROOMKEY_DETAILS_FAIL = 'ROOMKEY_DETAILS_FAIL'

export const ADMIN_ROOMKEYS_REQUEST = 'ADMIN_ROOMKEYS_REQUEST'
export const ADMIN_ROOMKEYS_SUCCESS = 'ADMIN_ROOMKEYS_SUCCESS'
export const ADMIN_ROOMKEYS_FAIL = 'ADMIN_ROOMKEYS_FAIL'

export const NEW_ROOMKEY_REQUEST = 'NEW_ROOMKEY_REQUEST'
export const NEW_ROOMKEY_SUCCESS = 'NEW_ROOMKEY_SUCCESS'
export const NEW_ROOMKEY_RESET = 'NEW_ROOMKEY_RESET'
export const NEW_ROOMKEY_FAIL = 'NEW_ROOMKEY_FAIL'


export const UPDATE_ROOMKEY_REQUEST = 'UPDATE_ROOMKEY_REQUEST'
export const UPDATE_ROOMKEY_SUCCESS = 'UPDATE_ROOMKEY_SUCCESS'
export const UPDATE_ROOMKEY_RESET = 'UPDATE_ROOMKEY_RESET'
export const UPDATE_ROOMKEY_FAIL = 'UPDATE_ROOMKEY_FAIL'

export const DELETE_ROOMKEY_REQUEST = 'DELETE_ROOMKEY_REQUEST'
export const DELETE_ROOMKEY_SUCCESS = 'DELETE_ROOMKEY_SUCCESS'
export const DELETE_ROOMKEY_RESET = 'DELETE_ROOMKEY_RESET'
export const DELETE_ROOMKEY_FAIL = 'DELETE_ROOMKEY_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

