import React, { useState, useCallback, useEffect, useRef } from 'react'
import MetaData from './layout/MetaData'
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from 'react-redux';
import Loader from './layout/loader';
import { Box, Avatar, Button, Typography, Grid, Paper } from '@mui/material';
import { getMyRequests } from '../actions/requestActions';
import { updateRequest } from '../actions/requestActions';
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Swal from 'sweetalert2';
import { UPDATE_REQUEST_RESET } from '../constants/requestConstants';
import { clearErrors } from '../actions/requestActions';
import useWebSocket from '../socket/webSocket';
import CircularProgress from '@mui/material/CircularProgress';

const minimalDate = (createdAt) => {
    const date = new Date(createdAt);
    const now = new Date();
    // If more than a day ago, return month day, year format
    const options = { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toLocaleDateString('en-US', options);
};

const RequestsSection = () => {
    const listRef = useRef({});
    const rowHeights = useRef({});
    const dispatch = useDispatch();
    const { myRequests } = useSelector(state => state.myRequests);
    const { error: updateError, isUpdated } = useSelector(state => state.requests);
    const { user } = useSelector(state => state.auth);
    const [denyReq, setDReq] = useState(false)
    const [QRequests, setQRequests] = useState(null)
    const { grantReq, setGReq } = useState(false)
    const { socket, isConnected } = useWebSocket(`${process.env.REACT_APP_WEBSOCKET_API}`);
    useEffect(() => {
        dispatch(getMyRequests());
    }, [])

    useEffect(() => {
        const inQueueRequests = myRequests?.filter(request => request.status === 'In queue');
        setQRequests(inQueueRequests);
    }, [myRequests])

    useEffect(() => {
        // console.log('QRequests:', QRequests)
    }, [QRequests])

    useEffect(() => {
        if (isConnected) {
            const handleRequest = (requestSent) => {
                // Check if data is equal to 'RequestSent'
                if (requestSent.userId === user?._id) {
                    // If data matches 'RequestSent', dispatch the action to fetch requests
                    dispatch(getMyRequests());
                }
            };

            socket.on("request", handleRequest);

            // Clean up the event listener when component unmounts or when isConnected changes
            return () => {
                socket.off("request", handleRequest);
            };
        }
    }, [isConnected, socket, user, dispatch]); // Include dispatch in the dependency array to prevent unnecessary reconnections
    // // console.log('here are the announcements: ', myRequests)

    const getRequestDescription = (code, keyNo) => {
        const codeMap = {
            getItem: "Get Item",
            getItemWKey: `Get Item & Key for Room ${keyNo}` ,
            getKey: `Get Key for Room ${keyNo}`,
            returnItem: "Return Item"
        };

        // Use the code as a key to look up its description in the codeMap
        return codeMap[code] || "Unknown Request";
    };


    const handleRequestGrant = async (requestInfo) => {
       await Swal.fire({
            title: "Request Details",
            html: `Requested By: ${requestInfo?.user?.username} ${requestInfo?.user?.coYeSe} <br> Request type: ${getRequestDescription(requestInfo.code, requestInfo.key?.keyNo)}`,
            showCancelButton: true,
            confirmButtonText: "GRANT",
            confirmButtonColor: "#355e3b",

        }).then((result) => {
            // Check if the confirm button is pressed
            if (result.isConfirmed) {
                setDReq(false)
                const formData = new FormData();
                formData.set('status', 'Granted');
                dispatch(updateRequest(requestInfo._id, formData));
            }
        });
    }
    const handleRequestDeny = async (requestId) => {
        const { value: text } = await Swal.fire({
            title: "DENY REQUEST",
            input: "textarea",
            inputValue: "Sorry, it's unavailable.",
            inputLabel: "Provide a reason for denying",
            inputPlaceholder: "Type your message here...",
            inputAttributes: {
                "aria-label": "Type your message here"
            },
            showCancelButton: true,
            confirmButtonText: "Deny", // Set initial confirm button text
            confirmButtonColor: "#dc3545", // Set initial confirm button color
            inputValidator: (value) => {
                // Validate input value (textarea content)
                if (!value || value.trim() === "") {
                    return "Please provide a reason for denying."; // Error message if input is empty
                }
            }
        });
        if (text) {
            setDReq(true)
            const formData = new FormData();
            formData.set('status', 'Denied');
            formData.set('reason', text);
            dispatch(updateRequest(requestId, formData));
        }
    };

    useEffect(() => {
        if (isUpdated) {
            if (denyReq) {
                // Display success message for denying the request
                Swal.fire({
                    title: "Denied!",
                    text: "Request Successfully Denied.",
                    icon: "success"
                });
                setDReq(false); // Reset denyReq state to false
            } else {
                Swal.fire({
                    title: "Granted!",
                    text: "Request Successfully Granted and added to the Delivery queue.",
                    icon: "success"
                });
            }

            // Reset the UPDATE_REQUEST_RESET action in Redux state
            dispatch({ type: UPDATE_REQUEST_RESET });
            dispatch(getMyRequests())
        }
    }, [isUpdated, updateError,
        // denyReq, 
        dispatch]); // Include all dependencies in the dependency array


    function getRowHeight(index) {
        return rowHeights.current[index] + 8 || 82;
    }

    function Row({ index, style }) {
        const rowRef = useRef({});

        useEffect(() => {
            if (rowRef.current) {
                setRowHeight(index, rowRef.current.clientHeight);
            }
            // eslint-disable-next-line
        }, [rowRef]);

        const request = QRequests[QRequests?.length - index - 1];
        return (
            <>

                <div style={style} key={QRequests?._id}>


                    <Box sx={{ m: 3, paddingRight: 3 }} ref={rowRef}>
                        <div className="card" data-label={minimalDate(request?.createdAt)}>
                            <div className="card__container">
                                <Grid container direction="row">
                                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar src={request?.user?.avatar?.url} sx={{ width: 60, height: 60 }} />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant='h6' sx={{ marginTop: '0.5rem', marginBottom: '1rem', color: '#303030' }}>
                                            <strong>{request?.user?.coYeSe} {request?.user?.username}</strong>
                                            <Typography variant="subtitle1" color="text.secondary">
                                                {request?.caption}
                                            </Typography>
                                        </Typography>
                                        <p className="card__body">
                                            <Grid container direction="row" sx={{ marginBottom: '-1rem' }}>
                                                <Grid item xs={6}>
                                                    <Button variant="contained" color="success" sx={{ height: 40, width: 90 }}
                                                    onClick={() => handleRequestGrant(request)}
                                                    >Accept</Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button variant="contained" color="error" sx={{ height: 40, width: 90 }}
                                                        onClick={() => handleRequestDeny(request?._id)}
                                                    >Deny</Button>
                                                </Grid>
                                            </Grid>

                                        </p>
                                    </Grid>

                                </Grid>

                            </div>
                        </div>
                    </Box>
                </div>

            </>
        )
    }

    function setRowHeight(index, size) {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
    }



    return (
        <Box sx={{ width: '100%', }}>
            <Paper elevation={8} sx={{ borderRadius: 2, width: '100%', height: '99vh' }} className={"profilecard"}>

                <div className='eight'>
                    <h1>Delivery Requests</h1>
                </div>
                <Box sx={{ height: '79vh', overflow: 'auto' }}>
                    {QRequests?.length > 0 ?
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    height={height}
                                    itemCount={QRequests?.length}
                                    width={width}
                                    itemSize={getRowHeight}
                                    ref={listRef}
                                // onItemsRendered={handleItemsRendered}
                                >
                                    {Row}

                                </List>
                            )}
                        </AutoSizer>

                        :
                        <Typography sx={{ height: '100%', color: '#404040', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Requests to show for now</Typography>}

                </Box>


            </Paper>



        </Box>
    );
}

export default RequestsSection