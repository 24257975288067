import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import MetaData from '../layout/MetaData'
import { pBtnColor } from '../styles/paabot-styles'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from "react-hook-form";
import { resetPassword, clearErrors } from '../../actions/userActions'
import { Box, Button, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';



const NewPassword = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { error, success } = useSelector(state => state.forgotPassword)
    let { token } = useParams();
    const goBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        if (error) {
            // alert.error(error);
            dispatch(clearErrors());
        }
        if (success) {
            // alert.success('Password updated successfully')
            navigate('/login')
        }
    }, [dispatch,  error, success, navigate])
    const submitHandler = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.set('password', password);
        formData.set('confirmPassword', confirmPassword);
        dispatch(resetPassword(token, formData))
    }



    return (

        <Fragment>



            <MetaData title={'New Password Reset'} />
            <Box>
            <MetaData title={'Login'} />
            <IconButton sx={{ ml: 3, mb: -20,
         '&:hover': {
            // Add styles for hover state
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            color: '#49B265',
            // Add any other styles you want to apply on hover
          },    
        }} onClick={() => {
                goBack()
            }}><ArrowBack sx={{ fontSize: 35, color: '#000',  
            '&:hover': {
                // Add styles for hover state
                color: '#49B265',
                // Add any other styles you want to apply on hover
              },     }}></ArrowBack></IconButton>
            <Grid container justifyContent="center">
              <Grid item xs={10} lg={5} my={5}>
                <Paper elevation={12} sx={{ padding: 3 }}>
                  <form className="shadow-lg" onSubmit={handleSubmit(submitHandler)}>
                    <Typography variant="h4" sx={{ marginBottom: '70px', marginTop: '-30px' }}>
                      
                      <div className='thirteen'>
                        <h1>New Password</h1>

                      </div>
                      
                      </Typography>

                    <Box mb={3}>
                      <TextField
                        id="password_field"
                        label="Password"
                        variant="outlined"
                        fullWidth
                        {...register("password", {
                          required: "password field cannot be empty.",
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message: "Invalid Email format!"
                          }
                        })}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={!!errors.password}
                        helperText={errors.password && <i>{errors.password.message}</i>}
                      />
                    </Box>

                    <Box mb={3}>
                      <TextField
                        type="confirmPassword"
                        id="confirmpassword_field"
                        label="Confirm Password"
                        variant="outlined"
                        fullWidth
                        {...register("confirmPassword", { required: "Confirm is required!" })}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword && <i>{errors.confirmPassword.message}</i>}
                      />
                    </Box>
                    <br></br>
                    <Button
                      className="submit_button"
                      type="submit"
                      variant='contained'
                      fullWidth
                      sx={{ py: 3, ...pBtnColor }}
                    >
                     SET PASSWORD
                    </Button>
                  </form>
                  {/* <p>Don't have an account yet?  <Link to="/register" className='mx-4'>Register Now</Link></p> */}
                </Paper>
              </Grid>
            </Grid>


          </Box>


            {/* <div className="row wrapper">

                <div className="col-10 col-lg-5">

                    <form className="shadow-lg" onSubmit={submitHandler}>

                        <h1 className="mb-3">New Password</h1>



                        <div className="form-group">

                            <label htmlFor="password_field">Password</label>

                            <input

                                type="password"

                                id="password_field"

                                className="form-control"

                                value={password}

                                onChange={(e) => setPassword(e.target.value)}

                            />

                        </div>



                        <div className="form-group">

                            <label htmlFor="confirm_password_field">Confirm Password</label>

                            <input

                                type="password"

                                id="confirm_password_field"

                                className="form-control"

                                value={confirmPassword}

                                onChange={(e) => setConfirmPassword(e.target.value)}

                            />

                        </div>



                        <button

                            id="new_password_button"

                            type="submit"

                            className="btn btn-block py-3">

                            Set Password

                        </button>



                    </form>

                </div>

            </div> */}



        </Fragment>

    )

}



export default NewPassword