export const dGridStyle = {
    borderColor: '#000',
            borderWidth: '1.4px',
            '& .MuiDataGrid-cell': {
              borderColor: '#000 !important', // Change cell border color
              borderWidth: '1.4px',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderColor: '#000 !important', // Change header border color
              borderWidth: '1.4px',
            },
            '& .MuiDataGrid-virtualScroller': {
              borderColor: '#000 !important', // Change virtual scroller border color
              borderWidth: '1.4px',
            },
};