import axios from 'axios'
import * as rk from '../constants/roomKeyConstants';

export const getRoomKeyDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: rk.ROOMKEY_DETAILS_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
       const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/roomkey/${id}`, config)
        dispatch({
            type: rk.ROOMKEY_DETAILS_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: rk.ROOMKEY_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const allRoomKeys = () => async (dispatch) => {
    try {
    
        dispatch({ type: rk.ALL_ROOMKEYS_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/roomkeys`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: rk.ALL_ROOMKEYS_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: rk.ALL_ROOMKEYS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const addRoomKey = (roomKeyData) => async (dispatch) => {
    try {
    
        dispatch({ type: rk.NEW_ROOMKEY_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.post(`${process.env.REACT_APP_API}/api/v1/admin/roomkey/new`, roomKeyData, config)
         
        dispatch({
            type: rk.NEW_ROOMKEY_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: rk.NEW_ROOMKEY_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateRoomKey = (roomKeyId, roomKeyData) => async (dispatch) => {
    try {
    
        dispatch({ type: rk.UPDATE_ROOMKEY_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/admin/roomkey/${roomKeyId}`, roomKeyData, config)
         
        dispatch({
            type: rk.UPDATE_ROOMKEY_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: rk.UPDATE_ROOMKEY_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deleteRoomKey = (roomKeyId) => async (dispatch) => {
    try {
    
        dispatch({ type: rk.DELETE_ROOMKEY_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/v1/admin/roomkey/${roomKeyId}`, config)
         
        dispatch({
            type: rk.DELETE_ROOMKEY_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: rk.DELETE_ROOMKEY_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: rk.CLEAR_ERRORS

    })
}