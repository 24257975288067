import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton, Paper } from '@mui/material';
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pie } from 'react-chartjs-2';
import { allRoomKeys } from '../../actions/roomKeyActions';
import Loader from '../layout/loader';

const KeyRatioPieChart = ({ dashboard }) => {
    const dispatch = useDispatch();
    const {loading, error, roomKeys} = useSelector((state) => state.roomKeys)
useEffect(()=>{
    if(!roomKeys){
        dispatch(allRoomKeys())
    }
},[])

const countAvailableAndMissingKeys = (roomKeys) => {
    let availableKeys = [];
    let missingKeys = [];
  
    Object.values(roomKeys).forEach((key) => {
      if (key.status === 'Available') {
        availableKeys.push(key.keyNo);
      } else if (key.status === 'Missing') {
        missingKeys.push(key.keyNo);
      }
    });
  
    return { available: availableKeys, missing: missingKeys };
  };
  
  const { available, missing } = countAvailableAndMissingKeys(roomKeys);
  
  const data = {
    labels: [
        available.length > 0 ? `Available: ${available.join(', ')}` : 'Available',
        missing.length > 0 ? `Missing: ${missing.join(', ')}` : 'Missing',
      ],
    datasets: [
      {
        data: [available.length, missing.length],
        backgroundColor: ['#0088FE' , '#FF8042'] , // Set color based on availability
        hoverBackgroundColor: ['#005c99' , '#cc6600'], // Set hover color based on availability
      },
    ],
  };

  const options = {
    aspectRatio: 1, // Adjust this value to change the aspect ratio
    maintainAspectRatio: false, // Set to false to allow the chart to resize based on container size    
    plugins: {
        legend: {
            position: 'right', // Set the legend position to the right side
        },
    },
};

  return (
    <div>
    {loading ? (
     <Loader/>
    ) : error ? (
      <p>Error fetching chart data: {error}</p>
    ) : (
    <div >
        <IconButton sx={{
          ml: 3, mb: -10,
          '&:hover': {
            // Add styles for hover state
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            color: '#49B265',
            // Add any other styles you want to apply on hover
          },
        }} 
        onClick={dashboard}
        ><ArrowBack sx={{
          fontSize: 35, color: '#000',
          '&:hover': {
            // Add styles for hover state
            color: '#49B265',
            // Add any other styles you want to apply on hover
          },
        }}></ArrowBack></IconButton>
      <div className='thirteen' style={{marginBottom: 20}}>
          <h1>Room Keys Report</h1> </div>
          <Paper elevation={8} sx={{width: '70%', height: '10%', m:'auto'}}>
          <div className='seven'>
          <h1>Room Keys Status</h1> </div>
          <Box sx={{ mx: 'auto', height: '50vh' }}>
           
      <Pie data={data} options={options}/></Box>
 </Paper>
 </div>
     )}
     </div>
  );
};

export default KeyRatioPieChart;
