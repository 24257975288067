
import React, { Fragment, useState, useEffect, useRef } from 'react'
import DashSidebar from './sidebar';
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Typography, Box, Avatar } from '@mui/material';
import PeopleIcon from "@mui/icons-material/People";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import BugReportOutlined from "@mui/icons-material/BugReportOutlined";
import KeyIcon from "@mui/icons-material/Key";
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocationOn from "@mui/icons-material/LocationOn";
import { lime, brown, blueGrey, pink } from "@mui/material/colors";
import { allCourses } from '../../actions/courseActions';
import { allCoordinates } from '../../actions/coordinateActions';
import { allBugReports } from '../../actions/bugReportActions';
import { getAdminAnnouncements } from '../../actions/announcementActions';
import { allUsers } from '../../actions/userActions';
import { allRoomKeys } from '../../actions/roomKeyActions';
import { getDlvryStats, getReqStats } from '../../actions/StatActions';

const Dashboard = ({ boxStyle, boxFormat, userchart, bugreportchart, keyreportchart, reqreportchart, dlvryreportchart }) => {
    const dispatch = useDispatch();
    const { users } = useSelector((state) => state.allUsers);
    const { courses } = useSelector((state) => state.allCourses);
    const { bugReports } = useSelector(
        (state) => state.bugReports
    );
    const { coordinates } = useSelector(
        (state) => state.coord
    );
    const { roomKeys } = useSelector(
        (state) => state.roomKeys
    );
    const { announcements } = useSelector(state => state.announcements);
    const {reqReport} = useSelector((state) => state.reqstat)
    const {dlvryReport} = useSelector((state) => state.dlvrystat)

    useEffect(() => {
        dispatch(allBugReports());
        dispatch(allRoomKeys());
        dispatch(allCoordinates());
        dispatch(allCourses());
        dispatch(allUsers());
        dispatch(getAdminAnnouncements());
        dispatch(getReqStats());
        dispatch(getDlvryStats())
    }, [])
    return (
        <Box>
            <Box sx={{ mt: -5, mb: 5, mx: 'auto' }}>
                <div class="thirteen">
                    <h1>Admin Dashboard</h1>
                </div></Box>
            <Grid container spacing={2}>
                <Grid item sx={{ p: 6 }} xs={12} md={3}>
                    <Paper sx={boxStyle} className="hoverblue" onClick={() => users?.length > 0 && userchart()}>
                        <Box sx={boxFormat}>
                            <PeopleIcon color="info" sx={{ fontSize: 60 }} />
                            <Typography variant="button" fontWeight='bold'>Users</Typography>
                            <Typography variant="h4">{users?.length}</Typography>
                        </Box>
                    </Paper>

                </Grid>
                <Grid item sx={{ p: 6 }} xs={12} md={3}>
                    <Paper sx={boxStyle} className="hoverblue">
                        <Box sx={boxFormat}>
                            <MenuBookOutlinedIcon color="success" sx={{ fontSize: 60 }} />
                            <Typography variant="button" fontWeight='bold'>Courses</Typography>
                            <Typography variant="h4">{courses?.length}</Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sx={{ p: 6 }} xs={12} md={3}>
                    <Paper sx={boxStyle} className="hoverblue" onClick={() => roomKeys?.length > 0 && keyreportchart()}>
                        <Box sx={boxFormat}>
                            <KeyIcon color="secondary" sx={{ fontSize: 60 }} />
                            <Typography variant="button" fontWeight='bold'>Room Keys</Typography>
                            <Typography variant="h4">{roomKeys?.length}</Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sx={{ p: 6 }} xs={12} md={3} >
                    <Paper sx={boxStyle} className="hoverblue" onClick={() => bugReports?.length > 0 && bugreportchart()}>
                        <Box sx={boxFormat}>
                            <BugReportOutlined color="error" sx={{ fontSize: 60 }} />
                            <Typography variant="button" fontWeight='bold'>Bug Reports</Typography>
                            <Typography variant="h4">{bugReports?.length}</Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{justifyContent: 'center'}}>
                <Grid item sx={{ p: 6 }} xs={12} md={3}>
                    <Paper sx={boxStyle} className="hoverblue">
                        <Box sx={boxFormat}>
                            <CampaignOutlinedIcon color="warning" sx={{ fontSize: 60 }} />
                            <Typography variant="button" fontWeight='bold' sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Announcements</Typography>
                            <Typography variant="h4">{announcements?.length}</Typography>
                        </Box>
                    </Paper>

                </Grid>
                <Grid item sx={{ p: 6 }} xs={12} md={3}>
                    <Paper sx={boxStyle} className="hoverblue" onClick={() => reqReport?.totalRequests > 0 && reqreportchart()} >
                        <Box sx={boxFormat}>
                            <HistoryEduIcon sx={{ fontSize: 60, color: pink[500] }} />
                            <Typography variant="button" fontWeight='bold'>Requests</Typography>
                            <Typography variant="h4">{reqReport?.totalRequests}</Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sx={{ p: 6 }} xs={12} md={3}>
                    <Paper sx={boxStyle} className="hoverblue" onClick={() => dlvryReport?.totalDeliveries > 0 && dlvryreportchart()}>
                        <Box sx={boxFormat}>
                            <LocalShippingIcon sx={{ fontSize: 60, color: blueGrey[500] }} />
                            <Typography variant="button" fontWeight='bold'>Deliveries</Typography>
                            <Typography variant="h4">{dlvryReport?.totalDeliveries}</Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

        </Box>
    )
};

export default Dashboard;

