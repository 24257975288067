import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import MetaData from "../layout/MetaData";
import Loader from "../layout/loader";
import { useDispatch, useSelector } from "react-redux";
import {
  allUsers,
  clearErrors,
  deleteUser,
  verifyUser,
} from "../../actions/userActions";
import { DELETE_USER_RESET, USER_DETAILS_RESET } from "../../constants/userConstants";
import DashSidebar from "./sidebar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography, Box, Checkbox } from "@mui/material";
import Swal from "sweetalert2";
import { dGridStyle } from "../chart/dataGridStyles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const CheckboxRenderer = ({ value, currentUser, userId }) => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(value);

  const handleCheckboxChange = () => {
    if (currentUser && currentUser.role === "admin") {
      setIsChecked(!isChecked);
      dispatch(verifyUser(userId, !isChecked));
      // // console.log(`Clicked on user ID: ${userId} ${!isChecked}`);
      // You can also perform any additional logic here
    }
  };

  return (
    <Checkbox
      checked={isChecked}
      color="success"
      disabled={currentUser && currentUser.role !== "admin"}
      onChange={handleCheckboxChange}
    />
  );
};

const UsersList = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { loading, error, users } = useSelector((state) => state.allUsers);
  const { user } = useSelector((state) => state.auth);
  const { isDeleted } = useSelector((state) => state.user);
  const errMsg = (message = "") =>
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const successMsg = (message = "") =>
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  useEffect(() => {
    dispatch({type: USER_DETAILS_RESET});
    dispatch(allUsers());
    if (error) {
      errMsg(error);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      Swal.fire({
        title: "Deleted!",
        text: "User Successfully Deleted.",
        icon: "success"
      });
      navigate("/admin/users");
      dispatch({ type: DELETE_USER_RESET });
    }
  }, [dispatch, error, isDeleted, navigate]);

  const deleteUserHandler = (id) => {
    Swal.fire({
      title: "Delete this User?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUser(id));
      }
    });
    // dispatch(deleteUser(id));
  };

  // const useCheckboxState = (initialState) => {
  //     const [isChecked, setIsChecked] = useState(initialState);

  //     const toggleCheckbox = () => {
  //       setIsChecked((prev) => !prev);
  //     };

  //     return [isChecked, toggleCheckbox];
  //   };

  const columns = [
    { field: "createdAt", headerName: "Created At", width: 120 },

    {
      field: "isValidatedUser",
      headerName: "Validated?",
      width: 80,
      renderCell: (params) => (
        <CheckboxRenderer
          value={params.value}
          currentUser={user}
          userId={params.row.id}
        />
      ),
    },
    { field: "username", headerName: "User Name", flex: 0.5, minWidth: 150, align: 'center', headerAlign: 'center' },
    { field: "coYeSe", headerName: "Course Yr. Section", width: 130, align: 'center', headerAlign: 'center' },
    { field: "email", headerName: "Email", width: 200,align: 'center', headerAlign: 'center' },
    { field: "role", headerName: "Role", width: 120,align: 'center', headerAlign: 'center' },
    { field: "id", headerName: "User ID", flex: 0.5, minWidth: 150,align: 'center', headerAlign: 'center' },

    {
      field: "actions",
      headerName: "Actions",
      flex: 0.3,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Link
            to={`/admin/user/${params.row.id}`}
            className="btn btn-info py-1 px-2"
          >
            <EditIcon />
          </Link>
          <IconButton
            onClick={() => deleteUserHandler(params.row.id)}
            className="btn btn-danger py-1 px-2 ml-2"
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = users
    .map((user) => ({
      createdAt: user.createdAt,
      id: user._id,
      username: user.username,
      coYeSe: user.coYeSe,
      email: user.email,
      role: user.role,
      isValidatedUser: user.isValidatedUser,
    }))
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <div sx={{ flexGrow: 1 }}>
      <Grid container direction="row">
        <Grid item xs={2} md={2}>
          <DashSidebar />
        </Grid>
        <Grid item xs={10} md={10}>
          <Fragment>
            <Typography variant="h4" sx={{ margin: 2 }}>
              <div class="thirteen">
                <h1>Users List</h1>
              </div>
            </Typography>
            {loading ? (
              <Loader />
            ) : (
              <Box
                sx={{
                  height: 500,
                  width: "90%",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  autoPageSize
                  rowsPerPageOptions={[10, 25, 50]}
                  disableSelectionOnClick
                  sx={dGridStyle}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                />
              </Box>
            )}
          </Fragment>
        </Grid>
      </Grid>
    </div>
  );
};

export default UsersList;
