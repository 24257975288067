import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import MetaData from '../layout/MetaData'
import DashSidebar from './sidebar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { newAnnouncement, clearErrors } from '../../actions/announcementActions'
import { NEW_ANNOUNCEMENT_RESET } from '../../constants/announcementConstants'
import { FormGroup, FormControlLabel, Checkbox, Grid, Paper, Typography, IconButton, Button, TextField, Avatar, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';



const CreateAnnouncement = () => {
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
//   const onSubmit = data => // console.log(data);
//   const [program, setProgram] = useState('')
//   const [year, setYear] = useState('')
//   const [section, setSection] = useState('')

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }

  const { loading, error, success } = useSelector(state => state.newAnnouncement);
  const errMsg = (message = '') => toast.error(message, {
    position: toast.POSITION.BOTTOM_CENTER
  });
  const successMsg = (message = '') => toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER
  });

  useEffect(() => {
    if (error) {
      errMsg(error);
      dispatch(clearErrors());
    }
    if (success) {
      successMsg('Announcement created successfully.')
      navigate('/admin/announcements')
      dispatch({
        type: NEW_ANNOUNCEMENT_RESET
      })
    }
  }, [dispatch, error, navigate, success])


  const submitHandler = (data) => {
   
    dispatch(newAnnouncement(data))
    // // console.log('submitted data: ', updatedData);
  }

  return (
    <Fragment>
      <MetaData title={'Create Announcement'}/>
      <IconButton sx={{
        ml: 3, mb: -20,
        '&:hover': {
          // Add styles for hover state
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }} onClick={() => {
        goBack()
      }}><ArrowBackIcon sx={{
        fontSize: 35, color: '#000',
        '&:hover': {
          // Add styles for hover state
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }}></ArrowBackIcon></IconButton>

      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item xs={10} lg={5} my={5}>
          <Paper elevation={12} sx={{ padding: 3 }}>
            <Typography variant="h3" sx={{ marginBottom: '50px' }}>
              <div class="thirteen">
                <h1>CREATE ANNOUNCEMENT</h1>
              </div>
            </Typography>

            <form className="shadow-lg" onSubmit={handleSubmit(submitHandler)}>
         
              <Box mb={3}>
                <TextField
                  id="title_name_field"
                  label="Title"
                  variant="outlined"
                  fullWidth
                //   value={program}
                //   onChange={(e) => setProgram(e.target.value)}
                {...register('title', {
                    required: 'Title is required',
                    minLength: {
                      value: 3,
                      message: 'Title must be at least 3 characters',
                    },
                    maxLength: {
                      value: 100,
                      message: 'Title must not exceed 100 characters',
                    },
                  })}
                // Add validation props if needed
                />
                 {errors.name && (
          <Typography variant="caption" color="error">
            {errors.name.message}
          </Typography>
        )}
              </Box>

              <Box mb={3}>
                <TextField
                  id="description_field"
                  label="Description"
                  variant="outlined"
                  fullWidth
                //   value={year}
                //   onChange={(e) => setYear(e.target.value)}
                {...register('description', {
                    required: 'Description is required',
                    minLength: {
                      value: 3,
                      message: 'Description must be at least 3 characters',
                    },
                    maxLength: {
                      value: 100,
                      message: 'Description must not exceed 100 characters',
                    },
                  })}
                // Add validation props if needed
                />
                  {errors.description && (
          <Typography variant="caption" color="error">
            {errors.description.message}
          </Typography>
        )}
              </Box>

              <Button
                type={loading ? 'button' : 'submit'}
                variant="contained"
                fullWidth
                sx={{ py: 3, mt: 2, backgroundColor: '#3f51b5', color: 'white' }}
              >
                {loading ? <CircularProgress sx={{color: '#fff'}} size={20} /> : `PUBLISH` } 
           {/* PUBLISH */}
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CreateAnnouncement

