import axios from 'axios'
import * as dlvry from '../constants/deliveryConstants'

export const getDeliveries = () => async (dispatch) => {
    try {
    
        dispatch({ type: dlvry.ADMIN_DELIVERIES_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/deliveries`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: dlvry.ADMIN_DELIVERIES_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: dlvry.ADMIN_DELIVERIES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getDelivery = (deliveryId) => async (dispatch) => {
    try {
    
        dispatch({ type: dlvry.DELIVERY_DETAILS_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/delivery/${deliveryId}`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: dlvry.DELIVERY_DETAILS_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: dlvry.DELIVERY_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const myDeliveries = () => async (dispatch) => {
    try {
    
        dispatch({ type: dlvry.MY_DELIVERIES_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/my-deliveries`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: dlvry.MY_DELIVERIES_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: dlvry.MY_DELIVERIES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateDeliveryQueue = (deliveryId, deliveryData) => async (dispatch) => {
    try {
    
        dispatch({ type: dlvry.UPDATE_DELIVERYQUEUE_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/delivery-queue/${deliveryId}`, deliveryData, config)
         
        dispatch({
            type: dlvry.UPDATE_DELIVERYQUEUE_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: dlvry.UPDATE_DELIVERYQUEUE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateDeliveryStatus = (deliveryId, deliveryData) => async (dispatch) => {
    try {
    
        dispatch({ type: dlvry.UPDATE_STATUS_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/delivery/${deliveryId}`, deliveryData, config)
         
        dispatch({
            type: dlvry.UPDATE_STATUS_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: dlvry.UPDATE_STATUS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: dlvry.CLEAR_ERRORS

    })
}