import * as br from '../constants/bugReportConstants';

export const allBugReportsReducer = (state = { bugReports: [] }, action) => {
  switch (action.type) {
    case br.ALL_BUGREPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case br.ALL_BUGREPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        bugReports: action.payload,
      };
    case br.ALL_BUGREPORTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case br.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  success: null,
  isDeleted: null,
  isUpdated: null,
  error: null,
};

export const bugReportReducer = (state = initialState, action) => {
  switch (action.type) {
    // New Bug Report
    case br.NEW_BUGREPORT_REQUEST:
    case br.UPDATE_BUGREPORT_REQUEST:
    case br.DELETE_BUGREPORT_REQUEST:
      return { ...state, loading: true, success: null, error: null };

    case br.NEW_BUGREPORT_SUCCESS:
      return { ...state, loading: false, success: action.payload, error: null };

    case br.UPDATE_BUGREPORT_SUCCESS:
      return { ...state, loading: false, isUpdated: action.payload, error: null };

    case br.NEW_BUGREPORT_FAIL:
    case br.UPDATE_BUGREPORT_FAIL:
    case br.DELETE_BUGREPORT_FAIL:
      return { ...state, loading: false, success: null, error: action.payload };
    case br.CLEAR_ERRORS:
      return {
        ...state,
        error: null
      }
    case br.DELETE_BUGREPORT_RESET:
      return {
        ...state,
        isDeleted: false
      }
      case br.UPDATE_BUGREPORT_RESET:
        return {
          ...state,
          isUpdated: false
        }
        case br.NEW_BUGREPORT_RESET:
          return {
            ...state,
            success: false
          }

    case br.DELETE_BUGREPORT_SUCCESS:
      return { ...state, loading: false, isDeleted: action.payload, error: null };
    default:
      return state;
  }
};
