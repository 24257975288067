import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import SpeedIcon from "@mui/icons-material/Speed";
import { Box, Typography } from "@mui/material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import BugReportOutlined from "@mui/icons-material/BugReportOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import KeyIcon from "@mui/icons-material/Key";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { userConversationsReducer } from "../../reducers/chatReducers";
import { useSelector } from "react-redux";



const StyledMenuItem = ({ label, isActive, onClick, icon, isCollapsed }) => {

  const rootStyles = {
    backgroundColor: isActive ? "#014421" : "initial",
    color: isActive ? "#00321f" : "initial",
    "&:hover": {
      backgroundColor: isActive ? "#8a9a5b" : "initial",
    },
  };

  return (
    <MenuItem
      active={isActive}
      onClick={onClick}
      rootStyles={rootStyles}
      suffix={icon}
      icon={isCollapsed ? icon : null}
    >
      {label}
    </MenuItem>
  );
};

const MenuLink = ({ to, children }) => (
  <Link to={to} style={{ textDecoration: "none", color: "#fff" }}>
    {children}
  </Link>
);

const DashSidebar = () => {
  const { user } = useSelector(state => state.auth);
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isCollapsed = !isXlScreen;
  const location = useLocation();
  const [activeMenuItem, setActiveMenuItem] = useState("");

  useEffect(() => {
    // Update the active menu item based on the current pathname
    const pathname = location.pathname;
    setActiveMenuItem(getMenuItemFromPath(pathname));
  }, [location.pathname]);

  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
    // Perform any other actions you need on menu item click
  };

  const getMenuItemFromPath = (pathname) => {
    // Implement logic to map pathname to a corresponding menu item
    // Example logic: /admin/course-create maps to 'Documentation'
    if (pathname === "/admin/reports") {
      return "Reports";
    }
    if (pathname === "/admin/courses") {
      return "Courses";
    }
    if (pathname === "/admin/users") {
      return "Users";
    }
    if (pathname === "/admin/roomkeys") {
      return "Room Keys";
    }
    if (pathname === "/admin/bugreports") {
      return "Bug Reports";
    }
    if (pathname === "/admin/announcements") {
      return "Announcements";
    }
    // if (pathname === "/admin/coordinates") {
    //   return "Coordinates";
    // }
    // Add more mapping logic as needed

    return "";
  };
  return (
    <Sidebar
      backgroundColor="#101a11"
      collapsed={isCollapsed}
      collapsedWidth="80px"
      style={{
        border: "1px solid #101a11",
        height: "100%",
        minHeight: '100vh',
        overflowY: "auto",
        color: "#fff",
        // width: "50%"
      }}
    >
      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            // only apply styles on first level elements of the tree
            if (level === 0)
              return {
                color: disabled ? "#fff" : "#fff",
                // backgroundColor: active ? '#eecef9' : undefined,
                ":hover": {
                  backgroundColor: "#000",
                },
              };
          },
        }}
      >
        <Box sx={{ padding: "10px" }}>
          {isCollapsed ?
            <SpeedIcon sx={{ fontSize: "38px", pr: "15px", ml: 1.3 }} />
            :
            <Typography
              sx={{
                color: "fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                fontSize: "25px",
              }}
            >
              <SpeedIcon sx={{ fontSize: "38px", pr: "15px" }} /> Dashboard
            </Typography>
          }

        </Box>
        <MenuLink to="/admin/reports">
          <StyledMenuItem
            label="Reports"
            icon={<AssessmentOutlinedIcon />}
            isActive={activeMenuItem === "Reports"}
            isCollapsed={isCollapsed}
            onClick={() => handleMenuItemClick("Reports")}
          />
        </MenuLink>
        <MenuLink to="/admin/announcements">
          <StyledMenuItem
            label="Announcements"
            icon={<CampaignOutlinedIcon />}
            isCollapsed={isCollapsed}
            isActive={activeMenuItem === "Announcements"}
            onClick={() => handleMenuItemClick("Announcements")}
          />
        </MenuLink>
        {user?.role === 'admin' &&
          <>
            <MenuLink to="/admin/users">
              <StyledMenuItem
                label="Users"
                icon={<PersonPinOutlinedIcon />}
                isCollapsed={isCollapsed}
                isActive={activeMenuItem === "Users"}
                onClick={() => handleMenuItemClick("Users")}
              />
            </MenuLink>
            <MenuLink to="/admin/courses">
              <StyledMenuItem
                label="Courses"
                icon={<MenuBookOutlinedIcon />}
                isCollapsed={isCollapsed}
                isActive={activeMenuItem === "Courses"}
                onClick={() => handleMenuItemClick("Courses")}
              />
            </MenuLink>
            <MenuLink to="/admin/roomkeys">
              <StyledMenuItem
                label="Room Keys"
                icon={<KeyIcon />}
                isCollapsed={isCollapsed}
                isActive={activeMenuItem === "Room Keys"}
                onClick={() => handleMenuItemClick("Room Keys")}
              />
            </MenuLink>
            <MenuLink to="/admin/bugreports">
              <StyledMenuItem
                label="Bug Reports"
                icon={<BugReportOutlined />}
                isCollapsed={isCollapsed}
                isActive={activeMenuItem === "Bug Reports"}
                onClick={() => handleMenuItemClick("Bug Reports")}
              />{" "}
            </MenuLink>
          </>

        }



        {/* <MenuLink to="/admin/coordinates">
          <StyledMenuItem
            label="Coordinates"
            icon={<LocationOnIcon />}
            isCollapsed={isCollapsed}
            isActive={activeMenuItem === "Coordinates"}
            onClick={() => handleMenuItemClick("Coordinates")}
          />{" "}
        </MenuLink> */}

      </Menu>
    </Sidebar>
  );
};

export default DashSidebar;
