import React, { Fragment, useState, useEffect } from 'react'
import MetaData from '../layout/MetaData'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { updateProfile, loadUser, clearErrors } from '../../actions/userActions'
import { Link } from 'react-router-dom'
import { UPDATE_PROFILE_RESET } from '../../constants/userConstants'
import { pBtnColor } from '../styles/paabot-styles'
import { allCourses } from '../../actions/courseActions';
import {
    IconButton,
    Button,
    TextField,
    Grid,
    Paper,
    Typography,
    Box,
    Avatar,
    FormControl,
    FormHelperText,
    InputLabel,
    Select,
    MenuItem
  } from '@mui/material';
  import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import CircularProgress from '@mui/material/CircularProgress';

const UpdateProfile = () => {
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    const tableNumbers = ['!', '@', '#', '$', '%', '^', '&', '*', '?'];
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [program, setProgram] = useState('');
    const [coYeSe, setCYS] = useState('')
    const [deskLocation, setDeskLocation] = useState('')
    const [avatar, setAvatar] = useState('')
    const [avatarPreview, setAvatarPreview] = useState('/images/default_avatar.jpg')
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const { user } = useSelector(state => state.auth);
    const { error, isUpdated, loading } = useSelector(state => state.user)
    const { courses } = useSelector(state => state.allCourses)

    const programHandleChange = (event) => {
      setProgram(event.target.value);
    };
    const getProgramFromCoYeSe = (coYeSe) => {
      const parts = coYeSe.split('-');
      return parts[0]; // Assuming that the program is the first part of the coYeSe string
    };

    // // console.log(error)
    useEffect(() => {
        // console.log(isUpdated)
        setFirstName(user.firstName);
        setValue('firstName', user.firstName)
        setLastName(user.lastName);
        setValue('lastName', user.lastName)
        setAvatarPreview(user.avatar.url)
        setValue('pic', user.avatar.url)
       
        if (user.role === 'student' || user.role === 'CO') {
            dispatch(allCourses())
            setProgram(getProgramFromCoYeSe(user.coYeSe))
            setValue('program',getProgramFromCoYeSe(user.coYeSe))
            setCYS(user.coYeSe);
        }
        if (user.role === 'admin' || user.role === 'faculty') {
          if(user.deskLocation){
            setDeskLocation(user.deskLocation);
            setValue('deskLocation', user.deskLocation)
          } 
        
      }
        if (error) {
            // alert.error(error);
            dispatch(clearErrors());
        }
        if (isUpdated) {
            // alert.success('User updated successfully')
            dispatch(loadUser());
               // If dispatch is successful, show success message and close modal
      Swal.fire({
        title: 'Profile Update Successful!',
        text: 'You have successfully updated your profile.',
        icon: 'success',
      });
            navigate('/me', { replace: true })
            dispatch({
                type: UPDATE_PROFILE_RESET
            })
        }
    }, [dispatch, error, isUpdated, navigate, user])
    const submitHandler = (data, e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.set('firstName', firstName);
        formData.set('lastName', lastName);
        formData.set('avatar', avatar);
        if(user.role === 'student' || user.role === 'CO'){
            formData.set('coYeSe', coYeSe);  
        }
        if(user.role === 'faculty' || user.role === 'admin'){
          formData.set('deskLocation', deskLocation);  
      }
       
        dispatch(updateProfile(formData))
    }
    const onChange = e => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setAvatarPreview(reader.result)
                setAvatar(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    // console.log('user first name:' , user.coYeSe)
    return (
        <Fragment>
            <MetaData title={'Update Profile'} />
            <div style={{ display: 'flex' }}>
            <IconButton sx={{ ml: 3, mb: -20,
         '&:hover': {
            // Add styles for hover state
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            color: '#49B265',
            // Add any other styles you want to apply on hover
          },    
        }} onClick={() => {
                goBack()
            }}><ArrowBackIcon sx={{ fontSize: 35, color: '#000',  
            '&:hover': {
                // Add styles for hover state
                color: '#49B265',
                // Add any other styles you want to apply on hover
              },     }}></ArrowBackIcon></IconButton>
             </div>
            <Grid container justifyContent="center">
      <Grid item xs={10} lg={5} my={5}>
        <Paper elevation={12} sx={{ padding: 3 }}>
          <form onSubmit={handleSubmit(submitHandler)} encType="multipart/form-data">
          <div class="thirteen">
  <h1>Edit Profile</h1>
</div>

<FormControl fullWidth error={!!errors.pic}>
              <Grid container>
                <Grid item container justifyContent="space-around" spacing={3}>
                  <Grid item sx={{ justifyContent: 'flex-start' }}>
                    <Avatar src={avatarPreview} alt="Avatar Preview" sx={{ width: 100, height: 100, mt: 2 }} />
                  </Grid>

                  <Grid item sx={{ alignItems: 'center', justifyContent: 'flex-end', display: 'flex' }}>
                    <label htmlFor="customFile">
                      <Button
                        variant="contained"
                        component="span"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                        sx={{ mt: 2, ...pBtnColor}}
                      >
                        Upload Profile Picture
                      </Button>
                    </label>
                    <input
                      type="file"
                      // {...register('pic', { required: 'Image is required!' })}
                      id="customFile"
                      accept="image/*"
                      onChange={onChange}
                      style={{ display: 'none' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <FormHelperText>{errors.pic && <i>{errors.pic.message}</i>}</FormHelperText>
            </FormControl>

            <Box mb={3} marginTop={4}>
              <TextField
                id="firstname_field"
                label="First Name"
                variant="outlined"
                fullWidth
                {...register('firstName', { required: 'First Name is required!' })}
                value={firstName}
                defaultValue={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={!!errors.firstName}
                helperText={errors.firstName && <i>{errors.firstName.message}</i>}
              />
            </Box>

            <Box mb={3} marginTop={4}>
              <TextField
                id="nlastame_field"
                label="Last Name"
                variant="outlined"
                fullWidth
                {...register('lastName', { required: 'Last Name is required!' })}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={!!errors.name}
                helperText={errors.lastName && <i>{errors.lastName.message}</i>}
              />
            </Box>

            {(user.role === 'admin' || user.role === 'faculty') && (
            <><Box sx={{   my: 2 }}>
            <FormControl fullWidth error={!!errors.deskLocation}>
                  <InputLabel id="deskLocation-select">Desk Location</InputLabel>
                  <Select
                    labelId="deskLocation-select"
                    id="desklocation"
                    {...register("deskLocation")}
                    value={deskLocation}
                    label="Select a desk location"
                    onChange={(e) => setDeskLocation(e.target.value)}
                  >
                   
                   {tableNumbers.map((tableNumber, index) => (
        <MenuItem key={tableNumber} value={tableNumber}>
          {`Table ${index + 1}`} {/* Display "Table 1" to "Table 9" */}
        </MenuItem>
      ))}
                  </Select>
                  <FormHelperText>{errors.deskLocation && <i>{errors.deskLocation.message}</i>}</FormHelperText>
                </FormControl>
          </Box>
          {program && (
            <Box sx={{ my: 2 }}>
              <FormControl fullWidth error={!!errors.coYeSe}>
                <InputLabel id="coYeSe-select">Course Yr. Section</InputLabel>
                <Select
                  labelId="coYeSe-select"
                  id="coYeSe"
                  {...register("coYeSe", { required: "Course Year and Section is required!" })}
                  value={coYeSe}
                  label="Select Course Year Section"
                  onChange={(e) => setCYS(e.target.value)}
                >
                        {courses
      .find((course) => course.course === program) // Filter the courses based on the selected program
      ?.sectionLabels.map((label) => (
        <MenuItem key={label} value={label}>
          {label}
        </MenuItem>
      ))}
                </Select>
                <FormHelperText>{errors.coYeSe && <i>{errors.coYeSe.message}</i>}</FormHelperText>
              </FormControl>
            </Box>

          )}
          </>
          )}

            {(user?.role === 'student' || user?.role === 'CO') && (
            <><Box sx={{   my: 2 }}>
            <FormControl fullWidth error={!!errors.program}>
                  <InputLabel id="program-select">Program</InputLabel>
                  <Select
                    labelId="program-select"
                    id="program"
                    {...register("program", { required: "Program is required!" })}
                    value={program}
                    label="Select a Program"
                    onChange={programHandleChange}
                  >
                    {courses.map((course) => (
                      <MenuItem key={course._id} value={course.course}>
                        {course.course}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.program && <i>{errors.program.message}</i>}</FormHelperText>
                </FormControl>
          </Box>
          {program && (
            <Box sx={{ my: 2 }}>
              <FormControl fullWidth error={!!errors.coYeSe}>
                <InputLabel id="coYeSe-select">Course Yr. Section</InputLabel>
                <Select
                  labelId="coYeSe-select"
                  id="coYeSe"
                  {...register("coYeSe", { required: "Course Year and Section is required!" })}
                  value={coYeSe}
                  label="Select Course Year Section"
                  onChange={(e) => setCYS(e.target.value)}
                >
                        {courses
      .find((course) => course.course === program) // Filter the courses based on the selected program
      ?.sectionLabels.map((label) => (
        <MenuItem key={label} value={label}>
          {label}
        </MenuItem>
      ))}
                </Select>
                <FormHelperText>{errors.coYeSe && <i>{errors.coYeSe.message}</i>}</FormHelperText>
              </FormControl>
            </Box>

          )}
          </>
          )}



           
            <br /> <br />

            <Button
              type={loading ? 'button' : 'submit'}
              variant="contained"
              fullWidth
              sx={{ py: 3, mt: 2, backgroundColor: '#3f51b5', color: 'white', ...pBtnColor }}
            >
              {loading ? <CircularProgress sx={{color: '#fff'}} size={20} /> : `UPDATE` } 
              {/* Update */}
            </Button>
          </form>

          <Link to="/password/update" className="float-right">
            Change Password?
          </Link>
        </Paper>
      </Grid>
    </Grid>
   

        </Fragment>

    )

}



export default UpdateProfile