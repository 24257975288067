export const ALL_BUGREPORTS_REQUEST = 'ALL_BUGREPORTS_REQUEST'
export const ALL_BUGREPORTS_SUCCESS = 'ALL_BUGREPORTS_SUCCESS'
export const ALL_BUGREPORTS_FAIL = 'ALL_BUGREPORTS_FAIL'

export const BUGREPORT_DETAILS_REQUEST = 'BUGREPORT_DETAILS_REQUEST' 
export const BUGREPORT_DETAILS_SUCCESS = 'BUGREPORT_DETAILS_SUCCESS'
export const BUGREPORT_DETAILS_FAIL = 'BUGREPORT_DETAILS_FAIL'

export const ADMIN_BUGREPORTS_REQUEST = 'ADMIN_BUGREPORTS_REQUEST'
export const ADMIN_BUGREPORTS_SUCCESS = 'ADMIN_BUGREPORTS_SUCCESS'
export const ADMIN_BUGREPORTS_FAIL = 'ADMIN_BUGREPORTS_FAIL'

export const NEW_BUGREPORT_REQUEST = 'NEW_BUGREPORT_REQUEST'
export const NEW_BUGREPORT_SUCCESS = 'NEW_BUGREPORT_SUCCESS'
export const NEW_BUGREPORT_RESET = 'NEW_BUGREPORT_RESET'
export const NEW_BUGREPORT_FAIL = 'NEW_BUGREPORT_FAIL'


export const UPDATE_BUGREPORT_REQUEST = 'UPDATE_BUGREPORT_REQUEST'
export const UPDATE_BUGREPORT_SUCCESS = 'UPDATE_BUGREPORT_SUCCESS'
export const UPDATE_BUGREPORT_RESET = 'UPDATE_BUGREPORT_RESET'
export const UPDATE_BUGREPORT_FAIL = 'UPDATE_BUGREPORT_FAIL'

export const DELETE_BUGREPORT_REQUEST = 'DELETE_BUGREPORT_REQUEST'
export const DELETE_BUGREPORT_SUCCESS = 'DELETE_BUGREPORT_SUCCESS'
export const DELETE_BUGREPORT_RESET = 'DELETE_BUGREPORT_RESET'
export const DELETE_BUGREPORT_FAIL = 'DELETE_BUGREPORT_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

