import axios from "axios";
import * as notif from "../constants/notifConstants"

export const myNotifications = () => async (dispatch) => {
    try {
    
        dispatch({ type: notif.MY_NOTIFICATIONS_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/my-notifications`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: notif.MY_NOTIFICATIONS_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: notif.MY_NOTIFICATIONS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deleteNotification = (requestId) => async (dispatch) => {
    try {
    
        dispatch({ type: notif.DELETE_NOTIFICATIONS_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/v1/notification/${requestId}`, config)
         
        dispatch({
            type: notif.DELETE_NOTIFICATIONS_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: notif.DELETE_NOTIFICATIONS_FAIL,
            payload: error.response.data.message
        })
    }
}