import * as notif from '../constants/notifConstants'

export const myNotifReducer = (state = { myNotifs: [] }, action) => {
    switch (action.type) {
      case notif.MY_NOTIFICATIONS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case notif.MY_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          myNotifs: action.payload,
        };
      case notif.MY_NOTIFICATIONS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case notif.CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };