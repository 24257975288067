import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataGrid } from "@mui/x-data-grid";
import MetaData from "../layout/MetaData";
import Loader from "../layout/loader";
import { useDispatch, useSelector } from "react-redux";
import { getUserReqHistory, clearErrors } from "../../actions/StatActions";
import { DELETE_SCHEDULE_RESET, SCHEDULE_DETAILS_RESET } from "../../constants/schedDeliveryConstants";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography, Box, Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { dGridStyle } from "../chart/dataGridStyles";
import { ArrowBack, LocalShipping } from "@mui/icons-material";

const BarDetails = ({historyStatus, status}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const errMsg = (message = "") =>
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const successMsg = (message = "") =>
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const getRequestDescription = (code, keyNo) => {
    const codeMap = {
      getItem: "Get Item",
      getItemWKey: `Get Item & Key for Room ${keyNo}`,
      getKey: `Get Key for Room ${keyNo}`,
      returnItem: "Return Item"
    };

    // Use the code as a key to look up its description in the codeMap
    return codeMap[code] || "Unknown Request";
  };

  const minimalDate = (createdAt) => {
    const date = new Date(createdAt);
    const now = new Date();
    // If more than a day ago, return month day, year format
    const options = { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toLocaleDateString('en-US', options);
};
  
const getStatusColor = (status) => {
  switch (status) {
    case 'Granted':
    case 'Success':
      return 'green';
    case 'Ignored':
    case 'Cancelled':
      return 'orange';
    case 'Denied':
    case 'Failed':
      return 'red';
    default:
      return 'black'; // default color if status is none of the above
  }
};
  const columns = [
    // {
    //   label: 'ID',
    //   field: 'id',
    //   minWidth: 150,
    //   align: 'center',
    //   headerAlign: 'center', // Aligning header text
    // },
    {
        headerName: 'Date Requested',
      field: 'createdAt',
      width: 200,
      align: 'center',
      headerAlign: 'center', // Aligning header text
      renderCell: (params) => (
        <span>
          {minimalDate(params.value)}
        </span>
      ),
    },
    {
        headerName: 'Requestor',
        field: 'requestor',
        width: 200,
        align: 'center',
        headerAlign: 'center', // Aligning header text
      },
    {
        headerName: 'Requestee',
      field: 'requestee',
      width: 200,
      align: 'center',
      headerAlign: 'center', // Aligning header text
    },
    {
        headerName: 'Type',
      field: 'code',
      width: 200,
      align: 'center',
      headerAlign: 'center', // Aligning header text
    },
    {
        headerName: 'Status',
      field: 'status',
      width: 100,
      align: 'center',
      headerAlign: 'center', // Aligning header text
      renderCell: (params) => (
        <span style={{ color: getStatusColor(params.value) }}>
          {params.value}
        </span>
      ),
    },
    {
        headerName: 'Caption',
      field: 'caption',
      minWidth: 250,
      align: 'center',
      headerAlign: 'center', // Aligning header text
    },

  ];

  const rows = historyStatus.map((history) => ({
    id: history?.requestId ? history?.requestId : history?.deliveryId ,
    createdAt: history.createdAt,
    requestor: history.requestorId.username,
    requestee: `${(history.requestorId?.role === 'admin' || history.requestorId?.role === 'faculty') ? history?.cys ? `${history?.cys} class officers ` : `${history?.stud?.username} ${history?.stud?.coYeSe}  `  :`${history?.requesteeId?.title} ${history?.requesteeId?.username}`}`,
    code: getRequestDescription(history.code, history?.key?.keyNo),
    caption: history.caption,
    status: history?.reqOutcome ? history?.reqOutcome : history?.delOutcome,
    // role: user.role,
    // isValidatedUser: user.isValidatedUser,
  }));

  const sortedRows = rows.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <>
      <div sx={{ flexGrow: 1 }}>
            <Box sx={{ backgroundColor: '#fff', borderRadius: 5, paddingLeft: 2, paddingRight: 2, paddingBottom: 2 }}>
         
              <Typography variant="h4" sx={{ marginBottom: 2, marginTop: 1}}>
                <div class="thirteen">
                  <h1>{status}</h1>
                </div>
              </Typography>
             
                <Box sx={{ height: 500, width: "80%", margin: "auto" }}>
                  <DataGrid
                    rows={sortedRows}
                    columns={columns}
                    pageSize={10}
                    autoPageSize
                    rowsPerPageOptions={[10, 25, 50]}
                    //   checkboxSelection
                    disableSelectionOnClick
                    sx={dGridStyle}
                  />
                </Box>
          
            </Box>
   
      </div>
    </>
  );
};

export default BarDetails;
