import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import MetaData from '../layout/MetaData'
import DashSidebar from './sidebar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { updateCoordinate, getCoordinateDetails, clearErrors } from '../../actions/coordinateActions'
import { UPDATE_COORDINATE_RESET } from '../../constants/coordinateConstants'
import { FormGroup, FormControlLabel, Checkbox, Grid, Paper, Typography, IconButton, Button, TextField, Avatar, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from "react-hook-form";
import { allCoordinates } from '../../actions/coordinateActions';



const UpdateCoordinate = () => {
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
//   const onSubmit = data => // console.log(data);
//   const [program, setProgram] = useState('')
//   const [year, setYear] = useState('')
//   const [section, setSection] = useState('')

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  const { error, isUpdated, coordinate } = useSelector(state => state.coord);
  const { id } = useParams();
  const errMsg = (message = '') => toast.error(message, {
    position: toast.POSITION.BOTTOM_CENTER
  });
  const successMsg = (message = '') => toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER
  });

  useEffect(() => {
    // // console.log(user && user._id !== userId);
    if (coordinate && coordinate._id !== id) {
      dispatch(getCoordinateDetails(id))
    } else {
    setValue('name', coordinate.name);
    setValue('xAxis', coordinate.xAxis);
    setValue('yAxis', coordinate.yAxis);
    }
    if (error) {
      errMsg(error);
      dispatch(clearErrors());
    }
    if (isUpdated === true) {
      successMsg('Coordinate updated successfully')
      navigate('/admin/coordinates')
      dispatch({
        type: UPDATE_COORDINATE_RESET
      })
    }
  }, [dispatch, error, navigate, isUpdated, id, coordinate])


  const submitHandler = (data) => {
    dispatch(updateCoordinate(coordinate._id, data))
    // // console.log('submitted data: ', data)
  }

  return (
    <Fragment>
      <IconButton sx={{
        ml: 3, mb: -20,
        '&:hover': {
          // Add styles for hover state
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }} onClick={() => {
        goBack()
      }}><ArrowBackIcon sx={{
        fontSize: 35, color: '#000',
        '&:hover': {
          // Add styles for hover state
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }}></ArrowBackIcon></IconButton>

      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item xs={10} lg={5} my={5}>
          <Paper elevation={12} sx={{ padding: 3 }}>
            <Typography variant="h3" sx={{ marginBottom: '50px' }}>
              <div class="thirteen">
                <h1>UPDATE COORDINATE</h1>
              </div>
            </Typography>

            <form className="shadow-lg" onSubmit={handleSubmit(submitHandler)}>
         
              <Box mb={3}>
                <TextField
                  id="program_name_field"
                  label="Label"
                  variant="outlined"
                  fullWidth
                //   value={program}
                //   onChange={(e) => setProgram(e.target.value)}
                {...register('name', {
                    required: 'name is required',
                    minLength: {
                      value: 3,
                      message: 'Label must be at least 3 characters',
                    },
                    maxLength: {
                      value: 100,
                      message: 'Label must not exceed 100 characters',
                    },
                  })}
                // Add validation props if needed
                />
                 {errors.name && (
          <Typography variant="caption" color="error">
            {errors.name.message}
          </Typography>
        )}
              </Box>

              <Box mb={3}>
                <TextField
                  id="xAxis_field"
                  label="X - Axis"
                  variant="outlined"
                  fullWidth
                  disabled
                  type="number"
                //   value={year}
                //   onChange={(e) => setYear(e.target.value)}
                  {...register('xAxis', {
                    required: 'X - Axis is required',
                   
                  })}
                // Add validation props if needed
                />
                  {errors.xAxis && (
          <Typography variant="caption" color="error">
            {errors.xAxis.message}
          </Typography>
        )}
              </Box>

              <Box mb={3}>
                <TextField
                  id="yAxis_field"
                disabled
                  label="Y - Axis"
                  variant="outlined"
                  fullWidth
                  type="number"
                //   value={section}
                  {...register('yAxis', {
                    required: 'Y Axis is required',
                   
                  })}
                //   onChange={(e) => setSection(e.target.value)}
                />
                  {errors.yAxis && (
          <Typography variant="caption" color="error">
            {errors.yAxis.message}
          </Typography>
        )}
              </Box>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{ py: 3, mt: 2, backgroundColor: '#3f51b5', color: 'white' }}
              >
                Update
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UpdateCoordinate

