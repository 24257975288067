import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import MetaData from '../layout/MetaData'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
// import { addScheduledDelivery, clearErrors } from '../../actions/bugReportActions';
import { addScheduledDelivery, clearErrors } from '../../actions/schedDeliveryActions';
import { NEW_SCHEDULE_RESET } from '../../constants/schedDeliveryConstants';
import { FormGroup, FormControlLabel, Checkbox, Grid, Paper, Typography, IconButton, Button, TextField, Avatar, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box, Autocomplete } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm, Controller } from "react-hook-form";
import { ErrorSharp } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { allCourses } from '../../actions/courseActions';
import { allUsers } from '../../actions/userActions';
import { allRoomKeys } from '../../actions/roomKeyActions';
import CircularProgress from '@mui/material/CircularProgress';


const CreateSchedule = () => {
    const { register, control, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    //   const onSubmit = data => // console.log(data);
    //   const [program, setProgram] = useState('')
    //   const [year, setYear] = useState('')
    //   const [section, setSection] = useState('')

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const { error, success, loading } = useSelector(state => state.sched);
    const { roomKeys } = useSelector(state => state.roomKeys);
    const { courses } = useSelector(state => state.allCourses)
    const { users } = useSelector(state => state.allUsers)
    const [ studentUsers, setStudentUsers ] = useState(null)
    const errMsg = (message = '') => toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER
    });
    const successMsg = (message = '') => toast.success(message, {
        position: toast.POSITION.BOTTOM_CENTER
    });
    const daysOfWeek = [
        { label: 'Sunday', value: '0' },
        { label: 'Monday', value: '1' },
        { label: 'Tuesday', value: '2' },
        { label: 'Wednesday', value: '3' },
        { label: 'Thursday', value: '4' },
        { label: 'Friday', value: '5' },
        { label: 'Saturday', value: '6' },
    ];


    const whichToNotify = watch('notify');
    const selectedValue = watch('repeat')
    const Instruction = watch('code');
    // const [programChosen, setProgramChosen ] = useState('')
    let programChosen = watch('program'); // Changed from const to let

    if ((whichToNotify === 'student' && !programChosen) || !whichToNotify) {
        programChosen = null;
    }
    if (whichToNotify === 'student' && programChosen) {
        setValue("program", null)
        setValue("cys", null)
    }
    if (whichToNotify === 'section') {
        setValue("stud", null)
        // setValue("cys", null)
    }
    if (Instruction === 'getItem') {
        setValue('roomKey', null)
    }

    useEffect(() => {
        if (selectedValue !== '3') {
            // Uncheck all checkboxes when the user selects an option other than "Custom Weekday"
            setValue('daysOfWeek', new Array(daysOfWeek.length).fill(false));
        }
    }, [selectedValue, setValue]);
    useEffect(()=>{
        if(users){
            setStudentUsers(users.filter(user => user.role === 'student' || user.role === 'CO'))
            // // console.log('filtered users: ', studentUsers)
        }
     
    }, [users])

    useEffect(() => {
        dispatch(allRoomKeys());
        dispatch(allUsers());
        dispatch(allCourses());
    }, [])
    useEffect(() => {
        if (error) {
            errMsg(error);
            dispatch(clearErrors());
        }
        if (success) {
            successMsg("Scheduled Delivery Created Successfully!")
            navigate(-1)
            dispatch({
                type: NEW_SCHEDULE_RESET
            })
        }
    }, [dispatch, error, navigate, success])


    const submitHandler = (data) => {
        if (data.repeat === '3') {
            data.isOneTimeEvent = false;
            data.isDailyEvent = false;
            // Convert the array of boolean values to a binary string
            const binaryString = data.daysOfWeek.map(day => day ? '1' : '0').join('');
            // Add the binary string to the data object
            data.customWeeklyDays = binaryString;
            data.isCustomWeeklyEvent = true
            // Use the data object as needed
        }
        if (data.repeat === '1') {
            data.isCustomWeeklyEvent = false
            data.isOneTimeEvent = null;
            data.customWeeklyDays = null;
            data.isDailyEvent = true;
        }
        if (data.repeat === '0') {
            data.isCustomWeeklyEvent = false
            data.isOneTimeEvent = true;
            data.customWeeklyDays = null;
            data.isDailyEvent = false;
        }

        // // console.log(data);
        dispatch(addScheduledDelivery(data))
    };

    return (
        <Fragment>
            <IconButton sx={{
                ml: 3, mb: -20,
                '&:hover': {
                    // Add styles for hover state
                    backgroundColor: 'rgba(0, 0, 0, 0.0)',
                    color: '#49B265',
                    // Add any other styles you want to apply on hover
                },
            }} onClick={() => {
                goBack()
            }}><ArrowBackIcon sx={{
                fontSize: 35, color: '#000',
                '&:hover': {
                    // Add styles for hover state
                    color: '#49B265',
                    // Add any other styles you want to apply on hover
                },
            }}></ArrowBackIcon></IconButton>

            <Grid container spacing={2} direction="row" justifyContent="center">
                <Grid item xs={10} lg={5} my={5}>
                    <Paper elevation={12} sx={{ padding: 3 }}>
                        <Typography variant="h3" sx={{ marginBottom: '50px' }}>
                            <div class="thirteen">
                                <h1>CREATE A DELIVERY SCHEDULE</h1>
                            </div>
                        </Typography>

                        <form className="shadow-lg" onSubmit={handleSubmit(submitHandler)}>

                            <Box mb={3}>
                                <TextField
                                    id="title_name_field"
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                    //   value={program}
                                    //   onChange={(e) => setProgram(e.target.value)}
                                    {...register('schedName', {
                                        required: 'You must name your schedule',
                                        minLength: {
                                            value: 3,
                                            message: 'Name must be at least 3 characters',
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: 'Name must not exceed 100 characters',
                                        },
                                    })}
                                // Add validation props if needed
                                />
                                {errors.schedName && (
                                    <Typography variant="caption" color="error">
                                        {errors.schedName.message}
                                    </Typography>
                                )}
                            </Box>

                            <Box mb={3}>
                                <TextField
                                    id="sched_time_field"
                                    label="Set Time"
                                    variant="outlined"
                                    type="time"
                                    fullWidth
                                    //   value={program}
                                    //   onChange={(e) => setProgram(e.target.value)}
                                    {...register('schedTime', {
                                        required: 'You must specify a time for your schedule',
                                    })}
                                // Add validation props if needed
                                />
                                {errors.schedTime && (
                                    <Typography variant="caption" color="error">
                                        {errors.schedTime.message}
                                    </Typography>
                                )}
                            </Box>

                            <Box mb={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="type-simple-select-label">Order</InputLabel>
                                    <Select
                                        labelId="req-type-select-label"
                                        id="req-type-select"
                                        //   value={age}
                                        label="Order"
                                        {...register('code', {
                                            required: 'Please specify an order'
                                        })}
                                    //   onChange={handleChange}
                                    >
                                        <MenuItem value='getKey'>Get Room Key</MenuItem>
                                        {/* <MenuItem value='returnKey'>Return Room Key</MenuItem> */}
                                        <MenuItem value='getItem'>Get Item</MenuItem>
                                        {/* <MenuItem value='returnItem'>Return Item</MenuItem> */}
                                        <MenuItem value='getItemWKey'>Get Item & Room Key</MenuItem>
                                        {/* <MenuItem value='returnItemWKey'>Return Item & Room Key</MenuItem> */}
                                    </Select>
                                    {errors.code && (
                                        <Typography variant="caption" color="error">
                                            {errors.code.message}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Box>
                            {Instruction ? (
                                <><Box>
                                    {Instruction !== 'getItem' && (

                                        <Box mb={3}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Room Key</InputLabel>
                                                <Select
                                                    labelId="type-select-label"
                                                    id="type-select"
                                                    //   value={age}
                                                    label="Room Key"
                                                    {...register('roomKey', {
                                                        required: 'Room Key is required',
                                                    })}
                                                >
                                                    {roomKeys.map((roomKey) => (
                                                        <MenuItem
                                                            key={roomKey._id}
                                                            value={roomKey._id}
                                                            disabled={roomKey.status !== 'Available'}
                                                            style={{ color: roomKey.status !== 'Available' ? 'red' : 'inherit' }}
                                                        >
                                                            ROOM {roomKey.keyNo}
                                                            {roomKey.status !== 'Available' && ' (Missing)'}
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                                {errors.roomKey && (
                                                    <Typography variant="caption" color="error">
                                                        {errors.roomKey.message}
                                                    </Typography>
                                                )}
                                            </FormControl>
                                        </Box>

                                    )}

                                    <Box mb={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Which to Notify</InputLabel>
                                            <Select
                                                labelId="type-select-label"
                                                id="type-select"
                                                //   value={age}
                                                label="Which to Notify"
                                                {...register('notify', {
                                                    required: 'Please select which student/section to notify',
                                                })}
                                            >
                                                <MenuItem value='student'>Student</MenuItem>
                                                <MenuItem value='section'>Section</MenuItem>
                                            </Select>

                                            {(whichToNotify === 'student' && studentUsers) && ( // Conditionally render the text field
 <Controller
 name="stud"
 control={control}
 rules={{ required: 'Please choose a student' }}
 render={({ field, fieldState: { error } }) => (
   <Autocomplete
     {...field}
     options={studentUsers}
     getOptionLabel={(option) => `${option.username} | ${option.coYeSe}`}
     isOptionEqualToValue={(option, value) => option._id === value._id}
     onChange={(_, data) => field.onChange(data ? data._id : '')}
     renderInput={(params) => (
       <TextField
         {...params}
         label="Select Student"
         variant="outlined"
         error={!!error}
        //  helperText={error ? error.message : null}
         fullWidth
         sx={{ mt: 2 }}
       />
     )}
   />
 )}
/>
                                            )}
                                            {whichToNotify === 'section' && (
                                                <FormControl fullWidth error={!!errors.program} sx={{ mt: 2 }}>

                                                    <InputLabel id="coYeSe-select">Select a Program</InputLabel>
                                                    <Select
                                                        labelId="program-select"
                                                        id="select-program"
                                                        {...register("program", { required: "Program is required!" })}
                                                        // value={program}
                                                        label="Select a Program"
                                                    >
                                                        {courses?.map((course) => (
                                                            <MenuItem key={course._id} value={course.course}>
                                                                {course.course}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            )}

                                            {programChosen !== null && programChosen !== "" && (
                                                <Box sx={{ mt: 2 }}>
                                                    <FormControl fullWidth error={!!errors.cys}>
                                                        <InputLabel id="coYeSe-select">Select Course Yr. Section</InputLabel>
                                                        <Select
                                                            labelId="coYeSe-select"
                                                            id="coYeSe"
                                                            {...register("cys", { required: "Course Year and Section is required!" })}
                                                            // value={coYeSe}
                                                            label="Select Course Year Section"
                                                        >
                                                            {courses
                                                                .find((course) => course.course === programChosen) // Filter the courses based on the selected program
                                                                ?.sectionLabels.map((label) => (
                                                                    <MenuItem key={label} value={label}>
                                                                        {label}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                        <FormHelperText>{errors.cys && <i>{errors.cys.message}</i>}</FormHelperText>
                                                    </FormControl>
                                                </Box>

                                            )}
                                            {(errors.notify || errors.stud) && (
                                                <Typography variant="caption" color="error">
                                                    {(errors.notify && errors.notify.message) || (errors.stud && errors.stud.message)}
                                                </Typography>
                                            )}
                                        </FormControl>
                                    </Box>

                                    {whichToNotify && (
                                        <Box>
                                            <TextField
                                                id="title_name_field"
                                                label="Message"
                                                variant="outlined"
                                                fullWidth
                                                //   value={program}
                                                //   onChange={(e) => setProgram(e.target.value)}
                                                {...register('caption', {
                                                    required: 'leave a message to whom you like to notify',
                                                    minLength: {
                                                        value: 3,
                                                        message: 'Message must be at least 3 characters',
                                                    },
                                                    maxLength: {
                                                        value: 250,
                                                        message: 'Message must not exceed 250 characters',
                                                    },
                                                })} />
                                            {errors.caption && (
                                                <Typography variant="caption" color="error">
                                                    {errors.caption.message}
                                                </Typography>
                                            )}
                                        </Box>)}
                                </Box><Box sx={{ my: 2 }}>
                                        <FormControl fullWidth>
                                            <InputLabel>Repeat</InputLabel>
                                            <Select
                                                {...register('repeat')}
                                                label="Repeat"
                                                defaultValue={"0"}
                                            >
                                                <MenuItem value="0">Once</MenuItem>
                                                <MenuItem value="1">Daily</MenuItem>
                                                <MenuItem value="3">Custom Weekday</MenuItem>
                                            </Select>
                                        </FormControl>

                                        {selectedValue === '3' && (
                                            <Box sx={{ my: 2 }}>
                                                {daysOfWeek.map((day, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        control={
                                                            <Checkbox
                                                                {...register(`daysOfWeek[${index}]`)}
                                                                value={day.value}
                                                            />
                                                        }
                                                        label={day.label}
                                                    />

                                                ))}

                                            </Box>
                                        )}</Box></>
                            ) : null}

                            <Button
                                 type={loading ? 'button' : 'submit'}
                                variant="contained"
                                fullWidth
                                sx={{ py: 3, mt: 2, backgroundColor: '#3f51b5', color: 'white' }}
                            >
                                {loading ? <CircularProgress sx={{color: '#fff'}} size={20} /> : `CREATE SCHEDULE` } 
                                {/* SUBMIT */}
                            </Button>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default CreateSchedule
