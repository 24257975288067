//primary button
// export const primaryButtonStyle = {
//     variant: 'contained',
//     sx: {
//       backgroundColor: '#159947',
//       '&:hover': {
//         backgroundColor: '#0f7d3d', // Change color on hover
//       },
//     },
//   };

export  const pBtnColor = {
    backgroundColor: '#159947',
    '&:hover': {
      backgroundColor: 'primary',
    },
  };