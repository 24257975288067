import * as coord from '../constants/coordinateConstants';

const initialState = Object.freeze({
    coordinates: [],
    loading: false,
    coordinate: {},
    isUpdated: false,
    error: null
});

export const coordinatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case coord.ALL_COORDINATES_REQUEST:
        case coord.UPDATE_COORDINATE_REQUEST:
        case coord.COORDINATE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null // Clear error on request
            };
        case coord.ALL_COORDINATES_SUCCESS:
            return {
                ...state,
                loading: false,
                coordinates: action.payload,
            };
        case coord.COORDINATE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                coordinate: action.payload,
            };
        case coord.COORDINATE_DETAILS_RESET:
            return {
                ...state,
                loading: false,
                coordinate: {},
            };

        case coord.UPDATE_COORDINATE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: true,
            };
        case coord.ALL_COORDINATES_FAIL:
        case coord.UPDATE_COORDINATE_FAIL:
        case coord.COORDINATE_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case coord.UPDATE_COORDINATE_RESET:
            return {
                ...state,
                loading: false,
                isUpdated: false,
            };
        case coord.CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};
