import React, { useState } from "react";
import { Grid, Paper, Typography, Box, Avatar } from "@mui/material";
import DashSidebar from "./sidebar";
import UserCharts from "../chart/userCharts";
import BugReports from "../chart/bugreportCharts";
import Dashboard from "./dashboard";
import KeyRatioPieChart from "../chart/keyCharts";
import RequestChart from "../chart/requestCharts";
import DeliveryChart from "../chart/deliveryCharts";
import MetaData from "../layout/MetaData";

const Reports = () => {
  const boxStyle = {
    textAlign: "center",
    height: "100%",
    padding: "5px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
  };

  const boxFormat = {
    display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'
  }
  const initialComponentState = {
    showUserCharts: false,
    showBugReports: false,
    showKeyReports: false,
    showReqReports: false,
    showDeliveryReports: false,
    showDashboard: true
  };
  const [componentState, setComponentState] = useState(
    initialComponentState
  );

  const userChartVis = () => {
    setComponentState({
      ...componentState,
      showUserCharts: true,
      showDashboard: false
    })
  };
  const bugChartVis = () => {
    setComponentState({
      ...componentState,
      showBugReports: true,
      showDashboard: false
    })
  };
  const keyChartVis = () => {
    setComponentState({
      ...componentState,
      showKeyReports: true,
      showDashboard: false
    })
  };
  const ReqChartVis = () => {
    setComponentState({
      ...componentState,
      showReqReports: true,
      showDashboard: false
    })
  };
  const DeliveryChartVis = () => {
    setComponentState({
      ...componentState,
      showDeliveryReports: true,
      showDashboard: false
    })
  };
  const dashboardVis = () => {
    setComponentState(initialComponentState)
  };

  return (
    <div ><MetaData title={'Reports'}/>
      <Grid container direction="row" spacing={10} >
        <Grid item xs={2} md={2}>
          <DashSidebar />
        </Grid>

        <Grid item xs={10} md={9} sx={{ mx: 'auto', marginTop: '15px'}}>
          <Box>
            {componentState.showDashboard && <Dashboard
              boxStyle={boxStyle}
              boxFormat={boxFormat}
              userchart={userChartVis}
              bugreportchart={bugChartVis}
              keyreportchart={keyChartVis}
              reqreportchart={ReqChartVis}
              dlvryreportchart={DeliveryChartVis}
            />}
          </Box>
          <Box >
            {componentState.showUserCharts && <UserCharts dashboard={dashboardVis} />}
            {componentState.showBugReports && <BugReports dashboard={dashboardVis} />}
            {componentState.showKeyReports && <KeyRatioPieChart dashboard={dashboardVis} />}
            {componentState.showReqReports && <RequestChart dashboard={dashboardVis} />}
            {componentState.showDeliveryReports && <DeliveryChart dashboard={dashboardVis} />}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Reports;
