import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import MetaData from "../layout/MetaData";
import Loader from "../layout/loader";
import { useDispatch, useSelector } from "react-redux";
import {
  allRoomKeys,
  clearErrors,
  deleteRoomKey,
} from "../../actions/roomKeyActions";

// import { DELETE_COURSE_RESET, CLEAR_ERRORS } from '../../constants/courseConstants';
import { DELETE_ROOMKEY_RESET, ROOMKEY_DETAILS_RESET } from "../../constants/roomKeyConstants";

import DashSidebar from "./sidebar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography, Box, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import { dGridStyle } from "../chart/dataGridStyles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const RoomKeyList = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { loading, error, roomKeys } = useSelector(
    (state) => state.roomKeys
  );
  const { user } = useSelector((state) => state.auth);
  const { isDeleted } = useSelector((state) => state.roomKey);
  const errMsg = (message = "") =>
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const successMsg = (message = "") =>
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  useEffect(() => {
    dispatch({ type: ROOMKEY_DETAILS_RESET });
    dispatch(allRoomKeys());
    if (error) {
      errMsg(error);
      dispatch(clearErrors());
    }
    if (isDeleted) {
      Swal.fire({
        title: "Deleted!",
        text: "Room Key Successfully Deleted.",
        icon: "success"
      });
      navigate("/admin/roomkeys");
      dispatch({ type: DELETE_ROOMKEY_RESET });
    }
  }, [dispatch, error, isDeleted, navigate]);

  const deleteRoomKeyHandler = (id) => {
    Swal.fire({
      title: "Delete this Room Key?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteRoomKey(id));
      }
    });
    // 
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "keyNo",
      headerName: "Room",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "keyQTY",
      headerName: "Quantity",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.6,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Link
              to={`/admin/roomkey/${params.row.id}`} 
            className="btn btn-info py-1 px-2">
            <EditIcon />
          </Link>
          <IconButton
            onClick={() => deleteRoomKeyHandler(params.row.id)}
            className="btn btn-danger py-1 px-2 ml-2"
          >
            <DeleteIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = roomKeys.map((roomKey) => ({
    id: roomKey._id,
    keyNo: roomKey.keyNo,
    keyQTY: roomKey.keyQTY,
    status: roomKey.status,
    createdAt: roomKey.createdAt,
    // role: user.role,
    // isValidatedUser: user.isValidatedUser,
  }));

  return (
    <>
      <div sx={{ flexGrow: 1 }}>
        <Grid container direction="row">
          <Grid item xs={2} md={2}>
            <DashSidebar />
          </Grid>
          <Grid item xs={10} md={10}>
            <Fragment>
              <Typography variant="h4" sx={{ margin: 2 }}>
                <div class="thirteen">
                  <h1>Room Keys</h1>
                </div>
              </Typography>
              {loading ? (
                <Loader />
              ) : (
                <Box sx={{ height: 500, width: "90%", margin: "auto" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    autoPageSize
                    rowsPerPageOptions={[10, 25, 50]}
                    //   checkboxSelection
                    disableSelectionOnClick
                    sx={dGridStyle}
                    slots={{
                      toolbar: CustomToolbar,
                    }}
                  />
                </Box>
              )}
            </Fragment>
          </Grid>
        </Grid>
        <Link 
        to="/admin/roomkey-create"
        >
          <Fab
            color="success"
            aria-label="add"
            sx={{ position: "fixed", top: 500, right: 40, zIndex: "20" }}
          >
            <AddIcon />
          </Fab>
        </Link>
      </div>
    </>
  );
};

export default RoomKeyList;
