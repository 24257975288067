import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import MetaData from '../layout/MetaData'
import DashSidebar from './sidebar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { updateUser, getUserDetails, clearErrors } from '../../actions/userActions'
import { UPDATE_USER_RESET } from '../../constants/userConstants'
import { FormGroup, FormControlLabel, Checkbox, Grid, Paper, Typography, IconButton, Button, TextField, Avatar, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useForm } from "react-hook-form";
import { allCourses } from '../../actions/courseActions';
import CircularProgress from '@mui/material/CircularProgress';


const UpdateUser = () => {
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const [title, setTitle] = useState('')
  const [firstName, setfirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [role, setRole] = useState('')
  const [coYeSe, setCoYeSe] = useState('')
  const [program, setProgram] = useState('')
  const [isValidated, setIsValidated] = useState(false)
  const [deskLocation, setdeskLocation] = useState('')


  const dispatch = useDispatch();
  let navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  const { error, isUpdated, loading } = useSelector(state => state.user);
  const { user } = useSelector(state => state.userDetails)
  const { courses } = useSelector(state => state.allCourses)
  const { id } = useParams();
  const errMsg = (message = '') => toast.error(message, {
    position: toast.POSITION.BOTTOM_CENTER
  });
  const successMsg = (message = '') => toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER
  });

  useEffect(() => {
    dispatch(allCourses()); // This line triggers the allCourses action
    // console.log('All Courses: ', courses);
  }, [dispatch]);

  const getProgramFromCoYeSe = (coYeSe) => {
    const parts = coYeSe.split('-');
    return parts[0]; // Assuming that the program is the first part of the coYeSe string
  };

  useEffect(() => {
    // // console.log(user && user._id !== userId);
    if (user && user._id !== id) {
      dispatch(getUserDetails(id))
    } else {
      setTitle(user.title)
      // setValue('title', user.title)
      setfirstName(user.firstName);
      setlastName(user.lastName);
      setProgram(getProgramFromCoYeSe(user.coYeSe))
      setCoYeSe(user.coYeSe);
      setValue("coYeSe", user.coYeSe);
      setdeskLocation(user.deskLocation);
      setIsValidated(user.isValidatedUser)
      setRole(user.role)
      setValue("role", user.role);
    }
    if (error) {
      errMsg(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      successMsg('User updated successfully')
      navigate('/admin/users')
      dispatch({
        type: UPDATE_USER_RESET
      })
    }
  }, [dispatch, error, navigate, isUpdated, id, user])

  const cysHandleChange = (event) => {
    setCoYeSe(event.target.value);
  };



  const programHandleChange = (event) => {
    setProgram(event.target.value);
  };

  const submitHandler = (data, e) => {

    e.preventDefault();

    const formData = new FormData();
    formData.set('title', title);
    formData.set('firstName', firstName);
    formData.set('lastName', lastName);
    formData.set('coYeSe', coYeSe);
    if (deskLocation) {
      formData.set('deskLocation', deskLocation);
    }
    formData.set('role', role);
    formData.set('isValidatedUser', isValidated);
    dispatch(updateUser(user._id, formData))
  }

  return (
    <Fragment>
      <IconButton sx={{
        ml: 3, mb: -20,
        '&:hover': {
          // Add styles for hover state
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }} onClick={() => {
        goBack()
      }}><ArrowBackIcon sx={{
        fontSize: 35, color: '#000',
        '&:hover': {
          // Add styles for hover state
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }}></ArrowBackIcon></IconButton>

      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item xs={10} lg={5} my={5}>
          <Paper elevation={12} sx={{ padding: 3 }}>
            <Typography variant="h4" sx={{ marginBottom: '50px' }}>
              <div class="thirteen">
                <h1>UPDATE USER</h1>
              </div>
            </Typography>

            <form className="shadow-lg" onSubmit={handleSubmit(submitHandler)}>
            <Grid container>
                <Grid item container justifyContent="space-around" spacing={3}>
                  <Grid item sx={{ justifyContent: 'flex-start' }}>
                    <Avatar src={user?.avatar?.url} alt="Avatar Preview" sx={{ width: 100, height: 100, mt: 2 }} />
                  </Grid>  </Grid>  </Grid>
              <Box sx={{ my: 2 }}>
                <FormControl fullWidth error={!!errors.title}>
                  <InputLabel id="demo-simple-select-label">Title</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register("title", { required: "Please select a title." })}
                    value={title}
                    label="Title"
                    onChange={(e) => setTitle(e.target.value)}
                  >
                    <MenuItem value={'Sir'}>Sir</MenuItem>
                    <MenuItem value={"Ma'am"}>Ma'am</MenuItem>
                  </Select>
                  <FormHelperText>{errors.title && <i>{errors.title.message}</i>}</FormHelperText>
                </FormControl>
              </Box>

              <Box mb={3}>
                <TextField
                  id="first_name_field"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  value={firstName}
                  onChange={(e) => setfirstName(e.target.value)}
                // Add validation props if needed
                />
              </Box>

              <Box mb={3}>
                <TextField
                  id="last_name_field"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  value={lastName}
                  onChange={(e) => setlastName(e.target.value)}
                // Add validation props if needed
                />
              </Box>

              <Box sx={{ my: 2 }}>
                <FormControl fullWidth error={!!errors.role}>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register("role", { required: "Please select a role." })}
                    value={role}
                    label="Role"
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <MenuItem value={'student'}>Student</MenuItem>
                    <MenuItem value={'faculty'}>Professor</MenuItem>
                    <MenuItem value={'CO'}>Class Officer</MenuItem>
                  </Select>
                  <FormHelperText>{errors.role && <i>{errors.role.message}</i>}</FormHelperText>
                </FormControl>
              </Box>
              {(role === 'student' || role === 'CO') && (
                <><Box sx={{ my: 2 }}>
                  <FormControl fullWidth error={!!errors.coYeSe}>
                    <InputLabel id="program-select">Program</InputLabel>
                    <Select
                      labelId="program-select"
                      id="program"
                      {...register("program", { required: "Program is required!" })}
                      value={program}
                      label="Select a Program"
                      onChange={programHandleChange}
                    >
                      {courses.map((course) => (
                        <MenuItem key={course._id} value={course.course}>
                          {course.course}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.coYeSe && <i>{errors.coYeSe.message}</i>}</FormHelperText>
                  </FormControl>
                </Box>
                <Box sx={{ my: 2 }}>
                    <FormControl fullWidth error={!!errors.coYeSe}>
                      <InputLabel id="coYeSe-select">Course Yr. Section</InputLabel>
                      <Select
                        labelId="coYeSe-select"
                        id="coYeSe"
                        {...register("coYeSe", { required: "Course Year and Section is required!" })}
                        value={coYeSe}
                        label="Select Course Year Section"
                        onChange={cysHandleChange}
                      >
                         {courses
        .find((course) => course.course === program) // Filter the courses based on the selected program
        ?.sectionLabels.map((label) => (
          <MenuItem key={label} value={label}>
            {label}
          </MenuItem>
        ))}
                      </Select>
                      <FormHelperText>{errors.coYeSe && <i>{errors.coYeSe.message}</i>}</FormHelperText>
                    </FormControl>
                  </Box></>
              )}
              {/* {(role === 'faculty' || role === 'admin') && (
            <Box mb={3}>
              <TextField
                id="deskLocation_field"
                label="Desk Location"
                variant="outlined"
                fullWidth
                value={deskLocation}
                onChange={(e) => setdeskLocation(e.target.value)}
                // Add validation props if needed
              />
            </Box>
 )} */}

              <Box mb={3}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isValidated}
                        color="success"
                        onChange={(e) => setIsValidated(e.target.checked)}
                      />
                    }
                    label="Is Validated User"
                  />
                </FormGroup>

              </Box>

              {/* ... rest of the form */}


              <Button
                 type={loading ? 'button' : 'submit'}
                variant="contained"
                fullWidth
                sx={{ py: 3, mt: 2, backgroundColor: '#3f51b5', color: 'white' }}
              >
                {loading ? <CircularProgress sx={{color: '#fff'}} size={20} /> : `UPDATE` } 
                {/* Update */}
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UpdateUser

