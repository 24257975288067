import React from 'react'
import { Helmet } from 'react-helmet'

const MetaData = ({ title }) => {
    return (
        <Helmet>
            <title>{`RAD4 : ${title}`}</title>
            <link rel="icon" type="image/png" href="/logo192.png" />
        </Helmet>
    )
}

export default MetaData