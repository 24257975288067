const mapSpecialCharactersToTables = (character) => {
    switch (character) {
      case '!':
        return 'Sir Nestor';
      case '@':
        return 'Maam Lappay';
      case '#':
        return 'Maam Baloloy';
      case '$':
        return 'Sir Silang';
      case '%':
        return 'Sir Dalisay';
      case '^':
        return 'Sir Rico';
      case '&':
        return 'Maam Magisa';
      case '*':
        return 'Maam Pops';
      case '?':
        return 'Table 9';
      default:
        return character; // If character is not a special character, return it as is
    }
  };

  export default mapSpecialCharactersToTables;