import * as rqst from '../constants/requestConstants'

export const myRequestReducer = (state = { myRequests: [] }, action) => {
    switch (action.type) {
      case rqst.MY_REQUESTS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case rqst.MY_REQUESTS_SUCCESS:
        return {
          ...state,
          loading: false,
          myRequests: action.payload,
        };
      case rqst.MY_REQUESTS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case rqst.CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };

  const initialState = {
    loading: false,
    success: null,
    isDeleted: null,
    isUpdated: null,
    error: null,
  };
  
  export const requestsReducer = (state = initialState, action) => {
    switch (action.type) {
      // New Bug Report
      case rqst.NEW_REQUEST_REQUEST:
      case rqst.UPDATE_REQUEST_REQUEST:
      case rqst.DELETE_REQUEST_REQUEST:
      case rqst.UPDATE_STATUS_REQUEST:
        return { ...state, loading: true, success: null, error: null };
  
      case rqst.NEW_REQUEST_SUCCESS:
        return { ...state, loading: false, success: action.payload, error: null };
  
      case rqst.UPDATE_REQUEST_SUCCESS:
        return { ...state, loading: false, isUpdated: action.payload, error: null };
  
      case rqst.NEW_REQUEST_FAIL:
      case rqst.UPDATE_REQUEST_FAIL:
      case rqst.DELETE_REQUEST_FAIL:
      case rqst.UPDATE_STATUS_FAIL:
        return { ...state, loading: false, success: null, error: action.payload };
      case rqst.CLEAR_ERRORS:
        return {
          ...state,
          error: null
        }
      case rqst.DELETE_REQUEST_RESET:
        return {
          ...state,
          isDeleted: false
        }
        case rqst.UPDATE_REQUEST_RESET:
          return {
            ...state,
            isUpdated: false
          }
          case rqst.NEW_REQUEST_RESET:
          return {
            ...state,
            success: false
          }
  
  
      case rqst.DELETE_REQUEST_SUCCESS:
        return { ...state, loading: false, isDeleted: action.payload, error: null };
      default:
        return state;
    }
  };