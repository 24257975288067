import React from 'react'

const Loader = () => {
    return (
        <div className="loader">   
    <div class="gears-container">
      <div class="gear-rotate"></div>
      <div class="gear-rotate-left"></div>
    </div>

        </div>
      
    )
}

export default Loader