export const MY_DELIVERIES_REQUEST = 'MY_DELIVERIES_REQUEST'
export const MY_DELIVERIES_SUCCESS = 'MY_DELIVERIES_SUCCESS'
export const MY_DELIVERIES_FAIL = 'MY_DELIVERIES_FAIL'

export const DELIVERY_DETAILS_REQUEST = 'DELIVERY_DETAILS_REQUEST' 
export const DELIVERY_DETAILS_SUCCESS = 'DELIVERY_DETAILS_SUCCESS'
export const DELIVERY_DETAILS_FAIL = 'DELIVERY_DETAILS_FAIL'

export const ADMIN_DELIVERIES_REQUEST = 'ADMIN_DELIVERIES_REQUEST'
export const ADMIN_DELIVERIES_SUCCESS = 'ADMIN_DELIVERIES_SUCCESS'
export const ADMIN_DELIVERIES_FAIL = 'ADMIN_DELIVERIES_FAIL'

export const UPDATE_DELIVERYQUEUE_REQUEST = 'UPDATE_DELIVERY_REQUEST'
export const UPDATE_DELIVERYQUEUE_SUCCESS = 'UPDATE_DELIVERY_SUCCESS'
export const UPDATE_DELIVERYQUEUE_RESET = 'UPDATE_DELIVERY_RESET'
export const UPDATE_DELIVERYQUEUE_FAIL = 'UPDATE_DELIVERY_FAIL'

export const DELETE_DELIVERY_REQUEST = 'DELETE_DELIVERY_REQUEST'
export const DELETE_DELIVERY_SUCCESS = 'DELETE_DELIVERY_SUCCESS'
export const DELETE_DELIVERY_RESET = 'DELETE_DELIVERY_RESET'
export const DELETE_DELIVERY_FAIL = 'DELETE_DELIVERY_FAIL'

export const UPDATE_STATUS_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_STATUS_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_STATUS_RESET = 'UPDATE_USER_RESET'
export const UPDATE_STATUS_FAIL = 'UPDATE_USER_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

