export const MY_REQUESTS_REQUEST = 'MY_REQUESTS_REQUEST'
export const MY_REQUESTS_SUCCESS = 'MY_REQUESTS_SUCCESS'
export const MY_REQUESTS_FAIL = 'MY_REQUESTS_FAIL'

export const REQUEST_DETAILS_REQUEST = 'REQUEST_DETAILS_REQUEST' 
export const REQUEST_DETAILS_SUCCESS = 'REQUEST_DETAILS_SUCCESS'
export const REQUEST_DETAILS_FAIL = 'REQUEST_DETAILS_FAIL'

export const ADMIN_REQUESTS_REQUEST = 'ADMIN_REQUESTS_REQUEST'
export const ADMIN_REQUESTS_SUCCESS = 'ADMIN_REQUESTS_SUCCESS'
export const ADMIN_REQUESTS_FAIL = 'ADMIN_REQUESTS_FAIL'

export const NEW_REQUEST_REQUEST = 'NEW_REQUEST_REQUEST'
export const NEW_REQUEST_SUCCESS = 'NEW_REQUEST_SUCCESS'
export const NEW_REQUEST_RESET = 'NEW_REQUEST_RESET'
export const NEW_REQUEST_FAIL = 'NEW_REQUEST_FAIL'


export const UPDATE_REQUEST_REQUEST = 'UPDATE_REQUEST_REQUEST'
export const UPDATE_REQUEST_SUCCESS = 'UPDATE_REQUEST_SUCCESS'
export const UPDATE_REQUEST_RESET = 'UPDATE_REQUEST_RESET'
export const UPDATE_REQUEST_FAIL = 'UPDATE_REQUEST_FAIL'

export const DELETE_REQUEST_REQUEST = 'DELETE_REQUEST_REQUEST'
export const DELETE_REQUEST_SUCCESS = 'DELETE_REQUEST_SUCCESS'
export const DELETE_REQUEST_RESET = 'DELETE_REQUEST_RESET'
export const DELETE_REQUEST_FAIL = 'DELETE_REQUEST_FAIL'

export const UPDATE_STATUS_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_STATUS_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_STATUS_RESET = 'UPDATE_USER_RESET'
export const UPDATE_STATUS_FAIL = 'UPDATE_USER_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

