export const ALL_COORDINATES_REQUEST = 'ALL_COORDINATES_REQUEST'
export const ALL_COORDINATES_SUCCESS = 'ALL_COORDINATES_SUCCESS'
export const ALL_COORDINATES_FAIL = 'ALL_COORDINATES_FAIL'

export const UPDATE_COORDINATE_REQUEST = 'UPDATE_COORDINATE_REQUEST'
export const UPDATE_COORDINATE_SUCCESS = 'UPDATE_COORDINATE_SUCCESS'
export const UPDATE_COORDINATE_RESET = 'UPDATE_COORDINATE_RESET'
export const UPDATE_COORDINATE_FAIL = 'UPDATE_COORDINATE_FAIL'

export const COORDINATE_DETAILS_REQUEST = 'UPDATE_COORDINATECOORDINATE_DETAILSREQUEST'
export const COORDINATE_DETAILS_SUCCESS = 'COORDINATE_DETAILS_SUCCESS'
export const COORDINATE_DETAILS_RESET = 'COORDINATE_DETAILS_RESET'
export const COORDINATE_DETAILS_FAIL = 'COORDINATE_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'