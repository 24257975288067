import React, { useState, useCallback, useEffect, useRef } from 'react'
import MetaData from './layout/MetaData'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from 'react-redux';
import Loader from './layout/loader';
import { Slider, Fab, Tooltip, Popover, Box, IconButton, Avatar, Divider, Switch, Button, Card, CardContent, Typography, Grid, Paper, CardActions } from '@mui/material';
import { Link } from 'react-router-dom';
import { getAdminAnnouncements } from '../actions/announcementActions';
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const minimalDate = (createdAt) => {
    const date = new Date(createdAt);
    const now = new Date();
    // If more than a day ago, return month day, year format
    const options = { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toLocaleDateString('en-US', options);
};

const AnnouncementSection = () => {
    const listRef = useRef({});
    const rowHeights = useRef({});
    const dispatch = useDispatch();
    const { loading, error, announcements } = useSelector(state => state.announcements);

    useEffect(() => {
        dispatch(getAdminAnnouncements());
    }, [])
    // console.log('here are the announcements: ', announcements)

    function getRowHeight(index) {
        return rowHeights.current[index] + 8 || 82;
    }

    function Row({ index, style }) {
        const rowRef = useRef({});

        useEffect(() => {
            if (rowRef.current) {
                setRowHeight(index, rowRef.current.clientHeight);
            }
            // eslint-disable-next-line
        }, [rowRef]);

        const announcement =  announcements[announcements?.length - index - 1];
        return (
            <div style={style} key={announcements?._id}>
                {announcements?.length > 0 ?
  
                        <Box sx={{ m: 3, paddingRight: 3 }} ref={rowRef}>
                            <div className="card" data-label={minimalDate(announcement.createdAt)}>
                                <div className="card__container">
                                    <Typography variant='h5' sx={{ marginBottom: '1rem', mr: 15, color: '#303030' }}>
                                        <strong>{announcement.name}</strong>
                                        <Typography variant="subtitle1" color="text.secondary">
                                        By: {announcement.publisher?.title} {announcement.publisher?.username}
                                        </Typography>
                                    </Typography>
                                    <p className="card__body">
                                        {announcement.description}
                                    </p>
                                </div>
                            </div>
                        </Box>
                    
                    :
                    <Typography>No Announcements to Show for now</Typography>}</div>
        )
    }

    function setRowHeight(index, size) {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
    }



    return (
        <Box sx={{ width: '100%', }}>
            <Paper elevation={8} sx={{ borderRadius: 2, width: '100%', height: '99vh'  }} className={"profilecard"}>

                <div className='eight'>
                    <h1>Announcements</h1>
                </div>
                <Box sx={{ height: '92vh', overflowY: 'auto', overflowX: 'hidden' }}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                height={height}
                                itemCount={announcements?.length}
                                width={width}
                                itemSize={getRowHeight}
                                ref={listRef}
                            // onItemsRendered={handleItemsRendered}
                            >
                                {Row}

                            </List>
                        )}
                    </AutoSizer>

                </Box>


            </Paper>



        </Box>
    );
}

export default AnnouncementSection