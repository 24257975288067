import * as chatconst from'../constants/chatConstants';

export const createConvoReducer = (state = { loading: false, newconvoid: null, error: null }, action) => {
    switch (action.type) {
      case chatconst.NEW_CONVERSATION_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case chatconst.NEW_CONVERSATION_SUCCESS:
        return {
          ...state,
          loading: false,
          newconvoid: action.payload,
        };
      case chatconst.NEW_CONVERSATION_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case chatconst.NEW_CONVERSATION_RESET:
        return {
          loading: false,
          newconvoid: null,
          error: null,
        };
      default:
        return state;
    }
  };


export const userMessagesReducer =  (state = { umessages: [] }, action) => {
    switch (action.type) {
        case chatconst.USER_MESSAGES_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case chatconst.USER_MESSAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                umessages: action.payload
            }
        case chatconst.USER_MESSAGES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case chatconst.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
  }

export const userConversationsReducer =  (state = { uconvo: [] }, action) => {
  switch (action.type) {
      case chatconst.USER_CONVERSATIONS_REQUEST:
          return {
              ...state,
              loading: true,
          }
      case chatconst.USER_CONVERSATIONS_SUCCESS:
          return {
              ...state,
              loading: false,
              uconvo: action.payload
          }
      case chatconst.USER_CONVERSATIONS_FAIL:
          return {
              ...state,
              loading: false,
              error: action.payload
          }
      case chatconst.CLEAR_ERRORS:
          return {
              ...state,
              error: null
          }
      default:
          return state;
  }
}

export const myConversationsReducer =  (state = { convo: [] }, action) => {
    switch (action.type) {
        case chatconst.MY_CONVERSATIONS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case chatconst.MY_CONVERSATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                convo: action.payload
            }
        case chatconst.MY_CONVERSATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case chatconst.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const chatSendReducer = (state = { sending: false, sentMessage: false, error: null }, action) => {
    switch (action.type) {
      case chatconst.NEW_CHAT_REQUEST:
        return {
          ...state,
          sending: true,
        };
      case chatconst.NEW_CHAT_SUCCESS:
        return {
          ...state,
          sending: false,
          sentMessage: action.payload,
        };
      case chatconst.NEW_CHAT_FAIL:
        return {
          ...state,
          sending: false,
          error: action.payload,
        };
        case chatconst.CLEAR_ERRORS:
          return {
              ...state,
              error: null
          }
      case chatconst.NEW_CHAT_RESET:
        return {
          sending: false,
          sentMessage: null,
          error: null,
        };
      default:
        return state;
    }
  };

  export const markSeenReducer = (state = {}, action) => {
    switch (action.type) {
        case chatconst.MARK_SEEN_REQUEST:
            return {
                loading: true,
            };
        case chatconst.MARK_SEEN_SUCCESS:
            return {
                loading: false,
                success: action.payload,
            };
        case chatconst.MARK_SEEN_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
            case chatconst.CLEAR_ERRORS:
              return {
                  ...state,
                  error: null
              }
        default:
            return state;
    }
};
