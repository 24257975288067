import {
    ALL_COURSES_REQUEST,
    ALL_COURSES_SUCCESS,
    ALL_COURSES_FAIL,
    COURSE_DETAILS_REQUEST,
    COURSE_DETAILS_SUCCESS,
    COURSE_DETAILS_FAIL,
    ADMIN_COURSES_REQUEST,
    ADMIN_COURSES_SUCCESS,
    ADMIN_COURSES_FAIL,
    NEW_COURSE_REQUEST,
    NEW_COURSE_SUCCESS,
    NEW_COURSE_FAIL,
    DELETE_COURSE_REQUEST,
    DELETE_COURSE_SUCCESS,
    DELETE_COURSE_FAIL,
    UPDATE_COURSE_REQUEST,
    UPDATE_COURSE_SUCCESS,
    UPDATE_COURSE_FAIL,
    CLEAR_ERRORS,
    DELETE_COURSE_RESET,
    UPDATE_COURSE_RESET,
    COURSE_DETAILS_RESET,
    NEW_COURSE_RESET
} from '../constants/courseConstants';

export const allCoursesReducer = (state = { courses: [] }, action) => {
    switch (action.type) {
        case ALL_COURSES_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ALL_COURSES_SUCCESS:
            return {
                ...state,
                loading: false,
                courses: action.payload
            }
        case ALL_COURSES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

const initialState = {
    loading: false,
    success: null,
    isDeleted: null,
    isUpdated: null,
    error: null,
};

export const courseReducer = (state = initialState, action) => {
    switch (action.type) {
        // New Course
        case NEW_COURSE_REQUEST:
        // Update Course
        case UPDATE_COURSE_REQUEST:
        // Delete Course
        case DELETE_COURSE_REQUEST:
            return { ...state, loading: true, success: null, error: null };

        // New Course Success
        case NEW_COURSE_SUCCESS:
            return { ...state, loading: false, success: action.payload, error: null };
        // Update Course Success
        case UPDATE_COURSE_SUCCESS:
            return { ...state, loading: false, isUpdated: action.payload, error: null };

        // New Course Fail
        case NEW_COURSE_FAIL:
        // Update Course Fail
        case UPDATE_COURSE_FAIL:
        // Delete Course Fail
        case DELETE_COURSE_FAIL:
            return { ...state, loading: false, success: null, error: action.payload };

        // Delete Course Success
        case DELETE_COURSE_SUCCESS:
            return { ...state, loading: false, isDeleted: action.payload, error: null };
        case DELETE_COURSE_RESET:
            return {
                ...state,
                isDeleted: false
            }
        case UPDATE_COURSE_RESET:
            return {
                ...state,
                isUpdated: false
            }
        case NEW_COURSE_RESET:
            return {
                ...state,
                success: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
};

export const courseDetailsReducer = (state = { course: {} }, action) => {
    switch (action.type) {
        case COURSE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case COURSE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                course: action.payload
            }
        case COURSE_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case COURSE_DETAILS_RESET:
            return {
                ...state,
                loading: false,
                course: {}
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}
