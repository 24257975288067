import React, { Fragment, useEffect, useState } from 'react';
import MetaData from './layout/MetaData'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from 'react-redux';
import Loader from './layout/loader';
import MyCarousel from './layout/myCarousel';
import { Slider, Fab, Tooltip, Popover, Box, IconButton, Avatar, Divider, Switch, Button, Card, CardContent, Typography, Grid } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import BugReportIcon from '@mui/icons-material/BugReport';
import { red, cyan, green } from '@mui/material/colors';
import AlarmIcon from '@mui/icons-material/Alarm';
import { Link } from 'react-router-dom';
import { updateStatus } from '../actions/userActions';
import AnnouncementSection from './announcementSection';
import RequestsSection from './requestsSection';
import NotifsSection from './notifSection';


const Home = () => {
    const dispatch = useDispatch();
    const { user, loading } = useSelector(state => state.auth)
    const [isUnlocked, setIsUnlocked] = useState(user?.status === "active");
    const [sliderDisabled, setSliderDisabled] = useState(false);
    const [sliderValue, setSliderValue] = useState(0);

    useEffect(() => {
        if (user) {
            setIsUnlocked(user.status === 'active')
            if (user.status === 'active') {
                setSliderValue(100)
            } else {
                setSliderValue(0)
            }
        }

    }, [user])


    const handleSlideChange = (_, value) => {
        setSliderValue(value);
        if (value === 100) {
            setIsUnlocked(true);
            dispatch(updateStatus(user._id, "active"));
        } else if (value === 0) {
            setIsUnlocked(false);
            dispatch(updateStatus(user._id, "online"));
        }

        if (value === 100 || value === 0) {
            // setIsUnlocked(true);
            setSliderDisabled(true); // Disable slider
            setTimeout(() => {
                //   setIsUnlocked(false);
                setSliderDisabled(false); // Enable slider after 2 seconds
            }, 3000);
        }
    };

    useEffect(() => {
        const cards = document.getElementsByClassName('profilecard');
        // const card2 = document.getElementById('profile-card2');
        if (isUnlocked) {
            // Iterate over the collection returned by getElementsByClassName
            Array.from(cards).forEach(card => {
                card.classList.remove('locked');
                card.classList.add('unlocked');
            });
            // Manipulate the element retrieved by getElementById
            // card2.classList.add('unlocked');
        } else {
            // Iterate over the collection returned by getElementsByClassName
            Array.from(cards).forEach(card => {
                card.classList.add('locked');
                card.classList.remove('unlocked');
            });
            // Manipulate the element retrieved by getElementById
            // card2.classList.remove('unlocked');
        }
    }, [isUnlocked]);
    return (
        <Box>
            {/* {loading ? <Loader /> : ( )} */}
            {/* <Loader/> */}
            <Box>
                <MetaData title={"RAD4: Request and Delivery"} />
                {user ? <>
                        <Grid container direction="row" spacing={0}>
                          
                                <Grid item xs={12} md={7}>
                                <Box sx={{ m: 1,  height: '100vh'}}>
                                    <AnnouncementSection/>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                <Box sx={{ m: 1,  height: '100vh'}}>
                                    {(user?.role === 'admin' || user?.role === 'faculty') ? <RequestsSection/> : <Box sx={{height: '99vh'}}><NotifsSection/> </Box>}
                                    {/* <RequestsSection/> */}
                                    
                                    </Box>
                                </Grid>
                                </Grid>

                                {/* For small devices */}
                                {/* <Box sx={{display: { xs: "flex", md: "none" }, justifyContent: 'flex-start', alignItems: 'center' }}>

                            <Card id={`profile-card2`}
                                sx={{ width: '100%', height: 'auto', borderRadius: 2 }} elevation={8}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        overflow: 'hidden',
                                        backgroundColor: '#4CAF50',
                                        width: '100%',
                                        height: 200,
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        justifyContent: 'center', // Add this line
                                    }}
                                >
                                    <img src={user.avatar?.url} alt="User Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />

                                    <Box sx={{ position: 'absolute', bottom: 0, textAlign: 'center', width: '100%', textShadow: '0 0 15px white' }}>

                                    </Box>
                                    <Link to="/me/update">
                                        <IconButton
                                            //   color="success"
                                            aria-label="edit-profile"
                                            sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Link>
                                </Box>
                                <CardContent>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        <Typography variant="h5" fontWeight="bold" gutterBottom>
                                            {user?.username}
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ marginTop: -1.8 }} gutterBottom>
                                            <em>{user?.email}</em>
                                        </Typography>

                                        <Grid container spacing={1} alignItems="center" sx={{ textAlign: 'center' }}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Role
                                                </Typography>
                                                <Typography variant="h6" gutterBottom fontWeight="bold" sx={{ color: '#404040', marginTop: -1 }}>
                                                    {user?.role}
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Course
                                                </Typography>
                                                <Typography variant="h6" gutterBottom fontWeight="bold" sx={{ color: '#404040', marginTop: -1 }}>
                                                    {String(user?.coYeSe).substring(0, 10)}
                                                </Typography>

                                            </Grid>
                                        </Grid>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', mt: 2 }}>
                                            {user.role === 'faculty' || user.role === 'admin' && (
                                                <><Typography variant="body2" color="textSecondary" align="center" sx={{ mb: 2 }}>
                                                    Slide to Set Active Status
                                                </Typography><Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                                        <Slider
                                                            value={sliderValue}
                                                            disabled={sliderDisabled}
                                                            valueLabelDisplay="off"
                                                            aria-label="unlock-slider"
                                                            onChange={handleSlideChange}
                                                            sx={{
                                                                mb: 3,
                                                                width: "40vh",
                                                                '& .MuiSlider-rail': {
                                                                    height: 40,
                                                                    borderRadius: 1,
                                                                    backgroundColor: isUnlocked ? '#09a94f' : 'gray',
                                                                },
                                                                '& .MuiSlider-track': {
                                                                    height: 40,
                                                                    borderRadius: 1,
                                                                    backgroundColor: isUnlocked ? '#09a94f' : 'gray',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 45,
                                                                    height: 45,
                                                                    borderRadius: 1,
                                                                    backgroundColor: 'white',
                                                                    boxShadow: '0px 0px 5px rgba(0,0,0,0.5)',
                                                                    cursor: 'pointer',
                                                                    //   marginTop: -5,
                                                                    zIndex: 2,
                                                                },
                                                            }} />

                                                    </Box></>

                                            )}

                                        </Box>
                                        <Box sx={{ width: '100%', display: 'block' }}>
                                            <Divider sx={{ backgroundColor: '#000' }} />
                                        </Box>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Tooltip title="Report a Bug">
                                                <IconButton>
                                                    <BugReportIcon sx={{ fontSize: 50, color: '#182c25' }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Show History">
                                                <IconButton>
                                                    <HistoryIcon sx={{ fontSize: 50, color: '#182c25' }} />
                                                </IconButton>
                                            </Tooltip>
                                            {((user?.role === 'admin') || (user?.role === 'faculty') || (user?.role === 'tech')) &&
                                         <Tooltip title="Scheduled Delivery">
                                         <IconButton>
                                             <AlarmIcon sx={{ fontSize: 50, color: '#182c25' }} />
                                         </IconButton>
                                     </Tooltip>
                                        }
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box> */}

</>   : <MyCarousel />
                }

                            {/* <Loader/> */}

                        </Box>

                    </Box>

                )

}

                export default Home