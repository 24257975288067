import axios from "axios";
import * as stat from "../constants/StatConstants"

export const getUserDelHistory = () => async (dispatch) => {
    try {
    
        dispatch({ type: stat.DELIVERY_HISTORY_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/my-delivery-history`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: stat.DELIVERY_HISTORY_SUCCESS,
            payload: data.userDeliveryHistory,
        })
    } catch (error) {
        dispatch({
            type: stat.DELIVERY_HISTORY_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getUserReqHistory = () => async (dispatch) => {
    try {
    
        dispatch({ type: stat.REQUEST_HISTORY_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/my-request-history`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: stat.REQUEST_HISTORY_SUCCESS,
            payload: data.userRequestHistory,
        })
    } catch (error) {
        dispatch({
            type: stat.REQUEST_HISTORY_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getReqStats = () => async (dispatch) => {
    try {
    
        dispatch({ type: stat.REQUEST_STATISTICS_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/request-reports`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: stat.REQUEST_STATISTICS_SUCCESS,
            payload: data.data,
            reports: data.reports
        })
    } catch (error) {
        dispatch({
            type: stat.REQUEST_STATISTICS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getDlvryStats = () => async (dispatch) => {
    try {
    
        dispatch({ type: stat.DELIVERY_STATISTICS_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/delivery-reports`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: stat.DELIVERY_STATISTICS_SUCCESS,
            payload: data.data,
            reports: data.reports
        })
    } catch (error) {
        dispatch({
            type: stat.DELIVERY_STATISTICS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: stat.CLEAR_ERRORS

    })
}
