import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import { Box, Typography, Button, Tabs, Tab, Tooltip } from "@mui/material";
import LocalShippingRounded from "@mui/icons-material/LocalShippingRounded";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, updateDeliveryQueue, updateDeliveryStatus } from "../actions/deliveryActions";
import { getDeliveries, myDeliveries as getMyDeliveries } from "../actions/deliveryActions";
import { UPDATE_DELIVERYQUEUE_RESET, UPDATE_STATUS_RESET } from "../constants/deliveryConstants";
import { TabDelivery } from "./layout/tabDelivery";
import useWebSocket from "../socket/webSocket";
const a11yProps = (index) => {
  return {
    id: `delivery-tab-${index}`,
    "aria-controls": `delivery-tabpanel-${index}`,
  };
};

const DeliverySidebar = ({ setDeliverySideBar, DeliverySideBar, myDeliveries, user }) => {
  const listRef = useRef({});
  const rowHeights = useRef({});
  const [isCancelled, setIsCancelled] = useState(false);
  const [queuedDeliveries, setQueuedDeliveries] = useState(false);
  const [isQueue, setIsQueue] = useState(false);
  const { success, isUpdated, error } = useSelector(state => state.deliveries)
  const { Deliveries } = useSelector(state => state.myDeliveries)
  const dispatch = useDispatch();
  const { socket, isConnected } = useWebSocket(`${process.env.REACT_APP_WEBSOCKET_API}`);
  const [Tabvalue, setTabValue] = useState(0);
  const TabhandleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const errMsg = (message = '') => toast.error(message, {
    position: toast.POSITION.BOTTOM_CENTER
  });

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
};
  // // console.log('My Deliveries: ', myDeliveries)

  useEffect(() => {
    if (isConnected && user) {
        const handleDelivery = ({ stor, stee, stud, cys }) => {
            // Check if user._id matches any of the IDs or user.coYeSe matches cys
            if(user?.role === 'admin' || user?.role === 'faculty'){
            // if (
            //     (stud && user?._id === stud) ||
            //     (stor && user?._id === stor) ||
            //     (stee && user?._id === stee) ||
            //     (cys && user?.coYeSe === cys)
            // ) {
                // Dispatch the action to fetch notifications if the conditions are met
                dispatch(getDeliveries());
            }
        };

        socket.on("delivery", handleDelivery);

        // Cleanup the event listener when the component unmounts or dependencies change
        return () => {
            socket.off("delivery", handleDelivery);
        };
    }
}, [isConnected, socket, user, dispatch]);

  useEffect(() => {
    // // console.log(`deliveries`, Deliveries)
    const filterAndSortQueuedDeliveries = Deliveries
      .filter(delivery => delivery.status === 'In queue')
      .sort((a, b) => a.queue - b.queue);
    setQueuedDeliveries(filterAndSortQueuedDeliveries);
  }, [Deliveries, myDeliveries]);


  useEffect(() => {
    dispatch(getDeliveries())
    if (error) {
      errMsg(error);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      if (isCancelled && !isQueue) {
        Swal.fire({
          title: 'Cancelled!',
          text: 'Delivery Cancellation Successful. Thank you for using RAD4.',
          icon: 'success',
        });
        dispatch({ type: UPDATE_STATUS_RESET });
        setIsCancelled(false)
      }
      if (!isCancelled && isQueue) {
        Swal.fire({
          title: 'Queue Updated!',
          text: 'Delivery Queue updated successfully. Thank you for using RAD4.',
          icon: 'success',
        });
        dispatch({ type: UPDATE_DELIVERYQUEUE_RESET });
        setIsQueue(false)
      }
      dispatch(getMyDeliveries())
      dispatch(getDeliveries())
    }
  }, [isUpdated, dispatch, error])

  function getRowHeight(index) {
    return rowHeights.current[index] + 8 || 82;
  }

  const getCodes = ['getItem', 'getKey', 'getItemWKey'];
  const returnCodes = ['returnItem', 'returnKey', 'returnItemWKey']
  const studentRoles = ['student', 'CO'];
  const facultyRole =['admin', 'faculty'];
  const unCancellableStatus = ['ready', 'Ready', 'On going'];
  const getRequestDescription = (code, keyNo) => {
    const codeMap = {
      getItem: "Get Item",
      getItemWKey: `Get Item & Key for Room ${keyNo}`,
      getKey: `Get Key for Room ${keyNo}`,
      returnItem: "Return Item"
    };

    // Use the code as a key to look up its description in the codeMap
    return codeMap[code] || "Unknown Request";
  };

  const handleDeliveryInfo = async (deliveryInfo, queued) => {
    const isUserInFacultyRole = facultyRole.includes(user?.role);
    const unCancellable = unCancellableStatus.includes(deliveryInfo.status)
    await Swal.fire({
         title: "Delivery Details",
         html: ` Delivery type: ${getRequestDescription(deliveryInfo.code, deliveryInfo.key?.keyNo)} <br> ${(deliveryInfo?.code === 'getKey') ? `Approved By: ${deliveryInfo?.requestee?.title} ${deliveryInfo?.requestee?.username}`: `From: ${getCodes.includes(deliveryInfo?.code) ? `${deliveryInfo?.requestee?.title} ${deliveryInfo?.requestee?.username}` : studentRoles.includes(deliveryInfo?.requestor?.role) ? `${deliveryInfo?.requestor?.username} ${deliveryInfo?.requestor?.coYeSe}` : deliveryInfo?.stud ? `${deliveryInfo?.stud?.username} ${deliveryInfo?.stud?.coYeSe}` : `${deliveryInfo.cys} class officers`}`}  <br>  To: ${getCodes.includes(deliveryInfo?.code) ? studentRoles.includes(deliveryInfo?.requestor?.role) ? `${deliveryInfo?.requestor?.username} ${deliveryInfo?.requestor?.coYeSe}` : deliveryInfo?.stud ? `${deliveryInfo?.stud?.username} ${deliveryInfo?.stud?.coYeSe}` : `${deliveryInfo?.cys} class officers` : `${deliveryInfo?.requestee?.title} ${deliveryInfo?.requestee?.username}`}  <br> Message: ${deliveryInfo?.caption}`,
       
        showCloseButton: true,
        showCancelButton:  (isUserInFacultyRole && !queued && !unCancellable),
        showConfirmButton: (isUserInFacultyRole && queued),
        confirmButtonText: 'Edit Queue Order',
        confirmButtonColor: '#355e3b',
        cancelButtonText: 'Cancel Delivery',
        cancelButtonColor: '#ca1011'

     }).then((result) => {
      if (result.isConfirmed) {
        setIsQueue(true)
        setIsCancelled(false)
        Swal.fire({
          title: 'Change Queue Order',
          input: 'number',
          inputLabel: 'Your number',
          inputValue: deliveryInfo.queue,
          inputPlaceholder: 'Desired Queue Position',
          showCancelButton: true,
          confirmButtonText: 'Update',
          confirmButtonColor: '#355e3b',
          cancelButtonText: 'Cancel',
          preConfirm: (number) => {
            if (!number) {
              Swal.showValidationMessage('You need to enter a number');
              return false;
            }
            return number;
          }
        }).then((result) => {
          if (result.isConfirmed) {
            const queueNo = result.value;
            const formData = new FormData();
            formData.set('queue', queueNo);
            dispatch(updateDeliveryQueue(deliveryInfo._id, formData))
          }
          
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        setIsCancelled(true)
        setIsQueue(false)
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, cancel it!"
        }).then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();
            formData.set('status', 'Cancelled');
            dispatch(updateDeliveryStatus(deliveryInfo._id, formData))
          }
        });
      }
    });
 }

  function Row({ index, style, delivery, queued }) {
    const rowRef = useRef();
    useEffect(() => {
      if (!queued) {
        // Define a custom sorting function
        const priorityOrder = {
          'Ready': 0,
          'On going': 1,
          'In queue': 2
        };

        // Sort the deliveries array based on priority
        myDeliveries.sort((a, b) => priorityOrder[a.status] - priorityOrder[b.status]);
      }
    }, []);
    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef, index]);

    return (
      <div style={style}>
        <Box
          sx={{
            m: 1,
            backgroundColor: '#000',
            borderRadius: 8,
            padding: 2,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s, box-shadow 0.3s',
            ':hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            },
            display: 'flex',
            flexDirection: 'column',
          }}
          ref={rowRef}
        >
          
          <Tooltip arrow placement={"left"} title={"View more delivery details"}>
          <Box sx={{ cursor: 'pointer'}}onClick={()=>handleDeliveryInfo(delivery, queued)}>
          {queued ? <Typography>
            Queue # <span style={{ color: '#42a5f5' }}><strong>{delivery?.queue} </strong></span>
          </Typography>:  <Typography>
            Status: <span style={{ color: delivery?.status === 'In queue' ? '#42a5f5' : delivery?.status === 'On going' ? '#f57c00' : delivery?.status === 'Ready' ? '#388e3c' : delivery?.status === 'ready' ? '#388e3c' : 'inherit' }}><strong>{delivery?.status} {delivery?.status === 'ready' && 'to pick up'} {delivery?.status === 'Ready' && 'for pick up'}</strong></span>
          </Typography>}
         
          <Typography>
           {(delivery?.code === 'getKey') ? `Approved By: ${delivery?.requestee?.title} ${delivery?.requestee?.username}`: `From: ${getCodes.includes(delivery?.code) ? `${delivery?.requestee?.title} ${delivery?.requestee?.username}` : studentRoles.includes(delivery?.requestor?.role) ? `${delivery?.requestor?.username} ${delivery?.requestor?.coYeSe}` : delivery?.stud ? `${delivery?.stud?.username} ${delivery?.stud?.coYeSe}` : `${delivery.cys} class officers`}`} 
          </Typography>
          <Typography>
            To: {getCodes.includes(delivery?.code) ? studentRoles.includes(delivery?.requestor?.role) ? `${delivery?.requestor?.username} ${delivery?.requestor?.coYeSe}` : delivery?.stud ? `${delivery?.stud?.username} ${delivery?.stud?.coYeSe}` : `${delivery?.cys} class officers` : `${delivery?.requestee?.title} ${delivery?.requestee?.username}`}
          </Typography>
          {delivery?.requestor?.role === 'CO' && <Typography> Approved By: {delivery?.requestee?.username} </Typography>}
          <Typography>Order: {getRequestDescription(delivery?.code, delivery?.key?.keyNo)}</Typography>
          {/* <Typography>Message: {truncateText(delivery?.caption, 15)}</Typography> */}
          </Box></Tooltip>
        </Box>
      </div>
    );
  }

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  const renderDeliveries = (queued) => (
    <Box sx={{ height: '100%' }}>
      {myDeliveries?.length > 0 ? (
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              itemCount={queued ? queuedDeliveries?.length : myDeliveries?.length}
              width={width}
              itemSize={getRowHeight}
              ref={listRef}
            >
              {({ index, style }) => (
                <Row
                  index={index}
                  style={style}
                  delivery={queued ? queuedDeliveries[index] : myDeliveries[index]}
                  queued={queued}
                />
              )}
            </List>
          )}
        </AutoSizer>
      ) : (
        <Typography
          sx={{
            height: '100%',
            color: '#404040',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {!queued ? `You have no pending Deliveries for today` : `There are no pending Deliveries for today`}


        </Typography>
      )}
    </Box>
  );

  return (
    <Box>
      <Sidebar
        //  width="40%"
        rtl
        onBackdropClick={() => setDeliverySideBar(false)}
        width="330px"
        toggled={DeliverySideBar}
        breakPoint="all"
        backgroundColor="#101a11"
        style={{
          border: "1px solid #101a11",
          height: "100vh",
          overflowY: "auto",
          color: "#fff",
        }}
      >
        <Menu>
          <Box sx={{ padding: "10px", marginTop: "64px" }}>

            <Typography
              sx={{
                color: "fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                fontSize: "25px",
              }}
            >
              <strong>DELIVERIES</strong>   <LocalShippingRounded sx={{ fontSize: "40px", pr: "15px" }} />
            </Typography>
          </Box>
          {studentRoles.includes(user?.role) ? 
           <Box sx={{height: '79vh'}}>
           {renderDeliveries(false)}</Box> 
          : 
          <Box>
            <Tabs
            value={Tabvalue}
            onChange={TabhandleChange}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#2e7d32",
              },
            }}
            centered
            variant="fullWidth"
            textColor="#FFF"
            // indicatorColor="#2e7d32"
            aria-label="secondary tabs example"
          >
            <Tab
              label="Queued Deliveries"
              {...a11yProps(1)}
              sx={{ "&.Mui-selected": { color: "#2e7d32" } }}
            />
            <Tab
              label="My Deliveries"
              {...a11yProps(0)}
              sx={{ "&.Mui-selected": { color: "#2e7d32" } }}
            />
          </Tabs>
       
          <TabDelivery value={Tabvalue} index={1}>
          <Box sx={{height: '66vh'}}>
            {renderDeliveries(false)}</Box> 
         </TabDelivery>

          <TabDelivery value={Tabvalue} index={0}>
          <Box sx={{height: '66vh'}}>
            {renderDeliveries(true)}</Box>  </TabDelivery> </Box>
          }
        
        </Menu>
      

      </Sidebar>
    </Box>
  )
}

export default DeliverySidebar;
