import React, { useState, useCallback, useEffect, useRef } from 'react'
import MetaData from './layout/MetaData'
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from 'react-redux';
import {  Box,   Typography,  Paper  } from '@mui/material';
import { myNotifications } from '../actions/notifActions';
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
// import initializeSocket from '../socket/webSocket';
import useWebSocket from '../socket/webSocket';

const minimalDate = (createdAt) => {
    const date = new Date(createdAt);
    const now = new Date();
    // If more than a day ago, return month day, year format
    const options = { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toLocaleDateString('en-US', options);
};

const NotifsSection = () => {
    const listRef = useRef({});
    const rowHeights = useRef({});
    const dispatch = useDispatch();
    const { myNotifs } = useSelector(state => state.myNotifs);
    const { user } = useSelector(state => state.auth);
    const { socket, isConnected } = useWebSocket(`${process.env.REACT_APP_WEBSOCKET_API}`);
    useEffect(() => {
        dispatch(myNotifications());
    }, [])
    useEffect(() => {
        if (isConnected) {
            const handleNotification = (notification) => {
                if ((notification?.userId === user?._id || notification?.userId === null) &&
                    (notification?.cys === user?.coYeSe || notification?.cys === null)) {
                    dispatch(myNotifications());
                }
            };

            socket.on("notif", handleNotification);

            // Clean up the event listener when component unmounts or when isConnected changes
            return () => {
                socket.off("notif", handleNotification);
            };
        }
    }, [isConnected, socket, user, dispatch]);// Include dispatch in the dependency array to prevent unnecessary reconnections
    // // console.log('here are the announcements: ', myNotifs)

    
 
  

    function getRowHeight(index) {
        return rowHeights.current[index] + 8 || 82;
    }

    function Row({ index, style }) {
        const rowRef = useRef({});

        useEffect(() => {
            if (rowRef.current) {
                setRowHeight(index, rowRef.current.clientHeight);
            }
            // eslint-disable-next-line
        }, [rowRef]);

        const notif = myNotifs[myNotifs?.length - index - 1];
        return (
            <div style={style} key={myNotifs?._id}>
               
  
                        <Box sx={{ m: 3, paddingRight: 1 }} ref={rowRef}>
                            <div className="card" data-label={minimalDate(notif?.createdAt)}>
                                <div className="card__container">
                                        <Typography variant='h6' sx={{ marginTop: '0.5rem', marginBottom: '1rem', color: '#303030' }}>
                                        <strong>{notif?.title}</strong> 
                                    </Typography>
                                    <p className="card__body">
                                    <Typography variant="subtitle1" color="text.secondary">
                                            {notif?.message}
                                        </Typography>
                                    </p>
                                  
                                </div>
                            </div>
                        </Box>
                    
                  </div>
        )
    }

    function setRowHeight(index, size) {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
    }



    return (

            <Paper elevation={8} sx={{ borderRadius: 2, width: '100%', height: '100%' }} className={"profilecard"}>

                <div className='eight'>
                    <h1>Notifications</h1>
                </div>
                <Box sx={{ height: '94%', overflow: 'auto', overflowX: 'hidden' }}>
                {myNotifs?.length > 0 ?
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                height={height}
                                itemCount={myNotifs?.length}
                                width={width}
                                itemSize={getRowHeight}
                                ref={listRef}
                            // onItemsRendered={handleItemsRendered}
                            >
                                {Row}

                            </List>
                        )}
                    </AutoSizer>
                      :
                      <Typography sx={{ height: '100%', color: '#404040', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>You have no notifications for today</Typography>}

                </Box>


            </Paper>
    );
}

export default NotifsSection