import * as stat from '../constants/StatConstants'

export const requestStatReducer = (state = { reqstat: [], reqReport: [] }, action) => {
    switch (action.type) {
      case stat.REQUEST_STATISTICS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case stat.REQUEST_STATISTICS_SUCCESS:
        return {
          ...state,
          loading: false,
          reqstat: action.payload,
          reqReport: action.reports
        };
      case stat.REQUEST_STATISTICS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case stat.CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };

  export const deliveryHistoryReducer = (state = { delHistory: [] }, action) => {
    switch (action.type) {
      case stat.DELIVERY_HISTORY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case stat.DELIVERY_HISTORY_SUCCESS:
        return {
          ...state,
          loading: false,
          delHistory: action.payload,
        };
      case stat.DELIVERY_HISTORY_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case stat.CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };

  export const requestHistoryReducer = (state = { reqHistory: [] }, action) => {
    switch (action.type) {
      case stat.REQUEST_HISTORY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case stat.REQUEST_HISTORY_SUCCESS:
        return {
          ...state,
          loading: false,
          reqHistory: action.payload,
        };
      case stat.REQUEST_HISTORY_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case stat.CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };

  export const deliveryStatReducer = (state = { dlvryStat: [], dlvryReport: [] }, action) => {
    switch (action.type) {
      case stat.DELIVERY_STATISTICS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case stat.DELIVERY_STATISTICS_SUCCESS:
        return {
          ...state,
          loading: false,
          dlvryStat: action.payload,
          dlvryReport: action.reports
        };
      case stat.DELIVERY_STATISTICS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case stat.CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };