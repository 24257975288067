import React, { useEffect, useState } from "react";
import { Line, Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { fetchChartUserData } from "../../actions/chartActions";
import "chart.js/auto";
import { ArrowBack, CalendarMonthRounded, Print } from "@mui/icons-material";
import { Fab, IconButton, Paper, Tooltip, Popover, List, ListItem, ListItemText, ListItemButton, Box } from "@mui/material";
import { allUsers } from "../../actions/userActions";
import { Chart } from "chart.js/auto";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

const UserCharts = ({ dashboard }) => {
  const dispatch = useDispatch();
  const chartDataState = useSelector((state) => state.chart);
  const { loading: uloading, users } = useSelector((state) => state.allUsers)
  const paperStyle = {
    width: '700px', height: '40%', m: 'auto', mt: '15px'
  }
  const [selectedFilter, setSelectedFilter] = useState('total');
  const [anchorEl, setAnchorEl] = useState(null);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = (filter) => {
    // Implement filtering logic based on the selected filter
    setSelectedFilter(filter);
    handleClose();
  };

  useEffect(() => {
    if (!users) {
      dispatch(allUsers())
    }
    dispatch(fetchChartUserData());
  }, [dispatch]);

  const { loading, error, chartData } = chartDataState;

  // console.log("chartDataState:", chartDataState);
  // console.log("chartData:", chartData);

  const chartOptions = {
    scales: {
      x: [
        {
          type: "time",
          time: {
            unit: "day",
          },
        },
      ],
      y: {
        beginAtZero: true,
      },
    },
    elements: {
      line: {
        cubicInterpolationMode: "monotone",
      },
    },
  };

  // Function to convert hex color to RGB
  const hexToRgb = (hexColor) => {
    const bigint = parseInt(hexColor.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  };

  // Function to generate color for each role with adjustable opacity
  const generateRoleColor = (index, opacity) => {
    const colorScale = [
      "#FF5733",
      "#33FF57",
      "#5733FF",
      "#FF33E9",
      "#33E9FF",
      "#E9FF33",
    ];

    // Adjust the color scale based on the number of roles
    const adjustedIndex = index % colorScale.length;

    // Add the opacity to the color
    return `rgba(${hexToRgb(colorScale[adjustedIndex])}, ${opacity})`;
  };

  // Set fill and background color for each role with adjusted opacity
  if (chartData.datasets) {
    chartData.datasets.forEach((dataset, index) => {
      dataset.fill = true;
      dataset.backgroundColor = generateRoleColor(index, 0.5); // Adjust opacity here
    });
  }

  const renderLineChart = () => {
    let registrationsByDateAndRole = {};
    const roleColors = {
      student: 'rgba(255, 99, 132)', // Red
      user: 'rgba(54, 162, 235)', // Blue
      faculty: 'rgba(255, 206, 86)', // Yellow
      admin: 'rgba(75, 192, 192)', // Green
      tech: 'rgba(153, 102, 255)', // Purple
      CO: 'rgba(255, 159, 64)' // Orange
      // Add more colors if needed for additional roles
    };

    // Calculate user registrations per day per role
    users.forEach(user => {
      const registrationDate = new Date(user.createdAt);
      const year = registrationDate.getFullYear();
      const month = registrationDate.getMonth() + 1;
      const day = registrationDate.getDate();
      const key = selectedFilter === 'monthly' ? `${year}-${month}` : selectedFilter === 'yearly' ? `${year}` : `${year}-${month}-${day}`;
      const role = user.role;

      if (!registrationsByDateAndRole[key]) {
        registrationsByDateAndRole[key] = {};
      }

      if (!registrationsByDateAndRole[key][role]) {
        registrationsByDateAndRole[key][role] = 0;
      }

      registrationsByDateAndRole[key][role]++;
    });

    // Prepare data for chart
    const labels = Object.keys(registrationsByDateAndRole);
    const roles = [...new Set(users.map(user => user.role))];
    const datasets = roles.map(role => {
      return {
        label: role,
        data: labels.map(date => registrationsByDateAndRole[date][role] || 0),
        backgroundColor: roleColors[role] || `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.2)`, // Use fixed color if available, otherwise generate random color
        borderColor: roleColors[role] || `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 1)`, // Use fixed color if available, otherwise generate random color
        borderWidth: 1
      };
    });

    // Create chart data
    const data = {
      labels: labels,
      datasets: datasets
    };

    // Chart options
    const options = {
      aspectRatio: 1, // Adjust this value to change the aspect ratio
      maintainAspectRatio: false, // Set to false to allow the chart to resize based on container size    
      scales: {
        y: {
          beginAtZero: true
        }
      }
    };

    return <Line data={data} options={options} />;
  };

  const StudentsChart = ({ users }) => {
    // Filter users with student or CO role
    const studentUsers = users.filter(user => user.role === 'student' || user.role === 'CO');

    // Initialize an object to store student counts by course and section
    const courseSectionCounts = {};

    // Count the number of students in each course and section
    studentUsers.forEach(user => {
      const { coYeSe } = user;
      const [course, ...rest] = coYeSe.split('-');
      const section = rest.join('-');

      if (!courseSectionCounts[course]) {
        courseSectionCounts[course] = {};
      }

      if (!courseSectionCounts[course][section]) {
        courseSectionCounts[course][section] = 0;
      }

      courseSectionCounts[course][section]++;
    });

    // Prepare data for the chart
    // Prepare data for the chart
    const courses = Object.keys(courseSectionCounts);
    const sections = [...new Set(Object.values(courseSectionCounts).flatMap(Object.keys))];
    const chartData = {
      labels: courses,
      datasets: sections.map(section => ({
        label: section,
        data: courses.map(course => courseSectionCounts[course][section] || 0), // Map section counts, default to 0 if undefined
        backgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.6)`,
      })),
    };


    // console.log('courseSectionCounts: ', courseSectionCounts)
   
    // Chart options
    const options = {
      aspectRatio: 1, // Adjust this value to change the aspect ratio
      maintainAspectRatio: false, // Set to false to allow the chart to resize based on container size    
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
    };

    return <Bar data={chartData} options={options} />;
  };



  useEffect(() => {
    renderLineChart();
    // StudentsChart(users);
  }, [users]);

  return (
    <div>
      {loading ? (
        <p>Loading chart data...</p>
      ) : error ? (
        <p>Error fetching chart data: {error}</p>
      ) : (<div><Box ref={componentRef}>
        <Box className="no-print">
          <IconButton sx={{
            ml: 3, mb: -10,
            '&:hover': {
              // Add styles for hover state
              backgroundColor: 'rgba(0, 0, 0, 0.0)',
              color: '#49B265',
              // Add any other styles you want to apply on hover
            },
          }}
            onClick={dashboard}
          ><ArrowBack sx={{
            fontSize: 35, color: '#000',
            '&:hover': {
              // Add styles for hover state
              color: '#49B265',
              // Add any other styles you want to apply on hover
            },
          }}></ArrowBack></IconButton>
        </Box>
        <Box className="d-margin">


          <div className='thirteen'>
            <h1>User Reports</h1>
          </div>
          {/* <Paper elevation={8} sx={{...paperStyle}}>
        <Line
          data={{
            labels: chartData.labels,
            datasets: chartData.datasets,
          }}
          options={chartOptions}
        /></Paper> */}

          <Paper elevation={8} sx={{ ...paperStyle }}>
            <div class="seven">
              <h1>Registered Users</h1>
            </div>
            <Box sx={{ mx: 'auto', height: '320px', width: '100%' }}>
            {renderLineChart()} </Box></Paper>

          <Paper elevation={8} sx={{ ...paperStyle }}>
            <div class="seven">
              <h1>Student Courses</h1>
            </div>
            <Box sx={{ mx: 'auto', height: '320px' }}>
            <StudentsChart users={users} /></Box></Paper>

<Box className="no-print">
          <Tooltip title="Filter Date" placement="top">
            <Fab
              color="success"
              aria-label="filter"
              onClick={handleClick}
              sx={{ position: "fixed", top: 500, right: 40, zIndex: "20" }}
            >
              <CalendarMonthRounded />
            </Fab>
          </Tooltip></Box>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <List>
              <ListItemButton onClick={() => handleFilter('total')}>
                <ListItemText primary="Total" />
              </ListItemButton>
              <ListItemButton onClick={() => handleFilter('monthly')}>
                <ListItemText primary="Monthly" />
              </ListItemButton>
              <ListItemButton onClick={() => handleFilter('yearly')}>
                <ListItemText primary="Yearly" />
              </ListItemButton>
              <ListItemButton onClick={() => handleFilter('specific')}>
                <ListItemText primary="Specific Date" />
              </ListItemButton>
            </List>
          </Popover>
        </Box>
      </Box>

        <Tooltip title="Print Delivery Reports" placement="top">
          <Fab
            color="success"
            aria-label="filter"
            onClick={handlePrint}
            sx={{ position: "fixed", top: 400, right: 40, zIndex: "20" }}
          >
            <Print />
          </Fab>
        </Tooltip>
      </div>

      )}

    </div>
  );
};

export default UserCharts;
