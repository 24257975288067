import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataGrid } from "@mui/x-data-grid";
import MetaData from "../layout/MetaData";
import Loader from "../layout/loader";
import { useDispatch, useSelector } from "react-redux";
import { getUserDelHistory, clearErrors } from "../../actions/StatActions";
import { DELETE_SCHEDULE_RESET, SCHEDULE_DETAILS_RESET } from "../../constants/schedDeliveryConstants";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography, Box, Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { dGridStyle } from "../chart/dataGridStyles";
import { ArrowBack, HistoryEdu, LocalShipping } from "@mui/icons-material";

const DeliveryHistory = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  const { loading, error, delHistory } = useSelector(
    (state) => state.delHis
  );
  const { user } = useSelector((state) => state.auth);
  const errMsg = (message = "") =>
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  useEffect(() => {
    dispatch(getUserDelHistory());
    if (error) {
      errMsg(error);
      dispatch(clearErrors());
    }
  }, [dispatch, error, navigate]);

  const getRequestDescription = (code, keyNo) => {
    const codeMap = {
      getItem: "Get Item",
      getItemWKey: `Get Item & Key for Room ${keyNo}`,
      getKey: `Get Key for Room ${keyNo}`,
      returnItem: "Return Item"
    };

    // Use the code as a key to look up its description in the codeMap
    return codeMap[code] || "Unknown Request";
  };

  const minimalDate = (createdAt) => {
    const date = new Date(createdAt);
    const now = new Date();
    // If more than a day ago, return month day, year format
    const options = { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toLocaleDateString('en-US', options);
};


//   const getRepeatingValue = (schedDelivery) => {
//     // // console.log('get repeating value', schedDelivery.isCustomWeeklyEvent)
//     if (schedDelivery.isOneTimeEvent) {
//       return 'Once';
//     } else if (schedDelivery.isDailyEvent) {
//       return 'Daily';
//     } else if (schedDelivery.isCustomWeeklyEvent) {
      
//       const customWeeklyDays = schedDelivery.customWeeklyDays;
//       const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
//       let repeatingValue = '';
//       for (let i = 0; i < customWeeklyDays.length; i++) {
//         if (customWeeklyDays[i] === '1') {
//           repeatingValue +=  daysOfWeek[i] + ' | ';
//         }
//       }
//       // Remove the trailing '/' character
//       repeatingValue = repeatingValue.slice(0, -1);
//       return repeatingValue;
//     }
//     // Default value if none of the conditions match
//     return '';
//   };
  
const getStatusColor = (status) => {
  switch (status) {
    case 'Success':
      return 'green';
    case 'Cancelled':
      return 'orange';
    case 'Failed':
      return 'red';
    default:
      return 'black'; // default color if status is none of the above
  }
};
  const columns = [
    // {
    //   label: 'ID',
    //   field: 'id',
    //   minWidth: 150,
    //   align: 'center',
    //   headerAlign: 'center', // Aligning header text
    // },
    {
      label: 'Date Requested',
      field: 'createdAt',
      width: 200,
      align: 'center',
      headerAlign: 'center', // Aligning header text
      renderCell: (params) => (
        <span >
          {minimalDate(params.value)}
        </span>
      ),
    },
    {
      label: 'Requestee',
      field: 'requestee',
      width: 200,
      align: 'center',
      headerAlign: 'center', // Aligning header text
    },
    {
      label: 'Type',
      field: 'code',
      width: 200,
      align: 'center',
      headerAlign: 'center', // Aligning header text
    },
    {
      label: 'Status',
      field: 'status',
      width: 100,
      align: 'center',
      headerAlign: 'center', // Aligning header text
      renderCell: (params) => (
        <span style={{ color: getStatusColor(params.value) }}>
        <strong>{params.value}</strong>  
        </span>
      ),
    },
    {
      label: 'Caption',
      field: 'caption',
      minWidth: 250,
      align: 'center',
      headerAlign: 'center', // Aligning header text
    },

  ];

  const rows = delHistory.map((history) => ({
    id: history.deliveryId,
    createdAt:history.createdAt,
    requestee: `${(user?.role === 'admin' || user?.role === 'faculty') ? history?.cys ? `${history?.cys} class officers ` : `${history.stud.username} ${history.stud.coYeSe}  `  :`${history.requesteeId.title} ${history.requesteeId.username}`}`,
    code: getRequestDescription(history.code, history?.key?.keyNo),
    caption: history.caption,
    status: history.delOutcome,
    // role: user.role,
    // isValidatedUser: user.isValidatedUser,
  }));

  const sortedRows = rows.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  return (
    <>
      <div sx={{ flexGrow: 1 }}>
            <Fragment>
            <IconButton sx={{
        ml: 3, mb: -20,
        '&:hover': {
          // Add styles for hover state
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }} onClick={() => {
        goBack()
      }}><ArrowBack sx={{
        fontSize: 35, color: '#000',
        '&:hover': {
          // Add styles for hover state
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }}></ArrowBack></IconButton>
              <Typography variant="h4" sx={{ margin: 2 }}>
                <div class="thirteen">
                  <h1>My Delivery History</h1>
                </div>
              </Typography>
              {loading ? (
                <Loader />
              ) : (
                <Box sx={{ height: 500, width: "80%", margin: "auto" }}>
                  <DataGrid
                    rows={sortedRows}
                    columns={columns}
                    pageSize={10}
                    autoPageSize
                    rowsPerPageOptions={[10, 25, 50]}
                    //   checkboxSelection
                    disableSelectionOnClick
                    sx={dGridStyle}
                  />
                </Box>
              )}
            </Fragment>
           
        <Link to="/request-history">
        <Tooltip title="View Request History" sx={{backgroundColor: '#000'}}>
          <Fab
            color="success"
            aria-label="add"
            sx={{ position: "fixed", top: 500, right: 40, zIndex: "20" }}
          >
            <HistoryEdu />
          </Fab>
          </Tooltip>
        </Link>
   
      </div>
    </>
  );
};

export default DeliveryHistory;
