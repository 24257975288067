export const MY_SCHEDULES_REQUEST = 'MY_SCHEDULES_REQUEST'
export const MY_SCHEDULES_SUCCESS = 'MY_SCHEDULES_SUCCESS'
export const MY_SCHEDULES_FAIL = 'MY_SCHEDULES_FAIL'

export const SCHEDULE_DETAILS_REQUEST = 'SCHEDULE_DETAILS_REQUEST' 
export const SCHEDULE_DETAILS_SUCCESS = 'SCHEDULE_DETAILS_SUCCESS'
export const SCHEDULE_DETAILS_FAIL = 'SCHEDULE_DETAILS_FAIL'
export const SCHEDULE_DETAILS_RESET = 'SCHEDULE_DETAILS_RESET'

export const ADMIN_SCHEDULES_REQUEST = 'ADMIN_SCHEDULES_REQUEST'
export const ADMIN_SCHEDULES_SUCCESS = 'ADMIN_SCHEDULES_SUCCESS'
export const ADMIN_SCHEDULES_FAIL = 'ADMIN_SCHEDULES_FAIL'

export const NEW_SCHEDULE_REQUEST = 'NEW_SCHEDULE_REQUEST'
export const NEW_SCHEDULE_SUCCESS = 'NEW_SCHEDULE_SUCCESS'
export const NEW_SCHEDULE_RESET = 'NEW_SCHEDULE_RESET'
export const NEW_SCHEDULE_FAIL = 'NEW_SCHEDULE_FAIL'


export const UPDATE_SCHEDULE_REQUEST = 'UPDATE_SCHEDULE_REQUEST'
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS'
export const UPDATE_SCHEDULE_RESET = 'UPDATE_SCHEDULE_RESET'
export const UPDATE_SCHEDULE_FAIL = 'UPDATE_SCHEDULE_FAIL'

export const DELETE_SCHEDULE_REQUEST = 'DELETE_SCHEDULE_REQUEST'
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS'
export const DELETE_SCHEDULE_RESET = 'DELETE_SCHEDULE_RESET'
export const DELETE_SCHEDULE_FAIL = 'DELETE_SCHEDULE_FAIL'

export const UPDATE_STATUS_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_STATUS_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_STATUS_RESET = 'UPDATE_USER_RESET'
export const UPDATE_STATUS_FAIL = 'UPDATE_USER_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

