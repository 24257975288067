export const MY_CONVERSATIONS_REQUEST = 'MY_CONVERSATIONS_REQUEST'
export const MY_CONVERSATIONS_SUCCESS = 'MY_CONVERSATIONS_SUCCESS'
export const MY_CONVERSATIONS_FAIL = 'MY_CONVERSATIONS_FAIL'

export const USER_CONVERSATIONS_REQUEST = 'USER_CONVERSATIONS_REQUEST'
export const USER_CONVERSATIONS_SUCCESS = 'USER_CONVERSATIONS_SUCCESS'
export const USER_CONVERSATIONS_FAIL = 'USER_CONVERSATIONS_FAIL'

export const USER_MESSAGES_REQUEST = 'USER_MESSAGES_REQUEST'
export const USER_MESSAGES_SUCCESS = 'USER_MESSAGES_SUCCESS'
export const USER_MESSAGES_FAIL = 'USER_MESSAGES_FAIL'

export const MARK_SEEN_REQUEST = 'CHAT_DETAILS_REQUEST' 
export const MARK_SEEN_SUCCESS = 'CHAT_DETAILS_SUCCESS'
export const MARK_SEEN_FAIL = 'CHAT_DETAILS_FAIL'

export const NEW_CONVERSATION_REQUEST = 'NEW_CONVERSATION_REQUEST'
export const NEW_CONVERSATION_SUCCESS = 'NEW_CONVERSATION_SUCCESS'
export const NEW_CONVERSATION_RESET = 'NEW_CONVERSATION_RESET'
export const NEW_CONVERSATION_FAIL = 'NEW_CONVERSATION_FAIL'

export const NEW_CHAT_REQUEST = 'NEW_CHAT_REQUEST'
export const NEW_CHAT_SUCCESS = 'NEW_CHAT_SUCCESS'
export const NEW_CHAT_RESET = 'NEW_CHAT_RESET'
export const NEW_CHAT_FAIL = 'NEW_CHAT_FAIL'


export const UPDATE_CHAT_REQUEST = 'UPDATE_CHAT_REQUEST'
export const UPDATE_CHAT_SUCCESS = 'UPDATE_CHAT_SUCCESS'
export const UPDATE_CHAT_RESET = 'UPDATE_CHAT_RESET'
export const UPDATE_CHAT_FAIL = 'UPDATE_CHAT_FAIL'

export const DELETE_CHAT_REQUEST = 'DELETE_CHAT_REQUEST'
export const DELETE_CHAT_SUCCESS = 'DELETE_CHAT_SUCCESS'
export const DELETE_CHAT_RESET = 'DELETE_CHAT_RESET'
export const DELETE_CHAT_FAIL = 'DELETE_CHAT_FAIL'

export const DELETE_CONVERSATION_REQUEST = 'DELETE_CONVERSATION_REQUEST'
export const DELETE_CONVERSATION_SUCCESS = 'DELETE_CONVERSATION_SUCCESS'
export const DELETE_CONVERSATION_RESET = 'DELETE_CONVERSATION_RESET'
export const DELETE_CONVERSATION_FAIL = 'DELETE_CONVERSATION_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

