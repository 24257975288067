import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataGrid } from "@mui/x-data-grid";
import MetaData from "../layout/MetaData";
import Loader from "../layout/loader";
import { useDispatch, useSelector } from "react-redux";
import { myScheduledDeliveries, clearErrors, deleteScheduledDelivery } from "../../actions/schedDeliveryActions";
import { DELETE_SCHEDULE_RESET, SCHEDULE_DETAILS_RESET } from "../../constants/schedDeliveryConstants";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography, Box, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { dGridStyle } from "../chart/dataGridStyles";
import { ArrowBack } from "@mui/icons-material";

const ScheduledDeliveries = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  const { loading, error,  schedDeliveries } = useSelector(
    (state) => state.mySched
  );
  const { user } = useSelector((state) => state.auth);
  const { isDeleted } = useSelector((state) => state.sched);
  const errMsg = (message = "") =>
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const successMsg = (message = "") =>
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  useEffect(() => {
    dispatch({ type: SCHEDULE_DETAILS_RESET });
    dispatch(myScheduledDeliveries());
    if (error) {
      errMsg(error);
      dispatch(clearErrors());
    }
    if (isDeleted) {
      successMsg("Schedule deleted successfully");
      // navigate("/admin/bugreports");
      dispatch({ type: DELETE_SCHEDULE_RESET });
    }
  }, [dispatch, error, isDeleted, navigate]);

  const deleteScheduledDeliveryHandler = (id) => {
    dispatch(deleteScheduledDelivery(id));
  };


  const getRepeatingValue = (schedDelivery) => {
    // // console.log('get repeating value', schedDelivery.isCustomWeeklyEvent)
    if (schedDelivery.isOneTimeEvent) {
      return 'Once';
    } else if (schedDelivery.isDailyEvent) {
      return 'Daily';
    } else if (schedDelivery.isCustomWeeklyEvent) {
      
      const customWeeklyDays = schedDelivery.customWeeklyDays;
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      let repeatingValue = '';
      for (let i = 0; i < customWeeklyDays.length; i++) {
        if (customWeeklyDays[i] === '1') {
          repeatingValue +=  daysOfWeek[i] + ' | ';
        }
      }
      // Remove the trailing '/' character
      repeatingValue = repeatingValue.slice(0, -1);
      return repeatingValue;
    }
    // Default value if none of the conditions match
    return '';
  };
  

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "schedName",
      headerName: "Name",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "schedTime",
      headerName: "Time",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "repeating",
      headerName: "Repeating",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.6,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Link
              to={`/update-schedule/${params.row.id}`} 
            className="btn btn-info py-1 px-2">
            <EditIcon />
          </Link>
          <IconButton
            onClick={() => deleteScheduledDeliveryHandler(params.row.id)}
            className="btn btn-danger py-1 px-2 ml-2"
          >
            <DeleteIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = schedDeliveries.map((schedDelivery) => ({
    id: schedDelivery._id,
    schedName: schedDelivery.schedName,
    schedTime: schedDelivery.schedTime,
    roomKey: schedDelivery.roomKey,
    status: schedDelivery.status,
    repeating: getRepeatingValue(schedDelivery),
    // role: user.role,
    // isValidatedUser: user.isValidatedUser,
  }));

  return (
    <>
      <div sx={{ flexGrow: 1 }}>
            <Fragment>
            <IconButton sx={{
        ml: 3, mb: -20,
        '&:hover': {
          // Add styles for hover state
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }} onClick={() => {
        goBack()
      }}><ArrowBack sx={{
        fontSize: 35, color: '#000',
        '&:hover': {
          // Add styles for hover state
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }}></ArrowBack></IconButton>
              <Typography variant="h4" sx={{ margin: 2 }}>
                <div class="thirteen">
                  <h1>My Scheduled Deliveries</h1>
                </div>
              </Typography>
              {loading ? (
                <Loader />
              ) : (
                <Box sx={{ height: 500, width: "75%", margin: "auto" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    autoPageSize
                    rowsPerPageOptions={[10, 25, 50]}
                    //   checkboxSelection
                    disableSelectionOnClick
                    sx={dGridStyle}
                  />
                </Box>
              )}
            </Fragment>
        <Link to="/create-schedule">
          <Fab
            color="success"
            aria-label="add"
            sx={{ position: "fixed", top: 500, right: 40, zIndex: "20" }}
          >
            <AddIcon />
          </Fab>
        </Link>
      </div>
    </>
  );
};

export default ScheduledDeliveries;
