import React, { Fragment, useState, useEffect } from "react";
import "../../App.css";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "./loader";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logout, activeProfs, updateStatus } from "../../actions/userActions";
import FIrstLogin from "../user/FirstLogIn";
import PeopleIcon from "@mui/icons-material/People";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import MenuIcon from "@mui/icons-material/Menu";
import { StyledBadge } from "../styles/onlineUsers";
import {
  Switch,
  Badge,
  Tooltip,
  Button,
  Container,
  Typography,
  IconButton,
  Toolbar,
  Box,
  AppBar,
  Avatar,
  Alert,
  AlertTitle,
  Snackbar,
  Modal,
} from "@mui/material";
import MaterialMenu from "@mui/material/Menu";
import MaterialMenuItem from "@mui/material/MenuItem";
import HandshakeIcon from "@mui/icons-material/Handshake";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import HistoryEduRoundedIcon from "@mui/icons-material/HistoryEduRounded";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import io from "socket.io-client";
import { TabPanel } from "./tabPanel";
import CircularProgress from "@mui/material/CircularProgress";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import useWebSocket from "../../socket/webSocket";
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import { getDeliveries, myDeliveries as getMyDeliveries } from "../../actions/deliveryActions";
import DeliverySidebar from "../deliverySidebar";
import NotifsSection from "../notifSection";
import { myNotifications } from "../../actions/notifActions";
const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const Header = () => {
  const navigate = useNavigate();
  const [Tabvalue, setTabValue] = useState(0);

  const TabhandleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const dispatch = useDispatch();
  const initialCount =
    parseInt(localStorage.getItem("activeProfessorsCount")) || 0;
  const messagesCount = parseInt(localStorage.getItem("unseenMessages")) || 0;

  const [activeProfessorsCount, setActiveProfessorsCount] =
    useState(initialCount);
  const [unseenMessages, setUnreadMessagesCount] = useState(messagesCount);
  const [notifCount, setNotifCount] = useState(0);
  const [deliveriesCount, setDeliveriesCount] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect to handle window resize events
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // Close menus on window resize
      handleCloseNavMenu();
      handleCloseUserMenu();
      // Add other menu closing functions as needed
    };

    // Attach event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { user, loading } = useSelector((state) => state.auth);
  const { myNotifs } = useSelector(state => state.myNotifs);
  const { Deliveries, myDeliveries } = useSelector((state) => state.myDeliveries);
  const { profs } = useSelector((state) => state.prof);
  const { socket: socket1, isConnected: isConnected1 } = useWebSocket(`${process.env.REACT_APP_WEBSOCKET_API}`);
  // const { socket: socket2, isConnected: isConnected2 } = useWebSocket(`https://indirectly-superb-elk.ngrok-free.app/`);


  useEffect(() => {
    if(user)
    dispatch(getMyDeliveries())
  dispatch(myNotifications())
  dispatch(activeProfs());
  }, [user])

  useEffect(() => {
    if(myDeliveries?.length > 0)
   setDeliveriesCount(myDeliveries?.length)
  }, [myDeliveries])

  useEffect(() => {
    if(myNotifs)
   setNotifCount(myNotifs?.length)
  }, [myNotifs])

  useEffect(() => {
    if (isConnected1 && !loading && user) {
        const handleProfcount = (event) => {
            const data = JSON.parse(event);
            if (data && data.count !== undefined) {
                setActiveProfessorsCount(data.count);
                dispatch(activeProfs());
                localStorage.setItem("activeProfessorsCount", data.count.toString());
                // console.log("Active professors count:", data.count);
            }
        };

        const handleUnreadMessages = (event) => {
            try {
                const data = JSON.parse(event);
                const { userId = null, unreadMessagesCount = 0 } = data;

                if (userId === user._id) {
                    setUnreadMessagesCount(unreadMessagesCount);
                    localStorage.setItem("unseenMessages", unreadMessagesCount.toString());
                }
            } catch (error) {
                console.error("Error parsing or handling unreadMessages event:", error);
            }
        };

        const handleNotification = (notification) => {
            if ((notification?.userId === user?._id || notification?.userId === null) &&
                (notification?.cys === user?.coYeSe || notification?.cys === null)) {
                dispatch(myNotifications());
            }
        };

        const handleDelivery = ({ deliveryIo }) => {
          // Check if user._id matches any of the IDs or user.coYeSe matches cys
          // if(user?.role === 'admin' || user?.role === 'faculty')
            if (
              ((deliveryIo?.stud !== null && user && user._id === deliveryIo?.stud) || !deliveryIo?.stud) &&
              ((deliveryIo?.stor && user && user._id === deliveryIo?.stor) || !deliveryIo?.stor) &&
              ((deliveryIo?.stee && user && user._id === deliveryIo?.stee) || !deliveryIo?.stee) &&
              ((deliveryIo?.cys !== null && user && user.coYeSe === deliveryIo?.cys) || !deliveryIo?.cys)
          ) {
              dispatch(getMyDeliveries());
          }
      };

      socket1.on("delivery", handleDelivery);
        socket1.on("notif", handleNotification);
        socket1.on("profcount", handleProfcount);
        socket1.on("unreadMessages", handleUnreadMessages);

        // Cleanup function
        return () => {
          socket1.off("delivery", handleDelivery);
            socket1.off("notif", handleNotification);
            socket1.off("profcount", handleProfcount);
            socket1.off("unreadMessages", handleUnreadMessages);
        };
    }
}, [isConnected1, socket1, loading, user, setActiveProfessorsCount, setUnreadMessagesCount, dispatch]);

  // Snackbar ============
  const [openSB, setSBOpen] = React.useState(false);
  const SBhandleClick = () => {
    setSBOpen(true);
    // dispatch(activeProfs());
  };

  const openSideBar = () => {
    // dispatch(activeProfs());
    setToggled(!toggled);
  };

  const SBhandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSBOpen(false);
  };

  const handleScheduledDelivery = () => {
    // Programmatically navigate to the "/messages" route
    setToggled(false);
    setDeliverySideBar(false)
    navigate("/my-scheduled-deliveries",);
  };

  const handleMessagesClick = (prof) => {
    setToggled(false);
    setDeliverySideBar(false)
    // Programmatically navigate to the "/messages" route
    navigate("/messages", { state: { profData: prof } });
  };

  const handleRequestClick = (prof) => {
    setToggled(false);
    setDeliverySideBar(false)
    // Programmatically navigate to the "/messages" route
    navigate("/request-item", { state: { profData: prof } });
  };
  // ==========================

  const pages = ["Notifications", "Messages", "Requests"];
  let settings = ["Profile", "Report A Bug", "Logout"];

  if (user?.role === "admin" || user?.role === "faculty") {
    settings.push("Dashboard");
  }

  const logoutHandler = () => {
    setUnreadMessagesCount(0);
    dispatch(logout());
  };

  const [toggled, setToggled] = React.useState(false);
  const [DeliverySideBar, setDeliverySideBar] = React.useState(false);
  const [broken, setBroken] = React.useState(
    window.matchMedia("(max-width: 800px)").matches
  );
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // switch
  const [checked, setChecked] = useState(user?.status === "active");
  const [switchloading, setSwitchLoading] = useState(false);

  useEffect(() => {
    setChecked(user?.status === "active")
  }, [user])
  const switchHandleChange = (event) => {
    if (!switchloading) {
      setSwitchLoading(true);
      setTimeout(() => {
        setChecked((prev) => !prev);
        if (!checked && user?._id) {
          dispatch(updateStatus(user._id, "active"));
        } else if (checked && user?._id) {
          dispatch(updateStatus(user._id, "online"));
        }
        setSwitchLoading(false);
      }, 1200); // Simulating a loading process with a timeout
    }
    // setChecked(event.target.checked);
    // // // console.log('toggle status: ', checked)
  };

  // ============= Notification Modal 
  const [modalNotifOpen, setModalNotifOpen] = useState(false);

  const handleModalNotifOpen = () => {
    setModalNotifOpen(true);
  };

  const handleModalNotifClose = () => {
    setModalNotifOpen(false);
  };

  return (
    <Box>
      {/* ======= snack bar ====== */}
      <Snackbar
        open={openSB}
        autoHideDuration={6000}
        onClose={SBhandleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={SBhandleClose} severity="info" sx={{ width: "100%" }}>
          <AlertTitle>No Active Professors</AlertTitle>
          There are no active professors inside the faculty room at the moment,
          please try again later.
        </Alert>
      </Snackbar>
      <AppBar position="sticky" style={{ backgroundColor: "#101a11" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Avatar src={'/logo512.png'}
              sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
            />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Request & Delivery
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <MaterialMenu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {user ? (
                  // If user is logged in, render menu items
                  <>
                    {/* // If user is logged in, render menu items */}
                    <MaterialMenuItem
                      key="profile"
                      onClick={handleCloseNavMenu}
                    >
                      <Typography variant="body1" textAlign="center">
                        Notifications
                      </Typography>
                    </MaterialMenuItem>
                    <MaterialMenuItem
                      key="Active Profs"
                      onClick={() => {
                        handleCloseNavMenu();
                        setToggled(!toggled);
                      }}
                    >
                      <Typography variant="body1" textAlign="center">
                        Active Profs ({activeProfessorsCount})
                      </Typography>
                    </MaterialMenuItem>
                  </>
                ) : (
                  !loading && (
                    <MaterialMenuItem onClick={handleCloseNavMenu}>
                      <Box
                        sx={{
                          flexGrow: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          component={Link}
                          to="/login"
                          color="inherit"
                          id="login_btn"
                        >
                          Login
                        </Button>
                      </Box>
                    </MaterialMenuItem>
                  )
                )}
              </MaterialMenu>
            </Box>
            <Avatar src={'/logo512.png'}
              sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
            />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              RAD4
            </Typography>
            {user ? (
              <>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: {
                      xs: "none",
                      md: "flex",
                      ml: "auto",
                      justifyContent: "flex-end",
                    },
                  }}
                >
                  {/* ======== end of snack bar ======= */}
                  <Tooltip title="My Deliveries">
                    <IconButton
                      // href="/messages"
                      aria-label="mydeliveries"
                      sx={{ my: 0, mx: 1, color: "white", display: "block" }}
                      onClick={() => {
                        setDeliverySideBar(!DeliverySideBar);
                      }}
                    >
                      <Badge badgeContent={deliveriesCount} color="info">
                        {" "}
                        <LocalShippingRoundedIcon />{" "}
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={`Active Professors: ${activeProfessorsCount}`}
                  >
                    <IconButton
                      aria-label="activeprofessors"
                      sx={{ my: 0, mx: 1, color: "white", display: "block" }}
                      onClick={() => {
                        setToggled(!toggled);
                      }}
                    >
                      <Badge
                        badgeContent={activeProfessorsCount}
                        color="success"
                      >
                        {" "}
                        <PeopleIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Messages">
                    <IconButton
                      href="/messages"
                      aria-label="delete"
                      sx={{ my: 0, mx: 1, color: "white", display: "block" }}
                    >
                      <Badge badgeContent={unseenMessages} color="success">
                        {" "}
                        <MessageRoundedIcon />{" "}
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Notifications">
        <IconButton
          aria-label="delete"
          sx={{ my: 0, mx: 1, color: "white", display: "block" }}
          onClick={handleModalNotifOpen} // Open the modal when the icon button is clicked
        ><Badge badgeContent={notifCount} color="error">
        {" "}
          <NotificationsRoundedIcon /> </Badge>
        </IconButton>
      </Tooltip>
                </Box>
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title={user && `${user.username}`}>
                    <IconButton
                      onClick={handleOpenUserMenu}
                      sx={{ p: 0, ml: 1 }}
                    >
                      <Avatar src={user.avatar && user.avatar.url} />
                    </IconButton>
                  </Tooltip>
                  <MaterialMenu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <MaterialMenuItem
                        key={setting}
                        onClick={()=>{
                          setting === 'Logout' && logoutHandler ();
                          handleCloseUserMenu();}}
                        component={Link}
                        to={setting === 'Logout' ? '/' : setting === 'Profile' ? '/me' : setting === 'Report A Bug' ? '/submit-bugreport': setting === 'Dashboard' ? '/admin/reports' : '/' }
                      >
                        <Typography textAlign="center"> {setting} </Typography>
                        
                        {/* {// console.log(
                          `Setting: ${setting}, User Role: ${user.role}`
                        )} */}
                        {/* {setting === "Logout" ? (
                          <Link
                            to="/"
                            onClick={logoutHandler}
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <Typography textAlign="center">
                              {setting}
                            </Typography>
                          </Link>
                        ) : setting === "Profile" ? (
                          <Link
                            to="/me"
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <Typography textAlign="center">{setting}</Typography>
                          </Link>
                        ) 
                        : setting === "Report A Bug" ? (
                          <Link
                            to="/submit-bugreport"
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <Typography textAlign="center">Report A Bug</Typography>
                          </Link>
                        ) : setting === "Dashboard" && (user?.role === "admin" || user?.role === "faculty") ? (
                          <Link
                            to="/admin/reports"
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <Typography textAlign="center">
                              Dashboard
                            </Typography>
                          </Link>
                        ) : (
                          <Link
                            to={`/${setting.toLowerCase()}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <Typography textAlign="center">
                              {setting}
                            </Typography>
                          </Link>
                        )} */}
                      </MaterialMenuItem>
                    ))}
                  </MaterialMenu>
                </Box>
              </>
            ) : (
              !loading && (
                <Box
                  sx={{
                    flexGrow: 1,
                    display: {
                      xs: "none",
                      md: "flex",
                      ml: "auto",
                      justifyContent: "flex-end",
                    },
                  }}
                >
                  {" "}
                  <Button
                    component={Link}
                    to="/login"
                    color="inherit"
                    id="login_btn"
                  >
                    Login
                  </Button>
                </Box>
              )
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <Modal
        open={modalNotifOpen}
        onClose={handleModalNotifClose}
        BackdropProps={{
          sx: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } // Customize backdrop style here
        }}
        // sx={{width: '50vh'}}
      >
        <Box sx={{ display: 'flex', marginX: 'auto', marginTop: '72px',height: '87vh', width: '40%' }}>
          <NotifsSection /> {/* Render the NotifsSection component inside the modal */}
        </Box>
      </Modal>

      {user && user.isNewUser === true && <FIrstLogin />}
      <DeliverySidebar setDeliverySideBar={setDeliverySideBar} DeliverySideBar={DeliverySideBar} myDeliveries={myDeliveries} user={user}/>
      <Sidebar
        onBackdropClick={() => setToggled(false)}
        width="300px"
        toggled={toggled}
        breakPoint="all"
        backgroundColor="#101a11"
        style={{
          border: "1px solid #101a11",
          height: "100vh",
          overflowY: "auto",
          color: "#fff",
        }}
      >
        <br /> <br />
        <br />
        <br />
        {(user?.role === "faculty" || user?.role === "admin") && (
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    color: disabled ? "#fff" : "#fff",
                    // backgroundColor: active ? '#eecef9' : undefined,
                    ":hover": {
                      backgroundColor: "#000",
                    },
                  };
              },
            }}
          >
            <SubMenu
              key={user._id}
              style={{ textAlign: "center" }}
              // icon={
              //     <Avatar alt={user.username} src={user.avatar.url} sx={{mr: '20px'}} />
              // }
              label={
                user.username
                  ? `${user.username} (YOU)`
                  : `${user.title} ${user.firstName} ${user.lastName} (YOU)`
              }
            >
              <MenuItem
                className="menuItem"
              // onClick={() => handleMessagesClick(prof)}
              // icon={<MessageRoundedIcon />}
              >
                Set Status as Active
                <Switch
                  checked={checked}
                  color="default"
                  onChange={switchHandleChange}
                  inputProps={{ "aria-label": "loading switch" }}
                  icon={
                    <div
                      style={{ position: "relative", width: 24, height: 24 }}
                    >
                      <CircleIcon
                        sx={{
                          fontSize: "20px",
                          color: "white",
                          position: "relative",
                          zIndex: 1,
                        }}
                      />
                      {switchloading && (
                        <CircularProgress
                          size={20}
                          thickness={5}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            color: "#005b96",
                            zIndex: 0,
                          }}
                        />
                      )}
                    </div>
                  }
                  checkedIcon={
                    <div
                      style={{ position: "relative", width: 24, height: 24 }}
                    >
                      <CheckCircleRoundedIcon
                        sx={{
                          borderRadius: "90%",
                          fontSize: "20px",
                          color: "#1a7c50",
                          position: "relative",
                          zIndex: 1,
                          backgroundColor: "white",
                        }}
                      />
                      {switchloading && (
                        <CircularProgress
                          size={20}
                          thickness={5}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            color: "#00321f",
                            zIndex: 1,
                          }}
                        />
                      )}
                    </div>
                  }
                  sx={{
                    ml: "30px",
                    "& .MuiSwitch-thumb": {
                      position: "relative",
                      zIndex: 1,
                    },
                  }}
                />
              </MenuItem>
              {checked && (
                <MenuItem className="menuItem"  onClick={() => handleScheduledDelivery()} icon={<HistoryEduRoundedIcon />}>
                  My Scheduled Deliveries
                </MenuItem>
              )}
            </SubMenu>
          </Menu>
        )}
        <Tabs
          value={Tabvalue}
          onChange={TabhandleChange}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#2e7d32",
            },
          }}
          centered
          variant="fullWidth"
          textColor="#FFF"
          // indicatorColor="#2e7d32"
          aria-label="secondary tabs example"
        >
          <Tab
            label="Active Profs"
            {...a11yProps(0)}
            sx={{ "&.Mui-selected": { color: "#2e7d32" } }}
          />
          <Tab
            label="All Profs"
            {...a11yProps(1)}
            sx={{ "&.Mui-selected": { color: "#2e7d32" } }}
          />
        </Tabs>
        <TabPanel value={Tabvalue} index={0}>
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    color: disabled ? "#fff" : "#fff",
                    // backgroundColor: active ? '#eecef9' : undefined,
                    ":hover": {
                      backgroundColor: "#000",
                    },
                  };
              },
            }}
          >
            {/* <h2 style={{ textAlign: 'center', color: 'white' }}>Active Professors</h2> */}
            {profs &&
              profs.length > 0 &&
              profs.some((prof) => prof.status === "active") ? (
              profs
                .filter((prof) => prof.status === "active") // Filter active professors
                .map((prof) => (
                  <SubMenu
                    key={prof._id}
                    icon={
                      <StyledBadge
                        overlap="circular"
                        status={prof.status}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                        sx={{ mr: 2 }}
                      >
                        <Avatar alt={prof.username} src={prof.avatar.url} />
                      </StyledBadge>
                    }
                    label={
                      prof.username
                        ? `${prof.title} ${prof.username}`
                        : `${prof.title} ${prof.firstName} ${prof.lastName}`
                    }
                  >
                    {user && user._id !== prof._id && (
                      <MenuItem
                        className="menuItem"
                        onClick={() => handleMessagesClick(prof)}
                        icon={<MessageRoundedIcon />}
                      >
                        Message
                      </MenuItem>
                    )}
                    <MenuItem
                      className="menuItem"
                      onClick={() => handleRequestClick(prof)}
                      icon={<HistoryEduRoundedIcon />}
                    >
                      Request Item
                    </MenuItem>
                  </SubMenu>
                ))
            ) : (
              <Typography>No active profs at the moment.</Typography>
            )}
          </Menu>
        </TabPanel>
        <TabPanel value={Tabvalue} index={1}>
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    color: disabled ? "#fff" : "#fff",
                    // backgroundColor: active ? '#eecef9' : undefined,
                    ":hover": {
                      backgroundColor: "#000",
                    },
                  };
              },
            }}
          >
            {/* <h2 style={{ textAlign: 'center', color: 'white' }}>Active Professors</h2> */}
            {profs && profs.length > 0 ? (
              profs.map((prof) => (
                <SubMenu
                  key={prof._id}
                  icon={
                    <StyledBadge
                      status={prof.status}
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      variant="dot"
                      sx={{ mr: 2 }}
                    >
                      <Avatar alt={prof.username} src={prof.avatar.url} />
                    </StyledBadge>
                  }
                  label={
                    prof.username
                      ? `${prof.title} ${prof.username}`
                      : `${prof.title} ${prof.firstName} ${prof.lastName}`
                  }
                >
                  {prof.status != "do not disturb" && (
                    <>
                      {user && user._id !== prof._id && (
                        <MenuItem
                          className="menuItem"
                          onClick={() => handleMessagesClick(prof)}
                          icon={<MessageRoundedIcon />}
                        >
                          Message
                        </MenuItem>
                      )}
                      {prof.status === "active" && (
                        <MenuItem
                          className="menuItem"
                          icon={<HistoryEduRoundedIcon />}
                        >
                          Request Item
                        </MenuItem>
                      )}
                    </>
                  )}
                </SubMenu>
              ))
            ) : (
              <Typography>
                There are no professors yet at the moment.
              </Typography>
            )}
          </Menu>
        </TabPanel>
      </Sidebar>
    </Box>
  );
};

export default Header;