import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { fetchBugReports } from "../../actions/chartActions";
import { ArrowBack, CalendarMonthRounded } from "@mui/icons-material";
import { Fab, IconButton, Paper, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

const BugReports = ({dashboard}) => {
  const dispatch = useDispatch();
  const bugReportData = useSelector((state) => state.bugreportchart);
  const [containerHeight, setContainerHeight] = useState(450); // Set an initial height

  useEffect(() => {
    dispatch(fetchBugReports());
  }, [dispatch]);

  useEffect(() => {
    // Update chart height when the window is resized
    const handleResize = () => {
      const newHeight =
        document.getElementById("bug-reports-container")?.clientHeight || 450;
      setContainerHeight(newHeight);
    };

    // Initial height calculation
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { loading, error, bugReports } = bugReportData;

  // Check if bugReports has the expected structure before rendering the chart
  if (!bugReports.labels || !bugReports.datasets) {
    return <p>Data structure is not as expected</p>;
  }

  const chartOptions = {
    scales: {
      x: [
        {
          type: "category",
          labels: bugReports.labels,
        },
      ],
      y: {
        beginAtZero: true,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <div id="bug-reports-container" style={{ height: `${containerHeight}px` }}>
      {loading ? (
        <p>Loading bug report data...</p>
      ) : error ? (
        <p>Error fetching bug report data: {error}</p>
      ) : (<div>
        <IconButton sx={{
          ml: 3, mb: -10,
          '&:hover': {
            // Add styles for hover state
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            color: '#49B265',
            // Add any other styles you want to apply on hover
          },
        }} 
        onClick={dashboard}
        ><ArrowBack sx={{
          fontSize: 35, color: '#000',
          '&:hover': {
            // Add styles for hover state
            color: '#49B265',
            // Add any other styles you want to apply on hover
          },
        }}></ArrowBack></IconButton>
         <div className='thirteen'>
          <h1>Bug Reports</h1>
        </div>
        <Paper elevation={10} sx={{m:10, height: '350px'}}>
        <Bar
          data={{
            labels: bugReports.labels,
            datasets: bugReports.datasets,
          }}
          options={chartOptions}
        /></Paper>
   
          <Tooltip title="Filter Date" placement="top">
          <Fab
            color="success"
            aria-label="add"
            sx={{ position: "fixed", top: 500, right: 40, zIndex: "20" }}
          >
            <CalendarMonthRounded />
          </Fab>
          </Tooltip>
     
        </div>
      )}

    </div>
  );
};

export default BugReports;
