  import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import MetaData from "../layout/MetaData";
import Loader from "../layout/loader";
import { useDispatch, useSelector } from "react-redux";
import {
  allBugReports,
  clearErrors,
  deleteBugReport,
} from "../../actions/bugReportActions";
// import { DELETE_COURSE_RESET, CLEAR_ERRORS } from '../../constants/courseConstants';
import {
  DELETE_BUGREPORT_RESET,
  CLEAR_ERRORS,
} from "../../constants/bugReportConstants";

import DashSidebar from "./sidebar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography, Box, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import { dGridStyle } from "../chart/dataGridStyles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

// const CheckboxRenderer = ({ value, currentUser, userId }) => {
//   const dispatch = useDispatch()
//     const [isChecked, setIsChecked] = useState(value);

//     const handleCheckboxChange = () => {
//       if (currentUser && currentUser.role === 'admin') {
//         setIsChecked(!isChecked);
//         dispatch(verifyUser(userId, !isChecked))
//         // // console.log(`Clicked on user ID: ${userId} ${!isChecked}`);
//         // You can also perform any additional logic here
//       }
//     };

//     return (
//       <Checkbox
//         checked={isChecked}
//         color= 'success'
//         disabled={currentUser && currentUser.role !== 'admin'}
//         onChange={handleCheckboxChange}
//       />
//     );
//   };

const BugReportList = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { loading, error, bugReports } = useSelector(
    (state) => state.bugReports
  );
  const { user } = useSelector((state) => state.auth);
  const { isDeleted } = useSelector((state) => state.bugReport);
  const errMsg = (message = "") =>
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const successMsg = (message = "") =>
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  useEffect(() => {
    dispatch(allBugReports());
    if (error) {
      errMsg(error);
      dispatch(clearErrors());
    }
    if (isDeleted) {
      Swal.fire({
        title: "Deleted!",
        text: "Bug Report Successfully Deleted.",
        icon: "success"
      });
      navigate("/admin/bugreports");
      dispatch({ type: DELETE_BUGREPORT_RESET });
    }
  }, [dispatch, error, isDeleted, navigate]);

  const deleteBugReportHandler = (id) => {
    Swal.fire({
      title: "Delete this Bug Report?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteBugReport(id));
      }
    });
    // 
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Created",
      flex: 0.5,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "username",
      headerName: "User",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "title",
      headerName: "Title",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "comment",
      headerName: "Desc",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.6,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {/* <Link
              to={`/admin/bug-report/${params.row.id}`} 
            className="btn btn-info py-1 px-2">
            <EditIcon />
          </Link> */}
          <IconButton
            onClick={() => deleteBugReportHandler(params.row.id)}
            className="btn btn-danger py-1 px-2 ml-2"
          >
            <DeleteIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = bugReports.map((bugReport) => ({
    id: bugReport._id,
    createdAt: bugReport.createdAt,
    username: bugReport.username,
    title: bugReport.title,
    type: bugReport.type,
    comment: bugReport.comment,
    // role: user.role,
    // isValidatedUser: user.isValidatedUser,
  }));

  return (
    <><MetaData title={'Bug Reports List'}/>
      <div sx={{ flexGrow: 1 }}>
        <Grid container direction="row">
          <Grid item xs={2} md={2}>
            <DashSidebar />
          </Grid>
          <Grid item xs={10} md={10}>
            <Fragment>
              <Typography variant="h4" sx={{ margin: 2 }}>
                <div class="thirteen">
                  <h1>Bug Reports</h1>
                </div>
              </Typography>
              {loading ? (
                <Loader />
              ) : (
                <Box sx={{ height: 500, width: "90%", margin: "auto" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    autoPageSize
                    rowsPerPageOptions={[10, 25, 50]}
                    //   checkboxSelection
                    disableSelectionOnClick
                    sx={dGridStyle}
                    slots={{
                      toolbar: CustomToolbar,
                    }}
                  />
                </Box>
              )}
            </Fragment>
          </Grid>
        </Grid>
        <Link to="/submit-bugreport">
          <Fab
            color="success"
            aria-label="add"
            sx={{ position: "fixed", top: 500, right: 40, zIndex: "20" }}
          >
            <AddIcon />
          </Fab>
        </Link>
      </div>
    </>
  );
};

export default BugReportList;
