export const minimalDate = (sentAt) => {
    const date = new Date(sentAt);
    const now = new Date();

    const diffInDays = Math.floor((now - date) / (24 * 60 * 60 * 1000));

    if (diffInDays > 0) {
        // If more than a day ago, return month day, year format
        const options = { month: '2-digit', day: '2-digit', year: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    } else {
        // If within the same day, return AM/PM format
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleTimeString('en-US', options);
    }
};

  // truncate text
export const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) return text;
    return `${text?.substring(0, maxLength)}...`;
};