import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import MetaData from '../layout/MetaData'
import DashSidebar from './sidebar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { updateCourse, getCourseDetails, clearErrors } from '../../actions/courseActions'
import { UPDATE_COURSE_RESET } from '../../constants/courseConstants'
import { FormGroup, FormControlLabel, Checkbox, Grid, Paper, Typography, IconButton, Button, TextField, Avatar, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from "react-hook-form";
import { allCourses } from '../../actions/courseActions';
import CircularProgress from '@mui/material/CircularProgress';



const UpdateCourse = () => {
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
//   const onSubmit = data => // console.log(data);
//   const [program, setProgram] = useState('')
//   const [year, setYear] = useState('')
//   const [section, setSection] = useState('')

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  const { error, isUpdated, loading } = useSelector(state => state.course);
  const { course } = useSelector(state => state.courseDetails)
  const { courses } = useSelector(state => state.allCourses)
  const { id } = useParams();
  const errMsg = (message = '') => toast.error(message, {
    position: toast.POSITION.BOTTOM_CENTER
  });
  const successMsg = (message = '') => toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER
  });

  useEffect(() => {
    // // console.log(user && user._id !== userId);
    if (course && course._id !== id) {
      dispatch(getCourseDetails(id))
    } else {
    setValue('program', course.course);
    setValue('year', course.year);
    setValue('section', course.section);
    }
    if (error) {
      errMsg(error);
      dispatch(clearErrors());
    }
    if (isUpdated === true) {
      successMsg('Course updated successfully')
      navigate('/admin/courses')
      dispatch({
        type: UPDATE_COURSE_RESET
      })
    }
  }, [dispatch, error, navigate, isUpdated, id, course])


  const submitHandler = (data) => {
    dispatch(updateCourse(course._id, data))
    // // console.log('submitted data: ', data)
  }

  return (
    <Fragment>
      <IconButton sx={{
        ml: 3, mb: -20,
        '&:hover': {
          // Add styles for hover state
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }} onClick={() => {
        goBack()
      }}><ArrowBackIcon sx={{
        fontSize: 35, color: '#000',
        '&:hover': {
          // Add styles for hover state
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }}></ArrowBackIcon></IconButton>

      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item xs={10} lg={5} my={5}>
          <Paper elevation={12} sx={{ padding: 3 }}>
            <Typography variant="h3" sx={{ marginBottom: '50px' }}>
              <div class="thirteen">
                <h1>COURSE</h1>
              </div>
            </Typography>

            <form className="shadow-lg" onSubmit={handleSubmit(submitHandler)}>
         
              <Box mb={3}>
                <TextField
                  id="program_name_field"
                  label="Program"
                  variant="outlined"
                  fullWidth
                //   value={program}
                //   onChange={(e) => setProgram(e.target.value)}
                {...register('program', {
                    required: 'Program is required',
                    minLength: {
                      value: 3,
                      message: 'Program must be at least 3 characters',
                    },
                    maxLength: {
                      value: 100,
                      message: 'Program must not exceed 100 characters',
                    },
                  })}
                // Add validation props if needed
                />
                 {errors.program && (
          <Typography variant="caption" color="error">
            {errors.program.message}
          </Typography>
        )}
              </Box>

              <Box mb={3}>
                <TextField
                  id="years_field"
                  label="Years required to finish"
                  variant="outlined"
                  fullWidth
                  type="number"
                //   value={year}
                //   onChange={(e) => setYear(e.target.value)}
                  {...register('year', {
                    required: 'Year is required',
                    validate: {
                      positive: (value) => value > 0 || 'Years to finish must be greater than 0',
                      lessThan10: (value) => value <= 10 || 'Years must be less than or equal to 10',
                    },
                  })}
                // Add validation props if needed
                />
                  {errors.year && (
          <Typography variant="caption" color="error">
            {errors.year.message}
          </Typography>
        )}
              </Box>

              <Box mb={3}>
                <TextField
                  id="section_field"
                
                  label="Sections"
                  variant="outlined"
                  fullWidth
                  type="number"
                //   value={section}
                  {...register('section', {
                    required: 'Section is required',
                    validate: {
                      positive: (value) => value > 0 || 'Section must be greater than 0',
                    //   lessThan10: (value) => value <= 10 || 'Section must be less than or equal to 10',
                    },
                  })}
                //   onChange={(e) => setSection(e.target.value)}
                />
                  {errors.section && (
          <Typography variant="caption" color="error">
            {errors.section.message}
          </Typography>
        )}
              </Box>

              <Button
               type={loading ? 'button' : 'submit'}
                variant="contained"
                fullWidth
                sx={{ py: 3, mt: 2, backgroundColor: '#3f51b5', color: 'white' }}
              >
                {loading ? <CircularProgress sx={{color: '#fff'}} size={20} /> : `UPDATE` } 
                {/* Update */}
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UpdateCourse

