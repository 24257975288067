import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form";
import { Paper, Box, Button, Typography, IconButton, Input, Grid, TextField, Badge, Tooltip, CircularProgress } from '@mui/material'
import MaterialAvatar from '@mui/material/Avatar'
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import ForumIcon from '@mui/icons-material/Forum';
import io from 'socket.io-client';
import VirtualizedMessageList from './virtualizedMessages';
import { boxStyles1, boxStyles2, boxStyles2a, boxStyles2b, boxStyles2c, boxStyles3, boxStyles3a, boxStyles5, searchStyle, sendButtonStyle, typoStyle1 } from './chatStyles';
import ConvosPanel from './convosPanel';
import { StyledBadge } from '../styles/onlineUsers';
import { createConversation, getMessages, markSeen, sendMessage } from '../../actions/chatActions';
import Send from '@mui/icons-material/Send';
import MessagesPanel from './messagesPanel';
import { NEW_CHAT_RESET } from '../../constants/chatConstants';
import useWebSocket from '../../socket/webSocket';

const Chatting = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const profData = location.state ? location.state.profData : null;
    const { register, handleSubmit } = useForm();
    const [userData, setUserData] = useState(null);
    const { user } = useSelector(state => state.auth)
    const { sending, sentMessage, error: messageError } = useSelector(state => state.chat)
    const { umessages, loading: messageload } = useSelector(state => state.umessages)
    const { newconvoid, loading: newConvoLoad } = useSelector(state => state.convocreate)
    const [loadConvo, setLoadConvo] = useState(false);
    const [message, setMessage] = useState('');
    const { socket, isConnected } = useWebSocket(`${process.env.REACT_APP_WEBSOCKET_API}`);
    const handleConversationClick = (userDetails) => {
        setUserData(userDetails); // Update the local state
        dispatch(getMessages(userDetails._id))
    };
    useEffect(() => {
        if (isConnected) {
            const handleUnreadMessages = (event) => {
                const data = JSON.parse(event);
                if ((data !== undefined) && (data.userId === user._id)) {
                    setLoadConvo(true);
                }
            };

            const handleMessageSeened = (event) => {
                const data = JSON.parse(event);
                if ((data !== undefined) && (data.otherParticipantId === user._id) && (userData.participants)) {
                    dispatch(getMessages(userData._id));
                }
            };

            socket.on("unreadMessages", handleUnreadMessages);
            socket.on("messageSeened", handleMessageSeened);

            // Clean up the event listeners when component unmounts or when isConnected changes
            return () => {
                socket.off("unreadMessages", handleUnreadMessages);
                socket.off("messageSeened", handleMessageSeened);
            };
        }
    }, [isConnected, socket, user, userData, dispatch]);

    useEffect(() => {
        if (profData) {
            setUserData(profData);
        }
    }, [profData])

    const messageHandleChange = (event) => {
        setMessage(event.target.value);
    };
    useEffect(() => {
        if (!newConvoLoad && newconvoid) {
            // console.log('this is the newconvoid: ', newconvoid)
            dispatch(sendMessage(newconvoid, message))
                .then(() => {
                    setMessage('')
                })
        }
    }, [newconvoid])

    useEffect(() => {
        if (sentMessage) {
            setMessage('')
            dispatch(getMessages(userData._id))
            dispatch({ type: NEW_CHAT_RESET });
        }
    }, [sentMessage])

    const submitHandler = (data, event) => {
        // // console.log("Message", message);
        if (message) {
            if (userData.participants) {
                dispatch(sendMessage(userData._id, message));
            }
            else {
                dispatch(createConversation(userData._id))
            }

        }
    }
    return (
        <Fragment>
            <Box sx={boxStyles1}>
                <Paper elevation={8}>
                    <Grid container columns={12} sx={{ height: '100%' }}>
                        <Grid Item xs={5}>
                            <Box sx={boxStyles2}>
                                <Box sx={boxStyles2a}>
                                    <Typography variant={'h4'}
                                        sx={typoStyle1}
                                        fontWeight={"bold"}> MESSAGES </Typography>
                                </Box>
                                <Box sx={boxStyles2b}
                                    component="form"
                                    noValidate
                                    autoComplete="off"
                                >
                                    <SearchIcon sx={{ marginX: '15px', color: '#fff' }} />
                                    <Input
                                        placeholder="Search conversation"
                                        fullWidth
                                        disableUnderline
                                        sx={searchStyle}
                                    />
                                </Box>
                                <Box sx={boxStyles2c}>
                                    <ConvosPanel
                                        handleConversationClick={handleConversationClick}
                                        user={user}
                                        sentMessage={sentMessage}
                                        loadConvo={loadConvo}
                                        setLoadConvo={setLoadConvo}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid Item xs={7}>
                            <Box sx={boxStyles3}>
                                <Box sx={boxStyles3a}>
                                    <StyledBadge
                                        status={userData?.participants?.status ? userData?.participants?.status : userData?.status}
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        variant="dot"
                                        sx={{ mr: 2, margin: '20px' }}
                                    >
                                        <MaterialAvatar
                                            sx={{ width: '40px', height: '40px' }}
                                            src={(userData?.participants?.avatar && userData?.participants?.avatar.url) ? userData?.participants?.avatar && userData?.participants?.avatar?.url : userData?.avatar && userData?.avatar?.url}
                                            elevation={2}
                                        />
                                    </StyledBadge>
                                    <Box>
                                        <Typography>{userData?.participants?.username ? userData?.participants?.username : userData?.username}</Typography>
                                        <Typography variant="body2" sx={{ color: '#303030' }}>
                                            {userData?.participants?.status ? userData?.participants?.status : userData?.status}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Grid container direction="column" style={{ height: '100%' }}>
                                    <Grid item xs={11}>
                                        <Box sx={{ width: '100%', height: '100%', }}>
                                            <MessagesPanel umessages={umessages} user={user} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <form onSubmit={handleSubmit(submitHandler)}>
                                            <Grid container direction="row" style={{ height: '100%' }}>
                                                <Grid item xs={11}>
                                                    <Box sx={{ backgroundColor: '#fff', width: '100%', height: '100%' }}>
                                                        <TextField
                                                            placeholder="Write your message..."
                                                            fullWidth
                                                            size="small"
                                                            multiline
                                                            focused={false}
                                                            maxRows={1}
                                                            variant="outlined"
                                                            InputProps={{
                                                                sx: {
                                                                    height: '6.2vh',// Center the icon vertically
                                                                    // paddingRight: '50px',
                                                                    color: 'rgba(0, 0, 1)',
                                                                    '&:hover .MuiOutlinedInput-root': {
                                                                        border: 'none',
                                                                        outline: 'none',
                                                                    },

                                                                },
                                                            }}

                                                            value={message}
                                                            onChange={messageHandleChange}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    {/* <Box sx={boxStyles5}
                                                    type="submit"
                                                >
                                                    <Send />
                                                </Box> */}<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <IconButton
                                                            type="submit"
                                                            aria-label="send"
                                                            disabled={sending}
                                                            sx={sendButtonStyle}>

                                                            {sending ? (
                                                                <CircularProgress size={20} sx={{ color: '#fff' }} />
                                                            ) : (
                                                                <SendIcon sx={{ color: '#fff' }} />
                                                            )}
                                                        </IconButton></Box>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Fragment>
    );
}
export default Chatting
