import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import MetaData from '../layout/MetaData'
import { toast, ToastContainer } from 'react-toastify';
// import { useAlert } from 'react-alert'
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux'
import { registeruser, clearErrors } from '../../actions/userActions'
import { useForm } from "react-hook-form";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { pBtnColor } from '../styles/paabot-styles'
import Loader from '../layout/loader';



import {
    Input,
    FormControl,
    FormHelperText,
    Grid,
    Paper,
    Typography,
    Box,
    TextField,
    Button,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const Register = () => {
     const notifyError = (error) => toast.error(error, {
      position: toast.POSITION.BOTTOM_CENTER
  })

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const notify = (message = '') => toast.success(message, {
        icon: () => <HowToRegIcon color='success' />,
        position: toast.POSITION.TOP_LEFT
    });
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    })

    const { firstName, lastName, email, password } = user;

    const [avatar, setAvatar] = useState('')
    const [avatarPreview, setAvatarPreview] = useState('/images/default_avatar.png')

    // const alert = useAlert();
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { isAuthenticated, error, loading } = useSelector(state => state.auth);

    useEffect(() => {

        if (isAuthenticated) {
            navigate('/')
            notify('Registration Successful!')
        }

        if (error) {
            // alert.error(error);
            notifyError(error)
            dispatch(clearErrors());
        }

    }, [dispatch, isAuthenticated, error, navigate])

    const submitHandler = (data, event) => {
        const formData = new FormData();
        formData.set('firstName', firstName);
        formData.set('lastName', lastName);
        formData.set('email', email);
        formData.set('password', password);
        formData.set('avatar', avatar);
   
        dispatch(registeruser(formData))
    }

    const onChange = (e) => {
        if (e.target.name === 'avatar') {
          const file = e.target.files[0];
          if (file && file instanceof Blob) {  // Ensure the file is a Blob
            const reader = new FileReader();
            reader.onload = () => {
              if (reader.readyState === 2) {
                setAvatarPreview(reader.result);
                setAvatar(reader.result);
              }
            };
            reader.readAsDataURL(file);
          } else {
            notifyError('The selected file is not a Blob or File type')
          }
        } else {
          setUser({ ...user, [e.target.name]: e.target.value });
        }
      };

    return (
        <Fragment>

            <MetaData title={'Register User'} />
            <Grid container justifyContent="center">
                <Grid item xs={10} lg={5} my={5}>
                    <Paper elevation={12} sx={{ padding: 3 }}>
                        <form onSubmit={handleSubmit(submitHandler)} encType="multipart/form-data">
                          <Box sx={{ marginBottom: '50px' }}>
                            <Typography variant="h4" >
                                Register
                            </Typography>
                            <Typography> <em>
                                By registering, you hereby agree to adhere to RAD4's <Link to="/terms-of-use" sx={{color: '#000'}}>terms of use</Link>.</em>
                            </Typography>
                            </Box>
                            <Box mb={3}>
                                <TextField
                                    id="first_name_field"
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                    {...register("firstName", { required: "First Name is required!" })}
                                    value={firstName}
                                    onChange={onChange}
                                    error={!!errors.firstName}
                                    helperText={errors.firstName && <i>{errors.firstName.message}</i>}
                                />
                            </Box>

                            <Box mb={3}>
                                <TextField
                                    id="last_name_field"
                                    label="Last Name"
                                    variant="outlined"
                                    fullWidth
                                    {...register("lastName", { required: "lastName is required!" })}
                                    value={lastName}
                                    onChange={onChange}
                                    error={!!errors.lastName}
                                    helperText={errors.lastName && <i>{errors.lastName.message}</i>}
                                />
                            </Box>


                            <Box mb={3}>
                                <TextField
                                    id="email_field"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    {...register("email", {
                                        required: "Email field cannot be empty.",
                                        pattern: {
                                            value: /^\S+@\S+$/i,
                                            message: "Invalid Email format!"
                                        }
                                    })}
                                    value={email}
                                    onChange={onChange}
                                    error={!!errors.email || !!error}
                                    helperText={(errors.email || error) && <i>{errors.email?.message || error}</i>}
                                />
                            </Box>

                            <Box mb={3}>
                                <TextField
                                    type="password"
                                    id="password_field"
                                    label="Password"
                                    variant="outlined"
                                    fullWidth
                                    {...register("password", {
                                        required: "Password is required!",
                                        minLength: {
                                            value: 8,
                                            message: "Password is too short! Minimum of 8 characters allowed."
                                        }
                                    })}
                                    value={password}
                                    onChange={onChange}
                                    error={!!errors.password}
                                    helperText={errors.password && <i>{errors.password.message}</i>}
                                />
                            </Box>
                            <FormControl fullWidth error={!!errors.avatar} > 
                            <Grid container >
                                {/* <Grid item>
                                    <Typography htmlFor="avatar_upload">Upload a profile Picture: </Typography>
                                </Grid> */}
                                <Grid item container justifyContent= 'space-around' spacing={3} sx={{display: 'flex'}}>
                                    <Grid item sx={{justifyContent: 'flex-start'}}>
                                        <Avatar src={avatarPreview} alt="Avatar Preview" sx={{ width: 100, height: 100, mt: 2,  }} />
                                    </Grid>

                                    <Grid item sx={{ alignItems: 'center', justifyContent: 'flex-end', display: 'flex' }} >
                                        <label htmlFor="customFile">
                                            <Button
                                                variant="contained"
                                                component="span"
                                                color="primary"
                                                startIcon={<CloudUploadIcon />}
                                                sx={{...pBtnColor}}
                                            >
                                                Upload Profile Picture
                                            </Button>
                                        </label>
                                        <input
                                            type="file"
                                            {...register("avatar", { required: "Avatar is required!" })}
                                            id="customFile"
                                            accept="images/*"
                                            onChange={onChange}
                                            style={{ display: 'none' }} // Hide the original file input
                                        />
                                     
                                    </Grid>

                                </Grid>
                            </Grid>
                            <FormHelperText>{errors.avatar && <i>{errors.avatar.message}</i>}</FormHelperText>
                            </FormControl>
                            <br /> <br />
                           
                            <Button
                                id="register_button"
                                type={loading ? 'button' : 'submit'}
                                variant="contained"
                                fullWidth
                                loading loadingPosition="end"
                                sx={{ py: 3, ...pBtnColor }}

                            >
                                  {loading ? <CircularProgress sx={{color: '#fff'}} size={20} /> : `Register` } 
                            </Button>
                        </form>

                        <p>
                            Already have an account? <Link to="/login" className='mx-4'>Login Now</Link>
                        </p>
                    </Paper>
                </Grid>
            </Grid>

        </Fragment>
    )
}

export default Register