import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';


export const StyledBadge = styled(Badge)(({ theme, status }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: getStatusColor(status), // Call a function to determine the color based on status
    color: getStatusColor(status),
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const getStatusColor = (status) => {
  switch (status) {
    case 'active':
      return '#44b700'; // Green color for active status
    case 'online':
      return '#e7b416';
    case 'offline':
      return '#cc3232';
    case 'do not disturb':
      return '#db7b2b';
    // Add more cases for other statuses if needed
    default:
      return '#000000'; // Default color
  }
};