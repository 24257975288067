
export const MY_NOTIFICATIONS_REQUEST = 'MY_NOTIFICATIONS_REQUEST' 
export const MY_NOTIFICATIONS_SUCCESS = 'MY_NOTIFICATIONS_SUCCESS'
export const MY_NOTIFICATIONS_FAIL = 'MY_NOTIFICATIONS_FAIL'
export const MY_NOTIFICATIONS_RESET = 'MY_NOTIFICATIONS_RESET'

export const DELETE_NOTIFICATIONS_REQUEST = 'DELETE_NOTIFICATIONS_REQUEST'
export const DELETE_NOTIFICATIONS_SUCCESS = 'DELETE_NOTIFICATIONS_SUCCESS'
export const DELETE_NOTIFICATIONS_RESET = 'DELETE_NOTIFICATIONS_RESET'
export const DELETE_NOTIFICATIONS_FAIL = 'DELETE_NOTIFICATIONS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

