import axios from 'axios'

import {
    ALL_COURSES_REQUEST,
    ALL_COURSES_SUCCESS,
    ALL_COURSES_FAIL,
    COURSE_DETAILS_REQUEST,
    COURSE_DETAILS_SUCCESS,
    COURSE_DETAILS_FAIL,
    ADMIN_COURSES_REQUEST,
    ADMIN_COURSES_SUCCESS,
    ADMIN_COURSES_FAIL,
    NEW_COURSE_REQUEST,
    NEW_COURSE_SUCCESS,
    NEW_COURSE_FAIL,
    DELETE_COURSE_REQUEST,
    DELETE_COURSE_SUCCESS,
    DELETE_COURSE_FAIL,
    UPDATE_COURSE_REQUEST,
    UPDATE_COURSE_SUCCESS,
    UPDATE_COURSE_FAIL,
    CLEAR_ERRORS
} from '../constants/courseConstants';

export const allCourses = () => async (dispatch) => {
    try {
    
        dispatch({ type: ALL_COURSES_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/CYS-All/`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: ALL_COURSES_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: ALL_COURSES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const addCourse = (courseData) => async (dispatch) => {
    try {
    
        dispatch({ type: NEW_COURSE_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }
        const { data } = await axios.post(`${process.env.REACT_APP_API}/api/v1/admin/CYS/new`, courseData, config)
         
        dispatch({
            type: NEW_COURSE_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: NEW_COURSE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateCourse = (courseId, courseData) => async (dispatch) => {
    try {
    // console.log('submitted data: ',courseData);
        dispatch({ type: UPDATE_COURSE_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true
        }
        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/admin/CYS/${courseId}`, courseData, config )
         
        dispatch({
            type: UPDATE_COURSE_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_COURSE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deleteCourse = (courseId) => async (dispatch) => {
    try {
    
        dispatch({ type: DELETE_COURSE_REQUEST })
        const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/v1/admin/CYS/${courseId}`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: DELETE_COURSE_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: DELETE_COURSE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getCourseDetails = (id) => async (dispatch) => {
    
    try {
        dispatch({ type: COURSE_DETAILS_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/CYS/${id}`,config)
        dispatch({
            type: COURSE_DETAILS_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: COURSE_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS

    })
}