import React, { useState, useCallback, useEffect, useRef } from 'react'
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import MaterialAvatar from '@mui/material/Avatar';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckIcon from '@mui/icons-material/Check';

const VirtualizedMessageList = ({ umessages, user, uconvo, selectedUserId, messagesBoxRef }) => {
    const listRef = useRef({});
    const rowHeights = useRef({});
    const formattedSentAt = (sentAt) => {
        const date = new Date(sentAt);
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
        return date.toLocaleDateString('en-US', options);
    };

    const minimalDate = (sentAt) => {
        const date = new Date(sentAt);
        const now = new Date();

        const diffInDays = Math.floor((now - date) / (24 * 60 * 60 * 1000));

        if (diffInDays > 0) {
            // If more than a day ago, return month day, year format
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        } else {
            // If within the same day, return AM/PM format
            const options = { hour: 'numeric', minute: 'numeric', hour12: true };
            return date.toLocaleTimeString('en-US', options);
        }
    };

    function getRowHeight(index) {
        return rowHeights.current[index] + 8 || 82;
      }
    
      function Row({ index, style }) {
        const rowRef = useRef({});
    
        useEffect(() => {
          if (rowRef.current) {
            setRowHeight(index, rowRef.current.clientHeight);
          }
          // eslint-disable-next-line
        }, [rowRef]);
    
        const message = umessages.messages[umessages?.messages?.length - index - 1]; // Reverse the order
        return (
            <div style={style} key={message._id}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        alignSelf: message.sender === user._id ? 'flex-end' : 'flex-start',
                        justifyContent: message.sender === user._id ? 'flex-end' : 'flex-start',
                    }}
                >
                    {message.sender !== user._id && (
                        <MaterialAvatar
                            sx={{ width: '30px', height: '30px', marginTop: '5px', marginX: '10px' }}
                            src={message?.senderAvatar}
                            alt={message?.sender}
                        />
                    )}
                    <Tooltip title={formattedSentAt(message?.sentAt)} placement={message?.sender === user._id ? 'top-end' : 'top-start'}>
                        <Box
                            bgcolor={message?.sender === user._id ? '#fff' : '#345f3c'}
                            color={message?.sender === user._id ? '#000' : '#fff'}
                            borderRadius="10px"
                            padding="10px"
                            border={'1.5px solid #345f3c'}
                            borderColor={message?.sender === user._id ? '#345f3c' : '#345f3c'}
                            marginRight={message?.sender === user._id ? '15px' : '150px'}
                            marginLeft={message?.sender === user._id ? '150px' : '0px'}
                            width="fit-content"
                            maxWidth="70%"
                            whiteSpace="pre-wrap"
                            ref={rowRef}
                        >
                            <Typography variant="body1">{message?.message}


                            </Typography>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: '#808080',
                                    marginTop: '5px',
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: message.sender === user._id ? 'flex-end' : 'flex-start',
                                }}
                            >
                                {minimalDate(message.sentAt)}{message.sender === user._id ? 
message.seen === false ? (
<CheckIcon sx={{ my: 'auto', fontSize: '16px' }} />
) : (
<DoneAllIcon sx={{ my: 'auto', fontSize: '16px' }} />
)
: null}
                            </Typography>
                        </Box>
                    </Tooltip>
                </Box>
            </div>
        );
      }

      function setRowHeight(index, size) {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
      }



    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    height={height}
                    itemCount={umessages?.messages?.length}
                    width={width}
                    initialScrollOffset={Math.max(0, (umessages?.messages?.length) * 75)}
                    outerRef={messagesBoxRef}  // Make sure to use the provided prop
                    itemSize={getRowHeight}
                    ref={listRef}
                // onItemsRendered={handleItemsRendered}
                >
                      {Row}
                  
                </List>
            )}
        </AutoSizer>
    );
};

export default VirtualizedMessageList;
