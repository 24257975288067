import axios from 'axios'
import * as reqst from '../constants/requestConstants'

// export const updateSchedStatus = (reqId, status) => async (dispatch) => {
//     try {
//         dispatch({ type: reqst.UPDATE_STATUS_REQUEST })
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             withCredentials: true,
//         }

//         const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/request/${reqId}`, {status}, config)
//         dispatch({
//             type: reqst.UPDATE_STATUS_SUCCESS,
//             payload: data.success
//         })

//          // Dispatch the CLEAR_SEND_STATE action to reset the send state
//          dispatch({ type: reqst.UPDATE_STATUS_RESET });
//     } catch (error) {
//         dispatch({
//             type: reqst.UPDATE_STATUS_FAIL,
//             payload: error.response ? error.response.data.message : 'Unknown error'
//         })
//     }
// }

export const getMyRequests = () => async (dispatch) => {
    try {
    
        dispatch({ type: reqst.MY_REQUESTS_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/my-requests`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: reqst.MY_REQUESTS_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: reqst.MY_REQUESTS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const addRequest = (requestData) => async (dispatch) => {
    try {
    
        dispatch({ type: reqst.NEW_REQUEST_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.post(`${process.env.REACT_APP_API}/api/v1/request/new`, requestData, config)
         
        dispatch({
            type: reqst.NEW_REQUEST_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: reqst.NEW_REQUEST_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateRequest = (requestId, requestData) => async (dispatch) => {
    try {
    
        dispatch({ type: reqst.UPDATE_REQUEST_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/request/${requestId}`, requestData, config)
         
        dispatch({
            type: reqst.UPDATE_REQUEST_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: reqst.UPDATE_REQUEST_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deleteRequest = (requestId) => async (dispatch) => {
    try {
    
        dispatch({ type: reqst.DELETE_REQUEST_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/v1/request/${requestId}`, config)
         
        dispatch({
            type: reqst.DELETE_REQUEST_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: reqst.DELETE_REQUEST_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: reqst.CLEAR_ERRORS

    })
}