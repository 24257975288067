// websocket.js
import io from "socket.io-client";
import { useState, useEffect } from 'react';

const useWebSocket = (url) => {
    const [socket, setSocket] = useState(null);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        const socketInstance = io.connect(url, {
            transports: ['websocket'],
        });

        socketInstance.on('connect', () => {
            setIsConnected(true);
        });

        socketInstance.on('disconnect', () => {
            setIsConnected(false);
        });

        setSocket(socketInstance);

        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
            }
        };
    }, [url]);

    return { socket, isConnected };
};

export default useWebSocket;
