import { ArrowBack, KeySharp, Print } from '@mui/icons-material';
import { Avatar, Box, Fab, Grid, IconButton, Paper, Tooltip, Typography, Modal } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pie } from 'react-chartjs-2';
// import { allRoomKeys } from '../../actions/roomKeyActions';
import { getReqStats } from '../../actions/StatActions';
import { Bar } from 'react-chartjs-2';
import { useReactToPrint } from 'react-to-print';
import Loader from '../layout/loader';
import People from '@mui/icons-material/People';
import Swal from 'sweetalert2';
import moment from 'moment/moment';
import BarDetails from './BarDetails';

const RequestChart = ({ dashboard }) => {
    const boxStyle = {
        textAlign: "center",
        height: "100%",
        padding: "5px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
    };

    const boxFormat = {
        display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'
    }
    const dispatch = useDispatch();
    const { loading, error, reqstat, reqReport } = useSelector((state) => state.reqstat)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        if (!reqstat && !reqReport) {
            dispatch(getReqStats())
        }
    }, [])
    const rankings = (top, label) => {
        let top10Users ='';
        // console.log('reqReport', reqReport)
        
        if(label === 'Top 10 Requestors'){
            top?.slice(0, 10).forEach((topUser, i) => {
                if (topUser?.userId?.username) {
                    top10Users += `<div><span key="${i}" style="text-align: left;"> ${i + 1}. ${topUser.userId.username}: ${topUser.numberOfRequests} </span> </div>`;
                }
            });
            // top?.topRequestors?.userId?.username
        } else {
            top?.slice(0, 10).forEach((topUser, i) => {
                if (topUser?.userId?.username) {
                    top10Users += `<span key="${i}"> ${i + 1}. ${topUser.userId.username}: ${topUser.numberOfRequests} </span> <br/>`;
                }
            });

        }

        if (!top10Users){
            top10Users = `No ${label} at the moment.`
        }
     
        Swal.fire({
            title: `${label}`,
            html: `    <div style="display: flex; justify-content: center;">
            <div style="text-align: left;">
                ${top10Users}
            </div>
        </div>`,
            // icon: 'info',
        });
    }
    const generateRequestsPerDayChart = (reqstat) => {
        const formatDate = (dateString) => {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' });
        };

        const dates = reqstat.map(item => formatDate(item.date));
        const ignored = reqstat.map(item => item.ignored);
        const denied = reqstat.map(item => item.denied);
        const granted = reqstat.map(item => item.granted);

        const data = {
            labels: dates,
            datasets: [
                {
                    label: 'Ignored',
                    data: ignored,
                    backgroundColor: '#f0db5b',
                },
                {
                    label: 'Denied',
                    data: denied,
                    backgroundColor: '#ed665c',
                },
                {
                    label: 'Granted',
                    data: granted,
                    backgroundColor: '#4ff08d',
                },
            ],
        };

        const options = {
            onClick: (event, elements) => {
                if (elements.length > 0) {
                    const element = elements[0];
                    const datasetLabel = data.datasets[element.datasetIndex].label;
                    const value = data.datasets[element.datasetIndex].data[element.index];
                    const label = data.labels[element.index];
                    handleBarDtlsOpen(label, datasetLabel);
                    // Swal.fire({
                    //     title: `${datasetLabel}`,
                    //     text: `Date: ${label}\nValue: ${value}`,
                    //     icon: 'info',
                    // });
                }
            },
            scales: {
                xAxes: [{ stacked: true }],
                yAxes: [{ stacked: true }],
            },
            aspectRatio: 1, // Adjust this value to change the aspect ratio
            maintainAspectRatio: false, // Set to false to allow the chart to resize based on container size    
        };

        return <Bar data={data} options={options} />;
    };
    const data = {
        labels: [
            'Granted',
            'Denied',
            'Ignored',
        ],
        datasets: [
            {
                data: [reqReport.totalGranted, reqReport.totalDenied, reqReport.totalIgnored],
                backgroundColor: ['#008450', '#b81d13', '#efb700'], // Set color based on availability
                hoverBackgroundColor: ['#005131', '#84140d', '#bc8f00'], // Set hover color based on availability
            },
        ],
    };

    const options = {
        aspectRatio: 1, // Adjust this value to change the aspect ratio
        maintainAspectRatio: false, // Set to false to allow the chart to resize based on container size    
        plugins: {
            legend: {
                position: 'right', // Set the legend position to the right side
            },
        },
    };

    const [modalBarDtlsOpen, setModalBarDtlsOpen] = useState(false);
    const [historyStatus, setHistoryStatus] = useState(null);
    const [outCome, setOutCome] = useState(null);

    const handleBarDtlsOpen = (date, hStatus) => {
        // Parse the input date string to a moment object
        const parsedDate = moment(date, 'MM/DD/YY');
      
        // Filter the main history based on the date and desired outcome
        const filteredHistory = reqstat.reduce((acc, history) => {
          // Check if the date matches
          if (moment(history.date).isSame(parsedDate, 'day')) {
            // Extract and filter the deliveryHistory for each requestor
            history.users.requestors.forEach(requestor => {
              const matchedDeliveries = requestor.requestHistory.filter(request => request.reqOutcome === hStatus);
              if (matchedDeliveries.length > 0) {
                // acc.push(...matchedDeliveries);
                matchedDeliveries.forEach(delivery => {
                    acc.push({
                      ...delivery, // Convert Mongoose document to plain object if needed
                      requestorId: requestor.userId
                    });
                  });
              }
            });
          }
          return acc;
        }, []);
      
        // Update the state with the filtered delivery history
        setHistoryStatus(filteredHistory);
      setOutCome(hStatus)
        // Open the modal
        setModalBarDtlsOpen(true);
      };

    //   useEffect(()=>{
    //     // console.log('History Status Bar Details: ', historyStatus)
    //   },[historyStatus]);
  
    const handleBarDtlsClose = () => {
      setModalBarDtlsOpen(false);
    };

    useEffect(()=>{

    })

    return (
        <div>
            {loading ? (
                <Loader />
            ) : error ? (
                <p>Error fetching chart data: {error}</p>
            ) : (
                <div >
                    <Box ref={componentRef} >
                        <Box className="no-print">
                            <IconButton sx={{
                                ml: 3, mb: -10,
                                '&:hover': {
                                    // Add styles for hover state
                                    backgroundColor: 'rgba(0, 0, 0, 0.0)',
                                    color: '#49B265',
                                    // Add any other styles you want to apply on hover
                                },
                            }}
                                onClick={dashboard}
                            ><ArrowBack sx={{
                                fontSize: 35, color: '#000',
                                '&:hover': {
                                    // Add styles for hover state
                                    color: '#49B265',
                                    // Add any other styles you want to apply on hover
                                },
                            }}></ArrowBack></IconButton></Box>
                        <Box className="d-margin">
                            <div className='thirteen'>
                                <h1>Request Report</h1> </div>
                            <Grid container spacing={8} sx={{ mb: 5, mt: 0.1, p: 2 }}>
                                <Grid item xs={4}>
                                    <Paper sx={boxStyle} className="hoverblue" onClick={()=> rankings(reqReport?.topRequestors, 'Top 10 Requestors')}>
                                        <Box sx={boxFormat}>
                                            {/* <People color="info" sx={{ fontSize: 60 }} /> */}
                                            <Avatar sx={{ width: '70px', height: '70px' }} src={reqReport?.topRequestors[0]?.userId?.avatar} />
                                            <Typography variant="button" fontWeight='bold'>Top Requestor</Typography>
                                            <Typography variant="h6" fontWeight={"bold"}>{reqReport?.topRequestors[0]?.userId?.username}</Typography>
                                            <Typography variant="subtitle1" sx={{ color: '#505050' }}>Has Requested {reqReport?.topRequestors[0]?.numberOfRequests} times </Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper sx={boxStyle}>
                                        <Box sx={boxFormat}>
                                            {/* <People color="info" sx={{ fontSize: 60 }} /> */}
                                            <KeySharp sx={{ width: '70px', height: '70px' }} />
                                            <Typography variant="button" fontWeight='bold'>Top Room Key Requested</Typography>
                                            <Typography variant="h6" fontWeight={"bold"}>Room {reqReport?.topRequestedKey[0]?.keyId?.keyNo}</Typography>
                                            <Typography variant="subtitle1" sx={{ color: '#505050' }}>Has been requested {reqReport?.topRequestedKey[0]?.numberOfRequests} times </Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper sx={boxStyle} className="hoverblue" onClick={()=> rankings(reqReport?.topRequestees, 'Top 10 Requestees')}>
                                        <Box sx={boxFormat}>
                                            <Avatar sx={{ width: '70px', height: '70px' }} src={reqReport?.topRequestees[0]?.userId?.avatar} />
                                            <Typography variant="button" fontWeight='bold'>Most Requested Prof</Typography>
                                            <Typography variant="h6" fontWeight={"bold"}>{reqReport?.topRequestees[0]?.userId?.title} {reqReport?.topRequestees[0]?.userId?.username}</Typography>
                                            <Typography variant="subtitle1" sx={{ color: '#505050' }}>Has received {reqReport?.topRequestees[0]?.numberOfRequests} requests </Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Paper elevation={8} sx={{ m: 'auto' }}>
                                        <div className='seven'>
                                            <h1>Request Status Ratio</h1> </div>
                                        <Box sx={{ mx: 'auto', height: '320px', p: 2.5 }}>

                                            <Pie data={data} options={options} /></Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper elevation={8} sx={{ height: '100%', m: 'auto' }}>
                                        <div className='seven'>
                                            <h1>Request Per Day</h1> </div>
                                        <Box sx={{ height: '320px', m: 'auto' }}>
                                            {generateRequestsPerDayChart(reqstat)}</Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Modal
        open={modalBarDtlsOpen}
        onClose={handleBarDtlsClose}
        BackdropProps={{
          sx: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } // Customize backdrop style here
        }}
        // sx={{width: '50vh'}}
      >
        <Box sx={{ margin: 'auto', width: '60%', height: '40%', borderRadius: 5}}>
          <BarDetails historyStatus={historyStatus} status={outCome}/> {/* Render the NotifsSection component inside the modal */}
        </Box>
      </Modal>
                    <Tooltip title="Print Delivery Reports" placement="top">
                        <Fab
                            color="success"
                            aria-label="filter"
                            onClick={handlePrint}
                            sx={{ position: "fixed", top: 500, right: 40, zIndex: "20" }}
                        >
                            <Print />
                        </Fab>
                    </Tooltip>
                </div>
            )}
            <br />
        </div>
    );
};

export default RequestChart;
