import {
  FETCH_CHART_DATA_REQUEST,
  FETCH_CHART_DATA_SUCCESS,
  FETCH_CHART_DATA_FAILURE,
  FETCH_BUG_REPORTS_REQUEST,
  FETCH_BUG_REPORTS_SUCCESS,
  FETCH_BUG_REPORTS_FAILURE,
} from "../constants/chartConstants";

const initialState = {
  loading: false,
  error: null,
  chartData: {
    labels: [],
    datasets: [],
  },
  bugReports: [],
};

export const chartReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHART_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CHART_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        chartData: action.payload,
      };
    case FETCH_CHART_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const bugReportChartReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUG_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_BUG_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        bugReports: action.payload,
      };
    case FETCH_BUG_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
