import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form";
import { Paper, Box, Button, Typography, IconButton, Input, Grid, TextField, Badge, Tooltip } from '@mui/material'
import MaterialAvatar from '@mui/material/Avatar'
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import { StyledBadge } from '../styles/onlineUsers';
import { createConversation, sendMessage, userConversations, getMessages } from '../../actions/chatActions';
import ForumIcon from '@mui/icons-material/Forum';
import { alpha } from '@mui/material'
import { markSeen } from '../../actions/chatActions';
import initializeSocket from '../../socket/webSocket';
import VirtualizedMessageList from './virtualizedMessages';

const formattedSentAt = (sentAt) => {
    const date = new Date(sentAt);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
    return date.toLocaleDateString('en-US', options);
};

const minimalDate = (sentAt) => {
    const date = new Date(sentAt);
    const now = new Date();

    const diffInDays = Math.floor((now - date) / (24 * 60 * 60 * 1000));

    if (diffInDays > 0) {
        // If more than a day ago, return month day, year format
        const options = { month: '2-digit', day: '2-digit', year: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    } else {
        // If within the same day, return AM/PM format
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleTimeString('en-US', options);
    }
};

const Chat = () => {
    const location = useLocation();
    const profData = location.state ? location.state.profData : null;
    const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();
    const messagesBoxRef = useRef();
    const { user, loading } = useSelector(state => state.auth)
    // const { convo, loading: convoload } = useSelector(state => state.convos)
    const { uconvo, loading: umessageload } = useSelector(state => state.uconvos)
    const { umessages, loading: uconvoload } = useSelector(state => state.umessages)
    const { newconvoid, loading: newConvoLoad } = useSelector(state => state.convocreate)
    const { sending } = useSelector(state => state.chat)
    const [totalMessages, setTotalMessages] = useState(0);
    const { loading: seenload } = useSelector(state => state.seen)
    const [isLoading, setIsLoading] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState(null);
    const [message, setMessage] = useState('');
    const [unreadMessages, setunreadMessages] = useState(0);
    const [changeCount, setChangeCount] = useState(0);
    const [conversationId, setConversationId] = useState('');
    let userUnreadMessages = uconvo
        .filter(conversation => conversation._id === conversationId) // Filter the relevant conversation
        .flatMap(conversation => conversation.latestMessage) // Access the latestMessage property
        .filter(message =>
            message?.sender?._id === selectedUserId && !message.seen
        );

    // truncate text
    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return `${text.substring(0, maxLength)}...`;
    };

    // get usreid convoid and unreadmessages count on user click
    const handleUserClick = (userId, convoId, unseenMessage) => {
        setSelectedUserId(userId);
        setConversationId(convoId);
        setunreadMessages(unseenMessage);
        if (unseenMessage > 0) {
            dispatch(markSeen(conversationId)).then(() => {
                if (!seenload) {
                    dispatch(userConversations()).then(() => {
                        dispatch(getMessages(conversationId))
                        if (!uconvoload && messagesBoxRef.current) {
                            messagesBoxRef.current.scrollTop = messagesBoxRef.current.scrollHeight;
                        }
                    });
                }
            });
        }
    };


    // set scroll position to bottom on default
    useEffect(() => {
        // if (unreadMessages > 0) {
        //     dispatch(markSeen(conversationId)).then(() => {
        //         if (!seenload) {
                    dispatch(userConversations()).then(() => {

                        if (!uconvoload && messagesBoxRef.current) {
                            messagesBoxRef.current.scrollTop = messagesBoxRef.current.scrollHeight;
                        }
                    });
        //         }
        //     });
        // }
        if (unreadMessages === 0 && messagesBoxRef.current) {
            messagesBoxRef.current.scrollTop = messagesBoxRef.current.scrollHeight;
        }
    }, [selectedUserId, umessages]);

    // get all conversations at first component mount



    useEffect(() => {
        // dispatch(myConversations());
        dispatch(userConversations());
    }, [sending]);

    // update whenever there are new unread messages
    useEffect(() => {
        if (!uconvoload && !loading && user) {
            const socket = initializeSocket();
            socket.on('unreadMessages', (event) => {
                try {
                    const data = JSON.parse(event);
                    const { userId = null, unreadMessagesCount = 0 } = data;
                    if (selectedUserId === userId) {
                        dispatch(getMessages(conversationId))
                    }
                    if (userId === user?._id) {
                        if (unreadMessages > 0 && conversationId) {
                            dispatch(getMessages(conversationId))
                            // dispatch(markSeen(conversationId)).then(() => {
                            //     if (!seenload) {
                            //         dispatch(userConversations()).then(() => {
                            //             if (!uconvoload) {

                            //                 messagesBoxRef.current.scrollTop = messagesBoxRef?.current?.scrollHeight;
                            //             }
                            //         });
                            //     }
                            // });
                        }
                        if (unreadMessages === 0) {
                            dispatch(userConversations()).then(() => {
                                if (!uconvoload) {
                                    messagesBoxRef.current.scrollTop = messagesBoxRef?.current?.scrollHeight;
                                }
                            });

                            // console.log('this conversation has : ', userUnreadMessages.length, ' unread messages');

                            messagesBoxRef.current.scrollTop = messagesBoxRef?.current?.scrollHeight;
                        }
                    }
                } catch (error) {
                    console.error('Error parsing or handling unreadMessages event:', error);
                }
            });

            return () => {
                socket.disconnect();
            };
        }
    }, []);


    useEffect(() => {
        if (!uconvoload && changeCount < 2) {
            if (!profData && uconvo.length > 0) {
                const latestUser = uconvo[uconvo.length - 1].participants._id;
                const latestConvo = uconvo[uconvo.length - 1]._id;

                if (latestUser !== selectedUserId) {
                    setSelectedUserId(latestUser);
                }

                if (latestConvo !== conversationId) {
                    setConversationId(latestConvo);
                }
            }
            else if (profData && profData._id) {
                const conversationWithProf = uconvo.find(chat =>
                    chat.participants && chat.participants._id === profData._id
                );

                if (conversationWithProf) {
                    // Check if _id is defined before accessing it
                    if (conversationWithProf._id) {
                        // // console.log('you had a convo with this prof!:', conversationWithProf._id);
                        setSelectedUserId(conversationWithProf.participants._id);
                        setConversationId(conversationWithProf._id);
                    } else {
                        setSelectedUserId(profData._id);
                        setConversationId('none');
                    }
                } else {
                    setSelectedUserId(profData._id);
                    setConversationId('none');
                }
            }

            setChangeCount(prevCount => prevCount + 1);
        }
    }, [profData, user._id, uconvo]);

    //dispatch messages based on conversationId (laod messages on user click)
    useEffect(() => {
        if (!uconvoload && conversationId && conversationId !== 'none') {
            // // console.log('This is the convo id: ', conversationId)
            dispatch(getMessages(conversationId))
        }
    }, [conversationId, sending]);

    // fetch total messages
    useEffect(() => {
        if (!umessageload && umessages.totalMessages) {
            setTotalMessages(umessages.totalMessages);
            // console.log('Total Messages: ', umessages.totalMessages);
        }
    }, [umessages.totalMessages]);

    //   fetch more messages on scroll up

    const messageHandleChange = (event) => {
        setMessage(event.target.value);

    };

    const submitHandler = (data, event) => {
        if (conversationId && conversationId !== 'none') {
            dispatch(sendMessage(conversationId, message));
            setMessage('')
        } else if (selectedUserId) {
            dispatch(createConversation(selectedUserId));
            // .then(() => {
            //     // console.log('this is the newconvoid: ', newconvoid)
            //     // The newconvoid is accessible after the createConversation action is successful
            //     dispatch(sendMessage(newconvoid, message))
            //     .then(()=>{
            //         setMessage('')
            //     })

            // })
            // .catch((error) => {
            //     // Handle createConversation failure if needed
            //     console.error('Error creating conversation:', error);
            // });
        }

        // // console.log('this is the message: ', message);
        // dispatch(registeruser(formData))
    }

    // useEffect(() => {
    //     if (newConvoLoad) {
    //         // console.log('Creating conversation...');
    //     } else if (newconvoid) {
    //         // console.log('Conversation created: ', newconvoid);
    //     }
    // }, [newconvoid, newConvoLoad]);

    useEffect(() => {
        if (!newConvoLoad && newconvoid) {
            // console.log('this is the newconvoid: ', newconvoid)
            dispatch(sendMessage(newconvoid, message))
                .then(() => {
                    setMessage('')
                    setConversationId(newconvoid)
                })
        }
    }, [newconvoid])

    // // console.log('this is the id of the selected user: ', selectedUserId)

    return (
        <Fragment>
            {/* <Box sx={{ display: { xs: "none", md: "flex" } }}> */}
                <Box
                    sx={{
                        // height: '100vh', 
                        justifyContent: 'center',  // Horizontally center
                        alignContent: 'center',      // Vertically center
                        display: { xs: "none", md: "flex" },
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1,
                            width: '70%',
                            height: '70%',
                        },
                        position: 'static'

                    }}
                >
                    <Paper elevation={12} >
                        <Grid container columns={12} sx={{ height: '100%' }}>
                            {/* First Column */}
                            <Grid item xs={5} >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        backgroundColor: '#014422',
                                        color: '#fff',
                                        height: '100%',
                                    }}
                                >
                                    <Box sx={{ height: '15vh', paddingLeft: '30px', display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <a href="#" className="bdr-ripple-ani-btn green two">
                                                <MaterialAvatar
                                                    sx={{ width: '48px', height: '48px', border: '1px solid #44b700', borderRadius: '50%' }}
                                                    className='elevated-avatar'
                                                    src={user.avatar && user.avatar.url}
                                                    elevation={24}
                                                />
                                            </a>

                                            <Typography ml={3}>{user.username} (YOU)</Typography>
                                        </Box>

                                    </Box>
                                    <Box
                                        sx={{
                                            height: '6vh',
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#345f3c',
                                            marginBottom: '20px'
                                        }}
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <SearchIcon sx={{ paddingX: '20px' }} />
                                        <Input
                                            placeholder="Search conversation"
                                            fullWidth
                                            disableUnderline
                                            sx={{
                                                color: 'rgba(255, 255, 255, 0.8)',
                                                '&:hover': {
                                                    borderBottom: 'none',
                                                },
                                            }}
                                        />
                                    </Box>

                                    {/*=========== user list section ============ */}
                                    <Box
                                        sx={{
                                            height: 'calc(100vh - 41vh)', // Adjust the height as needed
                                            overflowY: 'auto', // Enable vertical scrolling when content exceeds the container height
                                        }}
                                    >

                                        {/* when the user has no history of conversation with the prof they clicked on the sidebar section */}
                                        {profData && !uconvo.some(chats => chats.participants._id === profData._id) && (
                                            <Box
                                                key={`${profData._id}-unq`}
                                                sx={{
                                                    height: '10vh',
                                                    marginX: '1px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    borderBottom: '1px solid #345f3c',
                                                    padding: '0 30px',
                                                    cursor: 'pointer',
                                                    backgroundColor: selectedUserId === profData._id ? '#2e5239' : 'transparent',
                                                    '&:hover': {
                                                        backgroundColor: '#2e5239',
                                                    },
                                                }}
                                                onClick={() => handleUserClick(profData._id, 'none', 0)}
                                            >
                                                <StyledBadge
                                                    status={profData.status}
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    variant="dot"
                                                    sx={{ mr: 2, marginRight: '20px' }}
                                                >
                                                    <MaterialAvatar
                                                        sx={{ width: '40px', height: '40px' }}
                                                        src={profData.avatar && profData.avatar.url}
                                                        elevation={2}
                                                    />
                                                </StyledBadge>

                                                <Box>
                                                    <Typography>  {profData.role !== 'student' && profData.title && `${profData.title} `} {profData.username}</Typography>

                                                    <Typography variant="body2" sx={{ color: '#ffffff', fontWeight: 'bold' }}>
                                                        Create a conversation.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}


                                        {/* fetch user conversations, if the they already ahve a conversation with the prof he clicked on the sidebar, it is automatically highlighted/selected */}
                                        {uconvo.map((chats, index) => (

                                            <Box
                                                key={`${chats._id}-${index}`}
                                                sx={{
                                                    height: '10vh',
                                                    marginX: '1px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    borderBottom: '1px solid #345f3c',
                                                    padding: '0 20px',
                                                    cursor: 'pointer',
                                                    backgroundColor: selectedUserId === chats.participants._id ? '#2e5239' : 'transparent',
                                                    '&:hover': {
                                                        backgroundColor: '#2e5239',
                                                    },
                                                    // backgroundColor: (
                                                    //     // (profData && chats.participants.some(participant => participant._id === profData._id)) || // Auto-select based on profData
                                                    //     selectedUserId === chats.participants.find(participant => participant._id !== user._id)?._id
                                                    // ) ? '#2e5239' : 'transparent',
                                                    // '&:hover': {
                                                    //     backgroundColor: '#2e5239',
                                                    // },
                                                }}
                                                onClick={() => handleUserClick(chats.participants._id, chats._id, chats.unreadCount)}
                                            >

                                                <React.Fragment key={`${chats.participants._id}-${index}`}>
                                                    <StyledBadge
                                                        status={chats.participants.status}
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        variant="dot"
                                                        sx={{ mr: 2, marginRight: '20px' }}
                                                    >
                                                        <MaterialAvatar
                                                            sx={{ width: '40px', height: '40px' }}
                                                            src={chats.participants?.avatar && chats.participants?.avatar.url}
                                                            elevation={2}
                                                        />
                                                    </StyledBadge>

                                                    <Box sx={{ width: '75%' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Typography>
                                                                {(chats.participants.role !== 'student' && chats.participants.role !== 'CO') && chats.participants.title && `${chats.participants.title} `} {truncateText(chats.participants.username, 20)}
                                                            </Typography>
                                                            <Typography>
                                                                {(chats.participants.role === 'student' || chats.participants.role === 'CO') && chats.participants.coYeSe && ` ${chats.participants.coYeSe}`}
                                                            </Typography>
                                                        </div>

                                                        {/* latest message */}
                                                        {chats.latestMessage && (
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Typography variant="body2" sx={{ color: '#ffffff' }}>
                                                                    {chats?.latestMessage?.sender === user._id
                                                                        ? `You: ${truncateText(chats.latestMessage.message, 20)}`
                                                                        : chats.latestMessage.seen
                                                                            ? `${truncateText(chats.latestMessage.message, 20)}`
                                                                            : <strong>{truncateText(chats.latestMessage.message, 20)}</strong>}
                                                                </Typography><Typography variant="body2" sx={{ color: '#ffffff' }}>
                                                                    {chats?.latestMessage?.sender === user._id
                                                                        ? minimalDate(chats.latestMessage.sentAt)
                                                                        : chats.latestMessage.seen
                                                                            ? minimalDate(chats.latestMessage.sentAt)
                                                                            : <strong> {minimalDate(chats.latestMessage.sentAt)}</strong>}
                                                                </Typography></div>
                                                        )}

                                                    </Box>
                                                    <Badge badgeContent={chats.unreadCount} color="info" sx={{ marginLeft: 'auto' }} />
                                                </React.Fragment>

                                            </Box>


                                        ))}

                                    </Box>
                                </Box>
                            </Grid>

                            {/* Second Column */}
                            <Grid item xs={7}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        backgroundColor: '#c8ecc8',
                                        color: '#fff',
                                        height: '100%',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {conversationId === 'none' && profData && (
                                        // Render content only when convo does not exist and profData exists
                                        <Box
                                            key={`selected-professor`}
                                            sx={{
                                                display: 'flex',
                                                backgroundColor: '#fff',
                                                color: '#000',
                                                height: '10%',
                                                alignItems: 'center',
                                                position: 'relative',
                                            }}
                                        >
                                            <React.Fragment key={`${selectedUserId}-unique2`}>
                                                <StyledBadge
                                                    status={profData.status}
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    variant="dot"
                                                    sx={{ mr: 2, margin: '20px' }}
                                                >
                                                    <MaterialAvatar
                                                        sx={{ width: '40px', height: '40px' }}
                                                        src={profData.avatar && profData.avatar.url}
                                                        elevation={2}
                                                    />
                                                </StyledBadge>
                                                <Box>
                                                    <Typography> {profData.role !== 'student' && profData.title && `${profData.title} `} {profData.username}</Typography>
                                                    <Typography variant="body2" sx={{ color: '#303030' }}>
                                                        {profData.status}
                                                    </Typography>
                                                </Box>
                                            </React.Fragment>

                                        </Box>
                                    )}

                                    {/* chat participant details */}
                                    {uconvo.length > 0 ? (
                                        uconvo
                                            .filter(ichats => ichats._id === conversationId)
                                            .map((chats) => (
                                                <Box
                                                    key={`${chats._id}-outer`}
                                                    sx={{
                                                        display: 'flex',
                                                        backgroundColor: '#fff',
                                                        color: '#000',
                                                        height: '10%',
                                                        alignItems: 'center',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    {chats.participants._id === selectedUserId
                                                        && // Add this check to handle potential undefined
                                                        <React.Fragment key={chats.participants._id + chats._id}>
                                                            <StyledBadge
                                                                status={chats.participants.status}
                                                                overlap="circular"
                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                variant="dot"
                                                                sx={{ mr: 2, margin: '20px' }}
                                                            >
                                                                <MaterialAvatar
                                                                    sx={{ width: '40px', height: '40px' }}
                                                                    src={chats.participants.avatar && chats.participants.avatar.url}
                                                                    elevation={2}
                                                                />
                                                            </StyledBadge>
                                                            <Box>
                                                                <Typography>{chats.participants.username}</Typography>
                                                                <Typography variant="body2" sx={{ color: '#303030' }}>
                                                                    {chats.participants.status}
                                                                </Typography>
                                                            </Box>
                                                        </React.Fragment>
                                                    }

                                                    {/* Handle the case where there is no participant with selectedUserId */}
                                                    {conversationId === 'none' && (
                                                        // Render details of selected user (profData)
                                                        profData && // Add this check to handle potential undefined
                                                        <React.Fragment key={`${selectedUserId}-unique`}>
                                                            <StyledBadge
                                                                status={profData.status}
                                                                overlap="circular"
                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                variant="dot"
                                                                sx={{ mr: 2, margin: '20px' }}
                                                            >
                                                                <MaterialAvatar
                                                                    sx={{ width: '40px', height: '40px' }}
                                                                    src={profData.avatar && profData.avatar.url}
                                                                    elevation={2}
                                                                />
                                                            </StyledBadge>
                                                            <Box>
                                                                <Typography>{profData.username}</Typography>
                                                                <Typography variant="body2" sx={{ color: '#303030' }}>
                                                                    {profData.status}
                                                                </Typography>
                                                            </Box>
                                                        </React.Fragment>
                                                    )}
                                                </Box>
                                            ))
                                    ) : (<></>)
                                        // :(<Box sx={{ height: '100%', color: '#000', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        //     <ForumIcon sx={{ fontSize: '200px', color: alpha('#000', 0.4) }} />
                                        //     <Typography variant="body1" sx={{ marginTop: '10px', fontWeight: 'bold', color: '#454545' }}>
                                        //         No conversations yet
                                        //     </Typography>
                                        // </Box>)
                                    }

                                    {/* messages */}
                                    <Box
                                        ref={messagesBoxRef}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start', // Align sender's messages to the right
                                            gap: '10px',
                                            // overflowY: 'auto', // Enable vertical scrolling
                                            maxHeight: '420px',
                                            height: '100%'
                                        }}
                                    >
                                        {conversationId !== 'none' && umessages?.messages?.length > 0 ? (

                                            <VirtualizedMessageList
                                                umessages={umessages}
                                                user={user}
                                                uconvo={uconvo}
                                                selectedUserId={selectedUserId}
                                                messagesBoxRef={messagesBoxRef}
                                                isLoading={isLoading}
                                            />

                                            //   umessages.messages.slice().reverse().map((message) => (  // Use slice() to create a copy of the array and then reverse it
                                            //   <Box
                                            //       key={message._id}
                                            //       sx={{
                                            //           display: 'flex',
                                            //           alignItems: 'flex-end',
                                            //           alignSelf: message.sender === user._id ? 'flex-end' : 'flex-start',
                                            //       }}
                                            //   >
                                            //       {message.sender !== user._id && (
                                            //           <MaterialAvatar
                                            //               sx={{ width: '30px', height: '30px', marginTop: '5px', marginX: '10px' }}
                                            //               src={uconvo.find(convo => convo._id === selectedUserId)?.participants.find(participant => participant._id === message.sender)?.avatar?.url}
                                            //               alt={message.sender}
                                            //           />
                                            //       )}
                                            //       <Tooltip title={formattedSentAt(message.sentAt)} placement={message.sender === user._id ? 'top-end' : 'top-start'}>
                                            //           <Box
                                            //               bgcolor={message.sender === user._id ? '#fff' : '#345f3c'}
                                            //               color={message.sender === user._id ? '#000' : '#fff'}
                                            //               borderRadius="10px"
                                            //               padding="10px"
                                            //               marginRight={message.sender === user._id ? '15px' : '150px'}
                                            //               marginLeft={message.sender === user._id ? '150px' : '0px'}
                                            //               width="fit-content"
                                            //               maxWidth="70%"
                                            //               whiteSpace="pre-wrap"
                                            //           >
                                            //               <Typography variant="body1">{message.message}</Typography>
                                            //               <Typography variant="caption" sx={{
                                            //                   color: '#808080', marginTop: '5px',
                                            //                   display: 'flex',
                                            //                   alignItems: 'flex-end',
                                            //                   justifyContent: message.sender === user._id ? 'flex-end' : 'flex-start',
                                            //               }}>
                                            //                   {minimalDate(message.sentAt)}
                                            //               </Typography>
                                            //           </Box>
                                            //       </Tooltip>
                                            //   </Box>
                                            //   ))

                                        ) : (
                                            <Box sx={{ height: '100%', color: '#000', display: 'flex', flexDirection: 'column', margin: 'auto', alignItems: 'center' }}>
                                                <ForumIcon sx={{ fontSize: '200px', color: alpha('#000', 0.4) }} />
                                                <Typography variant="body1" sx={{ marginTop: '10px', fontWeight: 'bold', color: '#454545' }}>
                                                    No conversations yet
                                                </Typography>
                                            </Box>

                                        )}
                                    </Box>



                                    {/* Message Input */}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            backgroundColor: '#fff',
                                            color: '#fff',
                                            height: '8%',
                                            alignContent: 'flex-end',
                                            position: 'relative',
                                            width: '100%'
                                        }}
                                    >
                                        <form onSubmit={handleSubmit(submitHandler)}>
                                            <TextField
                                                placeholder="Write your message..."
                                                fullWidth

                                                size="small"
                                                multiline
                                                focused={false}
                                                maxRows={1}
                                                variant="outlined"
                                                InputProps={{
                                                    sx: {
                                                        height: '7vh',// Center the icon vertically
                                                        paddingRight: '50px',
                                                        color: 'rgba(0, 0, 1)',
                                                        '&:hover': {
                                                            borderBottom: 'none',
                                                        },

                                                        '& .MuiOutlinedInput-root': {
                                                            border: 'none',
                                                        },

                                                    },
                                                }}

                                                value={message}
                                                onChange={messageHandleChange}
                                            />
                                            <IconButton
                                                type='submit'
                                                sx={{
                                                    position: 'absolute',
                                                    right: '0px', // Adjust the value as needed for the right spacing
                                                    bottom: '50%', // Center the icon vertically
                                                    transform: 'translateY(50%)', // Center the icon vertically
                                                    cursor: 'pointer',
                                                    color: '#fff',
                                                    backgroundColor: "#014422",
                                                    borderRadius: 0,
                                                    width: '50px',
                                                    height: '7vh',// Center the icon vertically
                                                    '&:hover': {
                                                        backgroundColor: "#345f3c", // Change the background color on hover
                                                    },
                                                }}
                                            >
                                                <SendIcon />
                                            </IconButton>
                                        </form>
                                    </Box>

                                </Box>
                            </Grid>
                        </Grid>


                    </Paper>
                </Box>
            {/* </Box> */}
            {/* for small screens */}

            {/* <Box sx={{ display: { xs: "flex", md: "none" } }}> */}
                <Box
                    sx={{
                        // height: '100vh', 
                        justifyContent: 'center',  // Horizontally center
                        alignContent: 'center',      // Vertically center
                        display: { xs: "flex", md: "none" },
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1,
                            width: '100%',
                            height: '100%',
                        },
                        position: 'static'

                    }}
                >
                    <Paper elevation={12} >
                        <Grid container columns={12} sx={{ height: '100%' }}>
                            {/* First Column */}
                            <Grid item xs={2} >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        backgroundColor: '#014422',
                                        color: '#fff',
                                        height: '100%',
                                    }}
                                >
                                    <Box sx={{ height: '15vh',  display: 'flex', justifyContent: 'center' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <a href="#" className="bdr-ripple-ani-btn green two">
                                                <MaterialAvatar
                                                    sx={{ width: '48px', height: '48px', border: '1px solid #44b700', borderRadius: '50%' }}
                                                    className='elevated-avatar'
                                                    src={user.avatar && user.avatar.url}
                                                    elevation={24}
                                                />
                                            </a>
                                        </Box>

                                    </Box>
                                    

                                    {/*=========== user list section ============ */}
                                    <Box
                                        sx={{
                                            height: 'calc(100vh - 41vh)', // Adjust the height as needed
                                            overflowY: 'auto', // Enable vertical scrolling when content exceeds the container height
                                        }}
                                    >

                                        {/* when the user has no history of conversation with the prof they clicked on the sidebar section */}
                                        {profData && !uconvo.some(chats => chats.participants._id === profData._id) && (
                                            <Box
                                                key={`${profData._id}-unq`}
                                                sx={{
                                                    height: '10vh',
                                                    marginX: '1px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    borderBottom: '1px solid #345f3c',
                                                    padding: '0 30px',
                                                    cursor: 'pointer',
                                                    backgroundColor: selectedUserId === profData._id ? '#2e5239' : 'transparent',
                                                    '&:hover': {
                                                        backgroundColor: '#2e5239',
                                                    },
                                                }}
                                                onClick={() => handleUserClick(profData._id, 'none', 0)}
                                            >
                                                <StyledBadge
                                                    status={profData.status}
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    variant="dot"
                                                    sx={{ justifyContent: 'center', marginRight: '20px' }}
                                                >
                                                    <MaterialAvatar
                                                        sx={{ width: '40px', height: '40px'}}
                                                        src={profData.avatar && profData.avatar.url}
                                                        elevation={2}
                                                    />
                                                </StyledBadge>
                                            </Box>
                                        )}


                                        {/* fetch user conversations, if the they already ahve a conversation with the prof he clicked on the sidebar, it is automatically highlighted/selected */}
                                        {uconvo.map((chats, index) => (

                                            <Box
                                                key={`${chats._id}-${index} convo`}
                                                sx={{
                                                    height: '8vh',
                                                    // marginX: '1px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderBottom: '1px solid #345f3c',
                                                    // padding: '0 20px',
                                                    cursor: 'pointer',
                                                    backgroundColor: selectedUserId === chats.participants._id ? '#2e5239' : 'transparent',
                                                    '&:hover': {
                                                        backgroundColor: '#2e5239',
                                                    },
                                                }}
                                                onClick={() => handleUserClick(chats.participants._id, chats._id, chats.unreadCount)}
                                            >

                                                <React.Fragment key={`${chats.participants._id}-${index} convo1`}>
                                                    <StyledBadge
                                                        status={chats.participants.status}
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        variant="dot"
                                                        sx={{ justifyContent: 'center'}}
                                                    >
                                                        <MaterialAvatar
                                                            sx={{ width: '40px', height: '40px' }}
                                                            src={chats.participants?.avatar && chats.participants?.avatar.url}
                                                            elevation={2}
                                                        />
                                                    </StyledBadge>
                                                    {/* <Badge badgeContent={chats.unreadCount} color="info" sx={{ marginLeft: 'auto' }} /> */}
                                                </React.Fragment>

                                            </Box>


                                        ))}

                                    </Box>
                                </Box>
                            </Grid>

                            {/* Second Column */}
                            <Grid item xs={10}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        backgroundColor: '#c8ecc8',
                                        color: '#fff',
                                        height: '100%',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {conversationId === 'none' && profData && (
                                        // Render content only when convo does not exist and profData exists
                                        <Box
                                            key={`selected-professor1`}
                                            sx={{
                                                display: 'flex',
                                                backgroundColor: '#fff',
                                                color: '#000',
                                                height: '10%',
                                                alignItems: 'center',
                                                position: 'relative',
                                            }}
                                        >
                                            <React.Fragment key={`${selectedUserId}-unq2`}>
                                                <StyledBadge
                                                    status={profData.status}
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    variant="dot"
                                                    sx={{ mr: 2, margin: '20px' }}
                                                >
                                                    <MaterialAvatar
                                                        sx={{ width: '40px', height: '40px' }}
                                                        src={profData.avatar && profData.avatar.url}
                                                        elevation={2}
                                                    />
                                                </StyledBadge>
                                                <Box>
                                                    <Typography> {profData.role !== 'student' && profData.title && `${profData.title} `} {profData.username}</Typography>
                                                    <Typography variant="body2" sx={{ color: '#303030' }}>
                                                        {profData.status}
                                                    </Typography>
                                                </Box>
                                            </React.Fragment>

                                        </Box>
                                    )}

                                    {/* chat participant details */}
                                    {uconvo.length > 0 ? (
                                        uconvo
                                            .filter(ichats => ichats._id === conversationId)
                                            .map((chats) => (
                                                <Box
                                                    key={`${chats._id}-outer1`}
                                                    sx={{
                                                        display: 'flex',
                                                        backgroundColor: '#fff',
                                                        color: '#000',
                                                        height: '10%',
                                                        alignItems: 'center',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    {chats.participants._id === selectedUserId
                                                        && // Add this check to handle potential undefined
                                                        <React.Fragment key={chats.participants._id + chats._id +`unq1`}>
                                                            <StyledBadge
                                                                status={chats.participants.status}
                                                                overlap="circular"
                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                variant="dot"
                                                                sx={{ mr: 2, margin: '20px' }}
                                                            >
                                                                <MaterialAvatar
                                                                    sx={{ width: '40px', height: '40px' }}
                                                                    src={chats.participants.avatar && chats.participants.avatar.url}
                                                                    elevation={2}
                                                                />
                                                            </StyledBadge>
                                                            <Box>
                                                                <Typography>{chats.participants.username}</Typography>
                                                                <Typography variant="body2" sx={{ color: '#303030' }}>
                                                                    {chats.participants.status}
                                                                </Typography>
                                                            </Box>
                                                        </React.Fragment>
                                                    }

                                                    {/* Handle the case where there is no participant with selectedUserId */}
                                                    {conversationId === 'none' && (
                                                        // Render details of selected user (profData)
                                                        profData && // Add this check to handle potential undefined
                                                        <React.Fragment key={`${selectedUserId}-uniquee`}>
                                                            <StyledBadge
                                                                status={profData.status}
                                                                overlap="circular"
                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                variant="dot"
                                                                sx={{ mr: 2, margin: '20px' }}
                                                            >
                                                                <MaterialAvatar
                                                                    sx={{ width: '40px', height: '40px' }}
                                                                    src={profData.avatar && profData.avatar.url}
                                                                    elevation={2}
                                                                />
                                                            </StyledBadge>
                                                            <Box>
                                                                <Typography>{profData.username}</Typography>
                                                                <Typography variant="body2" sx={{ color: '#303030' }}>
                                                                    {profData.status}
                                                                </Typography>
                                                            </Box>
                                                        </React.Fragment>
                                                    )}
                                                </Box>
                                            ))
                                    ) : (<></>)
                                        // :(<Box sx={{ height: '100%', color: '#000', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        //     <ForumIcon sx={{ fontSize: '200px', color: alpha('#000', 0.4) }} />
                                        //     <Typography variant="body1" sx={{ marginTop: '10px', fontWeight: 'bold', color: '#454545' }}>
                                        //         No conversations yet
                                        //     </Typography>
                                        // </Box>)
                                    }

                                    {/* messages */}
                                    <Box
                                        ref={messagesBoxRef}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start', // Align sender's messages to the right
                                            gap: '10px',
                                            // overflowY: 'auto', // Enable vertical scrolling
                                            maxHeight: '420px',
                                            height: '100%'
                                        }}
                                    >
                                        {conversationId !== 'none' && umessages?.messages?.length > 0 ? (

                                            <VirtualizedMessageList
                                                umessages={umessages}
                                                user={user}
                                                uconvo={uconvo}
                                                selectedUserId={selectedUserId}
                                                messagesBoxRef={messagesBoxRef}
                                                isLoading={isLoading}
                                            />

                                            //   umessages.messages.slice().reverse().map((message) => (  // Use slice() to create a copy of the array and then reverse it
                                            //   <Box
                                            //       key={message._id + `msg`}
                                            //       sx={{
                                            //           display: 'flex',
                                            //           alignItems: 'flex-end',
                                            //           alignSelf: message.sender === user._id ? 'flex-end' : 'flex-start',
                                            //       }}
                                            //   >
                                            //       {message.sender !== user._id && (
                                            //           <MaterialAvatar
                                            //               sx={{ width: '30px', height: '30px', marginTop: '5px', marginX: '10px' }}
                                            //               src={uconvo.find(convo => convo._id === selectedUserId)?.participants.find(participant => participant._id === message.sender)?.avatar?.url}
                                            //               alt={message.sender}
                                            //           />
                                            //       )}
                                            //       <Tooltip title={formattedSentAt(message.sentAt)} placement={message.sender === user._id ? 'top-end' : 'top-start'}>
                                            //           <Box
                                            //               bgcolor={message.sender === user._id ? '#fff' : '#345f3c'}
                                            //               color={message.sender === user._id ? '#000' : '#fff'}
                                            //               borderRadius="10px"
                                            //               padding="10px"
                                            //               marginRight={message.sender === user._id ? '15px' : '150px'}
                                            //               marginLeft={message.sender === user._id ? '150px' : '0px'}
                                            //               width="fit-content"
                                            //               maxWidth="70%"
                                            //               whiteSpace="pre-wrap"
                                            //           >
                                            //               <Typography variant="body1">{message.message}</Typography>
                                            //               <Typography variant="caption" sx={{
                                            //                   color: '#808080', marginTop: '5px',
                                            //                   display: 'flex',
                                            //                   alignItems: 'flex-end',
                                            //                   justifyContent: message.sender === user._id ? 'flex-end' : 'flex-start',
                                            //               }}>
                                            //                   {minimalDate(message.sentAt)}
                                            //               </Typography>
                                            //           </Box>
                                            //       </Tooltip>
                                            //   </Box>
                                            //   ))

                                        ) : (
                                            <Box sx={{ height: '100%', color: '#000', display: 'flex', flexDirection: 'column', margin: 'auto', alignItems: 'center' }}>
                                                <ForumIcon sx={{ fontSize: '200px', color: alpha('#000', 0.4) }} />
                                                <Typography variant="body1" sx={{ marginTop: '10px', fontWeight: 'bold', color: '#454545' }}>
                                                    No conversations yet
                                                </Typography>
                                            </Box>

                                        )}
                                    </Box>



                                    {/* Message Input */}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            backgroundColor: '#fff',
                                            color: '#fff',
                                            height: '8%',
                                            alignContent: 'flex-end',
                                            position: 'relative',
                                            // width: "100%"
                                        }}
                                    >
                                        <form onSubmit={handleSubmit(submitHandler)}>
                                            <TextField
                                                placeholder="Write your message..."
                                                size="small"
                                                multiline
                                                focused={false}
                                                maxRows={1}
                                                variant="outlined"
                                                fullWidth
                                                InputProps={{
                                                    sx: {
                                                        height: '6vh',// Center the icon vertically
                                                        paddingRight: '50px',
                                                        
                                                        color: 'rgba(0, 0, 1)',
                                                        '&:hover': {
                                                            borderBottom: 'none',
                                                        },

                                                        '& .MuiOutlinedInput-root': {
                                                            border: 'none',
                                                        },

                                                    },
                                                }}
                                               
                                                value={message}
                                                onChange={messageHandleChange}
                                            />
                                            <IconButton
                                                type='submit'
                                                sx={{
                                                    position: 'absolute',
                                                    right: '0px', // Adjust the value as needed for the right spacing
                                                    bottom: '50%', // Center the icon vertically
                                                    transform: 'translateY(50%)', // Center the icon vertically
                                                    cursor: 'pointer',
                                                    color: '#fff',
                                                    backgroundColor: "#014422",
                                                    borderRadius: 0,
                                                    width: '50px',
                                                    height: '6vh',// Center the icon vertically
                                                    '&:hover': {
                                                        backgroundColor: "#345f3c", // Change the background color on hover
                                                    },
                                                }}
                                            >
                                                <SendIcon />
                                            </IconButton>
                                        </form>
                                    </Box>

                                </Box>
                            </Grid>
                        </Grid>


                    </Paper>
                </Box>
            {/* </Box> */}
        </Fragment>

    );
}
export default Chat