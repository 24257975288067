import * as coord from '../constants/coordinateConstants';
import axios from 'axios';

export const allCoordinates = () => async (dispatch) => {
    try {
    
        dispatch({ type: coord.ALL_COORDINATES_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/coordinates`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: coord.ALL_COORDINATES_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: coord.ALL_COORDINATES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateCoordinate = (coordId, coordData) => async (dispatch) => {
    try {
    
        dispatch({ type: coord.UPDATE_COORDINATE_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/admin/coordinate/${coordId}`, coordData, config)
         
        dispatch({
            type: coord.UPDATE_COORDINATE_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: coord.UPDATE_COORDINATE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getCoordinateDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: coord.COORDINATE_DETAILS_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
       const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/coordinate/${id}`, config)
        dispatch({
            type: coord.COORDINATE_DETAILS_SUCCESS,
            payload: data.data
        })
       
    } catch (error) {
        dispatch({
            type: coord.COORDINATE_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: coord.CLEAR_ERRORS

    })
}