export const ALL_ANNOUNCEMENTS_REQUEST = 'ALL_ANNOUNCEMENTS_REQUEST'
export const ALL_ANNOUNCEMENTS_SUCCESS = 'ALL_ANNOUNCEMENTS_SUCCESS'
export const ALL_ANNOUNCEMENTS_FAIL = 'ALL_ANNOUNCEMENTS_FAIL'

export const ANNOUNCEMENT_DETAILS_REQUEST = 'ANNOUNCEMENT_DETAILS_REQUEST' 
export const ANNOUNCEMENT_DETAILS_SUCCESS = 'ANNOUNCEMENT_DETAILS_SUCCESS'
export const ANNOUNCEMENT_DETAILS_FAIL = 'ANNOUNCEMENT_DETAILS_FAIL'
export const ANNOUNCEMENT_DETAILS_RESET = 'ANNOUNCMENT_DETAILS_RESET'

export const ADMIN_ANNOUNCEMENTS_REQUEST = 'ADMIN_ANNOUNCEMENTS_REQUEST'
export const ADMIN_ANNOUNCEMENTS_SUCCESS = 'ADMIN_ANNOUNCEMENTS_SUCCESS'
export const ADMIN_ANNOUNCEMENTS_FAIL = 'ADMIN_ANNOUNCEMENTS_FAIL'

export const NEW_ANNOUNCEMENT_REQUEST = 'NEW_ANNOUNCEMENT_REQUEST'
export const NEW_ANNOUNCEMENT_SUCCESS = 'NEW_ANNOUNCEMENT_SUCCESS'
export const NEW_ANNOUNCEMENT_RESET = 'NEW_ANNOUNCEMENT_RESET'
export const NEW_ANNOUNCEMENT_FAIL = 'NEW_ANNOUNCEMENT_FAIL'


export const UPDATE_ANNOUNCEMENT_REQUEST = 'UPDATE_ANNOUNCEMENT_REQUEST'
export const UPDATE_ANNOUNCEMENT_SUCCESS = 'UPDATE_ANNOUNCEMENT_SUCCESS'
export const UPDATE_ANNOUNCEMENT_RESET = 'UPDATE_ANNOUNCEMENT_RESET'
export const UPDATE_ANNOUNCEMENT_FAIL = 'UPDATE_ANNOUNCEMENT_FAIL'

export const DELETE_ANNOUNCEMENT_REQUEST = 'DELETE_ANNOUNCEMENT_REQUEST'
export const DELETE_ANNOUNCEMENT_SUCCESS = 'DELETE_ANNOUNCEMENT_SUCCESS'
export const DELETE_ANNOUNCEMENT_RESET = 'DELETE_ANNOUNCEMENT_RESET'
export const DELETE_ANNOUNCEMENT_FAIL = 'DELETE_ANNOUNCEMENT_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

