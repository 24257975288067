import { ArrowBack, KeySharp, Print } from '@mui/icons-material';
import { Avatar, Box, Fab, Grid, IconButton, Modal, Paper, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pie } from 'react-chartjs-2';
// import { allRoomKeys } from '../../actions/roomKeyActions';
import { Bar } from 'react-chartjs-2';
import { getDlvryStats } from '../../actions/StatActions';
import mapSpecialCharactersToTables from './mapCharacterTables';
import Loader from '../layout/loader';
import People from '@mui/icons-material/People';
import { DataGrid } from '@mui/x-data-grid';
import { dGridStyle } from './dataGridStyles';
import { useReactToPrint } from 'react-to-print';
import BarDetails from './BarDetails';
import moment from 'moment';
import Swal from 'sweetalert2';

const DeliveryChart = ({ dashboard }) => {
    const boxStyle = {
        textAlign: "center",
        height: "100%",
        padding: "5px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
    };

    const boxFormat = {
        display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'
    }
    const dispatch = useDispatch();
    const { loading, error, dlvryStat, dlvryReport } = useSelector((state) => state.dlvrystat)
    const [deliveryTime , setDeliveryTime]= useState(null)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    useEffect(() => {
        if (!dlvryStat && !dlvryReport) {
            dispatch(getDlvryStats())
        }
    }, [])

    const rankings = (top, label) => {
        let top10Users ='';
        // // console.log('reqReport', reqReport)
        
        if(label === 'Top 10 Requestors'){
            top?.slice(0, 10).forEach((topUser, i) => {
                if (topUser?.userId?.username) {
                    top10Users += `<div><span key="${i}" style="text-align: left;"> ${i + 1}. ${topUser.userId.username}: ${topUser.numberOfRequests} </span> </div>`;
                }
            });
            // top?.topRequestors?.userId?.username
        } else {
            top?.slice(0, 10).forEach((topUser, i) => {
                if (topUser?.userId?.username) {
                    top10Users += `<span key="${i}"> ${i + 1}. ${topUser.userId.username}: ${topUser.numberOfRequests} </span> <br/>`;
                }
            });

        }

        if (!top10Users){
            top10Users = `No ${label} at the moment.`
        }
     
        Swal.fire({
            title: `${label}`,
            html: `    <div style="display: flex; justify-content: center;">
            <div style="text-align: left;">
                ${top10Users}
            </div>
        </div>`,
            // icon: 'info',
        });
    }
    const generateRequestsPerDayChart = (dlvryStat) => {
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return date.toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' });
        };
      
        const dates = dlvryStat.map(item => formatDate(item.date));
        const failed = dlvryStat.map(item => item.failed);
        const cancelled = dlvryStat.map(item => item.cancelled);
        const success = dlvryStat.map(item => item.success);
      
        const data = {
          labels: dates,
          datasets: [
            {
              label: 'Failed',
              data: failed,
              backgroundColor: '#ed665c',
            },
            {
              label: 'Cancelled',
              data: cancelled,
              backgroundColor: '#f0db5b',
            },
            {
              label: 'Success',
              data: success,
              backgroundColor: '#4ff08d',
            },
          ],
        };
      
        const options = {
            onClick: (event, elements) => {
                if (elements.length > 0) {
                    const element = elements[0];
                    const datasetLabel = data.datasets[element.datasetIndex].label;
                    const value = data.datasets[element.datasetIndex].data[element.index];
                    const label = data.labels[element.index];
                    handleBarDtlsOpen(label, datasetLabel);
                }
            },
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true }],
          },
          aspectRatio: 1, // Adjust this value to change the aspect ratio
        maintainAspectRatio: false, // Set to false to allow the chart to resize based on container size    
        };
      
        return <Bar data={data} options={options} />;
      };
    const data = {
        labels: [
            'success',
            'cancelled',
            'failed',
        ],
        datasets: [
            {
                data: [dlvryReport.totalSuccess, dlvryReport.totalCancelled, dlvryReport.totalFailed],
                backgroundColor: ['#008450', '#efb700', '#b81d13'], // Set color based on availability
                hoverBackgroundColor: ['#005131', '#bc8f00', '#84140d'], // Set hover color based on availability
            },
        ],
    };

    const options = {
        aspectRatio: 1, // Adjust this value to change the aspect ratio
        maintainAspectRatio: false, // Set to false to allow the chart to resize based on container size    
        plugins: {
            legend: {
                position: 'right', // Set the legend position to the right side
            },
        },
    };

    const [modalBarDtlsOpen, setModalBarDtlsOpen] = useState(false);
    const [historyStatus, setHistoryStatus] = useState(null);
    const [outCome, setOutCome] = useState(null);

    const handleBarDtlsOpen = (date, hStatus) => {
        // Parse the input date string to a moment object
        const parsedDate = moment(date, 'MM/DD/YY');
      
        // Filter the main history based on the date and desired outcome
        const filteredHistory = dlvryStat.reduce((acc, history) => {
          // Check if the date matches
          if (moment(history.date).isSame(parsedDate, 'day')) {
            // Extract and filter the deliveryHistory for each requestor
            history.users.requestors.forEach(requestor => {
              const matchedDeliveries = requestor.deliveryHistory.filter(request => request.delOutcome === hStatus);
              if (matchedDeliveries.length > 0) {
                // acc.push(...matchedDeliveries);
                matchedDeliveries.forEach(delivery => {
                    acc.push({
                      ...delivery, // Convert Mongoose document to plain object if needed
                      requestorId: requestor.userId
                    });
                  });
              }
            });
          }
          return acc;
        }, []);
      
        // Update the state with the filtered delivery history
        setHistoryStatus(filteredHistory);
      setOutCome(hStatus)
        // Open the modal
        setModalBarDtlsOpen(true);
      };

    //   useEffect(()=>{
    //     // console.log('History Status Bar Details: ', historyStatus)
    //   },[historyStatus]);
  
    const handleBarDtlsClose = () => {
      setModalBarDtlsOpen(false);
    };

    const columns = [
        { field: "table", headerName: "Table",  flex: 0.5, align: 'center', headerAlign: 'center' },
        { field: "time", headerName: "Average Delivery Time", flex: 0.5, align: 'center', headerAlign: 'center' },
      ];
      
      
    useEffect(() => {
        if (dlvryReport?.averageTimes) {
          const formattedData = Object.entries(dlvryReport.averageTimes).map(([deskLocation, time]) => ({
            id: deskLocation,
            table: mapSpecialCharactersToTables(deskLocation),
            time: time,
          }));
          setDeliveryTime(formattedData);
          // console.log('Delivery Time: ', formattedData)
        // } else {
        //   setDeliveryTime(null); // Set deliveryTime to null if dlvryReport?.averageTimes is null
        }
      }, [dlvryReport]);
      
      
    // const formattedData = deliveryTime.map(([deskLocation, time]) => ({
    //     id: deskLocation, // Use deskLocation as the unique identifier
    //     deskLocation,
    //     averageTime: time,
    //   }));

    return (
        <div >
            {loading ? (
                <Loader />
            ) : error ? (
                <p>Error fetching chart data: {error}</p>
            ) : (
                <div>  <Box ref={componentRef}>
                    <Box className="no-print">
                    <IconButton  sx={{
                        ml: 3, mb: -10,
                        '&:hover': {
                            // Add styles for hover state
                            backgroundColor: 'rgba(0, 0, 0, 0.0)',
                            color: '#49B265',
                            // Add any other styles you want to apply on hover
                        },
                    }}
                        onClick={dashboard}
                    ><ArrowBack sx={{
                        fontSize: 35, color: '#000',
                        '&:hover': {
                            // Add styles for hover state
                            color: '#49B265',
                            // Add any other styles you want to apply on hover
                        },
                    }}></ArrowBack></IconButton></Box>
                   <Box className="d-margin">
                    <div className='thirteen'>
                        <h1>Delivery Reports</h1> </div>
                    <Grid container spacing={8} sx={{mb: 5, mt: 0.1, p: 2}}>
                        <Grid item xs={4}>
                            <Paper sx={boxStyle} className="hoverblue" onClick={()=> rankings(dlvryReport?.topRequestors, 'Top 10 Requestors')}>
                                <Box sx={boxFormat}>
                                    {/* <People color="info" sx={{ fontSize: 60 }} /> */}
                                    <Avatar sx={{width: '70px', height: '70px'}} src={dlvryReport?.topRequestors[0]?.userId?.avatar} />
                                    <Typography variant="button" fontWeight='bold'>Top Delivery Initiator</Typography>
                                    <Typography variant="h6" fontWeight={"bold"}>{dlvryReport?.topRequestors[0]?.userId?.username}</Typography>
                                    <Typography variant="subtitle1"  sx={{color:'#505050'}}>Has had {dlvryReport?.topRequestors[0]?.numberOfRequests} deliveries </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper sx={boxStyle}>
                                <Box sx={boxFormat}>
                                    {/* <People color="info" sx={{ fontSize: 60 }} /> */}
                                    <KeySharp sx={{width: '70px', height: '70px'}}/>
                                    <Typography variant="button" fontWeight='bold'>Most Dispatched Key</Typography>
                                    <Typography variant="h6" fontWeight={"bold"}>Room {dlvryReport?.topRequestedKey[0]?.keyId?.keyNo}</Typography>
                                    <Typography variant="subtitle1"  sx={{color:'#505050'}}>Has been dispatched {dlvryReport?.topRequestedKey[0]?.numberOfRequests} times </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper sx={boxStyle} className="hoverblue" onClick={()=> rankings(dlvryReport?.topRequestees, 'Top 10 Requestees')}>
                                <Box sx={boxFormat}>
                                <Avatar sx={{width: '70px', height: '70px'}} src={dlvryReport?.topRequestees[0]?.userId?.avatar} />
                                    <Typography variant="button" fontWeight='bold'>Prof With The Most Deliveries</Typography>
                                    <Typography variant="h6" fontWeight={"bold"}>{dlvryReport?.topRequestees[0]?.userId?.title} {dlvryReport?.topRequestees[0]?.userId?.username}</Typography>
                                    <Typography variant="subtitle1" sx={{color:'#505050'}}>Has had {dlvryReport?.topRequestees[0]?.numberOfRequests} deliveries </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    {(deliveryTime) && <Box sx={{m:1}}>
                    <DataGrid
        rows={deliveryTime}
        columns={columns}
        pageSizeOptions={[9]}
                //   autoPageSize
                  rowsPerPageOptions={[10, 25, 50]}
        disableSelectionOnClick
        sx={dGridStyle}
      />
                    </Box>}
                    
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                        <Paper elevation={8} sx={{ m: 'auto' }}>
                        <div className='seven'>
                            <h1>Deliveries Status Ratio</h1> </div>
                        <Box sx={{ mx: 'auto', height: '320px' }}>

                            <Pie data={data} options={options} /></Box>
                    </Paper>
                        </Grid>
                        <Grid item xs={6}>
                        <Paper elevation={8} sx={{ m: 'auto'}}>
                        <div className='seven'>
                            <h1>Deliveries Per Day</h1> </div>
                        <Box sx={{  height: '320px', m: 'auto'}}>
{generateRequestsPerDayChart(dlvryStat)}</Box>
                    </Paper>
                            </Grid>
                    </Grid>
                    </Box>
                   </Box>
                   <Modal
        open={modalBarDtlsOpen}
        onClose={handleBarDtlsClose}
        BackdropProps={{
          sx: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } // Customize backdrop style here
        }}
        // sx={{width: '50vh'}}
      >
        <Box sx={{ margin: 'auto', width: '60%', height: '40%', borderRadius: 5}}>
          <BarDetails historyStatus={historyStatus} status={outCome}/> {/* Render the NotifsSection component inside the modal */}
        </Box>
      </Modal>
                    <Tooltip title="Print Delivery Reports" placement="top">
          <Fab
            color="success"
            aria-label="filter"
            onClick={handlePrint}
            sx={{ position: "fixed", top: 500, right: 40, zIndex: "20" }}
          >
            <Print />
          </Fab>
          </Tooltip>
                   
                </div>
            )}
            <br/>
        </div>
    );
};

export default DeliveryChart;
