// MyCarousel.js
import React, { useEffect, useRef } from 'react';
import '../../App.css'
import Carousel from 'react-material-ui-carousel'
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';

const MyCarousel = () => {
    const caption = 'RAD4';
    const items = [
        { image: '/images/landingpage1.png'},
        { image: '/images/landingpage2.png'},
        { image: '/images/landingpage3.png'},
      ];

      const handleAppDownload = () => {
        Swal.fire({
          title: 'DOWNLOAD THE RAD4 APP',
          imageUrl: '/RAD4QR.png', // Replace with your image URL
          imageAlt: 'Download RAD4 App',
          html: `
            <p>Scan the QR code or click the link below to download the app:</p>
            <a href="https://www.mediafire.com/folder/oq6y0xvps78mc/RAD4+MOBILE" target="_blank" rel="noopener noreferrer">Download RAD4 App</a>
          `,
          showCloseButton: true,
        });
      };

  return (
    <Carousel indicators={false}>
      {items.map((item, index) => (
        <div key={index} style={{ position: 'relative', height: '100vh' }} className='carousel-container'>
          <a href={item.link}>
          <img
                src={item.image}
                alt={`carousel-item-${index}`}
                className='darken-image'
              />
            <div className='header-content' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Typography className='underline-title' color="inherit" align="center" variant="h2" marked="center"  sx={{
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
      }}>
                RAD4
              </Typography>
              <Typography
                color="inherit"
                align="center"
                variant="h5"
                sx={{ mb: 4, mt: { xs: 4, sm: 10 },   textShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)', }}
              >
                <em>Optimizing Request and Delivery of Educational Resources</em>
              </Typography>
<Grid container spacing={4} direction={"oolumn"}>
  <Grid item sm={12}>
              <Button variant="outlined" href={'/login'}color="inherit" size="large" sx={{borderWidth: '2px',  '&:hover': {
                    backgroundColor: '#014323',
                    borderColor: '#08b461', // Set your desired hover background color
                    color: '#fff', // Set your desired hover text color
                  },}}>
                Get Started
              </Button>
              </Grid>
              <Grid item sm={12}>
              <Button variant="outlined" color="inherit" size="large" sx={{borderWidth: '2px',  '&:hover': {
                    backgroundColor: '#014323',
                    borderColor: '#08b461', // Set your desired hover background color
                    color: '#fff', // Set your desired hover text color
                  },}}
                  onClick={handleAppDownload}
                  >
                Try Our Android App
              </Button>
              </Grid>
              </Grid>
            </div>
          </a>
        </div>
      ))}
    </Carousel>
  );
};

export default MyCarousel