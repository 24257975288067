import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../actions/userActions'
import Checkbox from '@mui/material/Checkbox';
import { useForm, Controller } from "react-hook-form";
import { setUserProfile, clearErrors } from '../../actions/userActions'
import { pBtnColor } from '../styles/paabot-styles'
import { allCourses } from '../../actions/courseActions';
import Swal from 'sweetalert2'


import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  Modal,
  Typography
} from '@mui/material';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';


const FirstLogin = () => {
  const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm({
    defaultValues: {
      title: '',
    },
  });
  const { courses } = useSelector(state => state.allCourses)

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [open, setOpen] = useState(false);
  const { user } = useSelector(state => state.auth)
  const dispatch = useDispatch();
  const logoutHandler = () => {

    dispatch(logout());
  }
  useEffect(() => {
    dispatch(allCourses()); // This line triggers the allCourses action
  }, [dispatch]);
  const [role, setRole] = useState('');
  const [program, setProgram] = useState('');
  const [coYeSe, setCoYeSe] = useState('');

  // const [title, setTitle] = useState('');

  const rolesHandleChange = (event) => {
    setRole(event.target.value);
    setCoYeSe('');
  };

  const programHandleChange = (event) => {
    setProgram(event.target.value);
  };

  const cysHandleChange = (event) => {
    setCoYeSe(event.target.value);
  };

  // const onChange = e => {
  //         setUser({ ...user, [e.target.name]: e.target.value })
  // }

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }
  const submitHandler = (data, event) => {
    try {
      const formData = new FormData();
      formData.set('title', data.title);
      formData.set('role', role);
      formData.set('coYeSe', coYeSe);

      // Assuming `setUserProfile` returns a promise
      dispatch(setUserProfile(formData));

      // If dispatch is successful, show success message and close modal
      Swal.fire({
        title: 'Sign up Successful!',
        text: 'You may log in once we have verified your account.',
        icon: 'success',
      });

      // Close the modal (adjust this part based on how your modal is managed)
      handleClose(null, 'success');
      dispatch(logout());
    } catch (error) {
      // Handle errors here
      console.error('Error during user profile update:', error);

      // Show an error message using SweetAlert2 or any other notification library
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred during sign up. Please try again.',
        icon: 'error',
      });
    }
  }
  // Use useEffect to open the modal on component mount
  useEffect(() => {
    setOpen(true);
  }, []); // Empty dependency array ensures it runs only once on mount

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '4px solid #08b461',
    borderRadius: 7,
    boxShadow: 24,
    p: 4,
    display: 'flex', flexDirection: 'column', alignItems: 'center',
  };

  const handleRadioClick = (value) => {
    // console.log(value);
  };

  const bodyStyle = open ? { overflow: 'hidden' } : { overflow: 'auto' };
  return (

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"


    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Almost There, {user.username}!
        </Typography>
        <Typography id="modal-modal-description" sx={{ mb: 2, fontSize: 12 }}>
          Please fill in additional information to continue with your sign up.
        </Typography>
        <form onSubmit={handleSubmit(submitHandler)}>

          <FormControl component="fieldset" sx={{ alignItems: 'center' }}
            error={!!errors.title}

          >
            <Typography>
              <em>What is your biological sex?</em>
            </Typography>
            <Controller
              name="title"
              control={control}
              defaultValue=""
              rules={{ required: 'This section is required!' }}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  // value={title}
                  // // {...register("title", { required: "This section cannot be empty." })}
                  //   onChange={(e) => setTitle(e.target.value)}

                  //  onChange={titleHandleChange}
                  // {...register("title", { required: "This section is required!" })}
                  row>
                  <FormControlLabel
                    value="Sir"
                    control={<Radio onClick={() => handleRadioClick('Sir')} icon={<MaleIcon sx={{ fontSize: 40 }} />} checkedIcon={<MaleIcon sx={{ color: '#1167b1', fontSize: 40 }} />} />}
                    label="Male"
                    labelPlacement="bottom"
                    sx={{
                      border: `2px solid ${field.value === 'Sir' ? '#1167b1' : '#626465'}`,
                      borderRadius: 2,
                      padding: 1,
                      textAlign: 'center',
                      //   borderColor: '#626465',
                      width: 100,
                      '&:hover': {
                        borderColor: '#1167b1',
                        '& .MuiSvgIcon-root': {
                          color: '#1167b1',
                        },
                      },
                    }}
                  />
                  <FormControlLabel
                    value="Ma'am"
                    control={<Radio onClick={() => handleRadioClick("Ma'am")} icon={<FemaleIcon sx={{ fontSize: 40 }} />} checkedIcon={<FemaleIcon sx={{ color: '#fe036a', fontSize: 40 }} />} />}
                    label="Female"
                    labelPlacement="bottom"
                    sx={{
                      border: `2px solid ${field.value === "Ma'am" ? '#fe036a' : '#626465'}`,
                      borderRadius: 2,
                      padding: 1,
                      textAlign: 'center',
                      width: 100,
                      '&:hover': {
                        borderColor: '#fe036a',
                        '& .MuiSvgIcon-root': {
                          color: '#fe036a',
                        },
                      },
                    }}
                  />
                </RadioGroup>
              )}
            />
            <FormHelperText>{errors.title && <i>{errors.title.message}</i>}</FormHelperText>
          </FormControl>
          <Box sx={{ maxWidth: 300, mx: 2, my: 2 }}>
            <FormControl fullWidth error={!!errors.role}>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                {...register("role", { required: "Please select a role." })}
                value={role}
                label="Role"
                onChange={rolesHandleChange}
              >
                <MenuItem value={'student'}>Student</MenuItem>
                <MenuItem value={'CO'}>Class Officer</MenuItem>
                <MenuItem value={'faculty'}>Professor</MenuItem>
              </Select>
              <FormHelperText>{errors.role && <i>{errors.role.message}</i>}</FormHelperText>
            </FormControl>
          </Box>
          {role === 'student' && (
            <><Box sx={{ maxWidth: 300, mx: 2, my: 2 }}>
              <FormControl fullWidth error={!!errors.program}>
                <InputLabel id="program-select">Program</InputLabel>
                <Select
                  labelId="program-select"
                  id="program"
                  {...register("program", { required: "Program is required!" })}
                  value={program}
                  label="Select a Program"
                  onChange={programHandleChange}
                >
                  {courses.map((course) => (
                    <MenuItem key={course._id} value={course.course}>
                      {course.course}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.program && <i>{errors.program.message}</i>}</FormHelperText>
              </FormControl>
            </Box>
              {program && (
                <Box sx={{ maxWidth: 300, mx: 2, my: 2 }}>
                  <FormControl fullWidth error={!!errors.coYeSe}>
                    <InputLabel id="coYeSe-select">Course Yr. Section</InputLabel>
                    <Select
                      labelId="coYeSe-select"
                      id="coYeSe"
                      {...register("coYeSe", { required: "Course Year and Section is required!" })}
                      value={coYeSe}
                      label="Select Course Year Section"
                      onChange={cysHandleChange}
                    >
                      {courses
                        .find((course) => course.course === program) // Filter the courses based on the selected program
                        ?.sectionLabels.map((label) => (
                          <MenuItem key={label} value={label}>
                            {label}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errors.coYeSe && <i>{errors.coYeSe.message}</i>}</FormHelperText>
                  </FormControl>
                </Box>

              )}
            </>
          )}
          <Box sx={{ maxWidth: 500, mx: 2, mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button variant='contained' type="submit" sx={{ mt: 2, ...pBtnColor }} >
              CONTINUE
            </Button>
            <Button onClick={logoutHandler} sx={{ mt: 2 }}>
              MAYBE LATER
            </Button>
          </Box>
        </form>

      </Box>
    </Modal>
  );
}

export default FirstLogin