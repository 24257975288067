import React, { Fragment, useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import Loader from '../layout/loader'
import MetaData from '../layout/MetaData'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { pBtnColor } from '../styles/paabot-styles'
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Button, Typography, TextField, Box, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import { login, clearErrors, glogin, loadUser, activeProfs } from '../../actions/userActions'
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { useForm } from "react-hook-form";
import GoogleIcon from '@mui/icons-material/Google';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Login = () => {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const googlelogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
  });
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate()
  let location = useLocation();
  const { user:authUser, isAuthenticated, error, loading } = useSelector(state => state.auth);
  // const redirect = location.search ? location.search.split('=')[1] : ''
  const redirect = new URLSearchParams(location.search).get('redirect')
  const notify = (error) => toast.error(error, {
    position: toast.POSITION.BOTTOM_CENTER
  })

  const notifySuccess = (message) => toast.success(message, {
    icon: () => <AccountCircleIcon color='success' />,
    position: toast.POSITION.TOP_LEFT
  });

  useEffect(
    () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            dispatch(glogin(res.data))
          })
          .catch((err) => {
            // console.log('this is err: ', err)

            if ((error != 'Login first to access this resource.') &&  (error != 'User ID is missing')) {
              notify(error)
            }

          });
      }
    },
    [user]
  );
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
      // console.log('user details asdfag:', authUser)
      if (authUser.isNewUser == false) {
        notifySuccess(`Welcome ${authUser.username}`)
        dispatch(loadUser())
        // dispatch(activeProfs())
      }
      else {
        notifySuccess('Registration Successful!')
      }

    }
    if (error) {
      // alert.error(error);
      // // console.log(error)
      if (error != 'User ID is missing') {
        notify(error)
      }

      dispatch(clearErrors());
    }
  }, [dispatch, isAuthenticated, error, navigate, redirect])

  const submitHandler = (data, event) => {
    event.preventDefault();

    dispatch(login(email, password))
    // 

  }

  return (
  
    <Box>

    
   
          <Box>
            <MetaData title={'Login'} />
            <Grid container justifyContent="center">
              <Grid item xs={10} lg={5} my={5}>
                <Paper elevation={12} sx={{ padding: 3 }}>
                  <form className="shadow-lg" onSubmit={handleSubmit(submitHandler)}>
                    <Typography variant="h4" sx={{ marginBottom: '70px', marginTop: '-30px' }}>
                      
                      <div className='thirteen'>
                        <h1>Log In</h1>

                      </div>
                      
                      </Typography>

                    <Box mb={3}>
                      <TextField
                        id="email_field"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        {...register("email", {
                          required: "Email field cannot be empty.",
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message: "Invalid Email format!"
                          }
                        })}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!errors.email}
                        helperText={errors.email && <i>{errors.email.message}</i>}
                      />
                    </Box>

                    <Box mb={3}>
                      <TextField
                        type="password"
                        id="password_field"
                        label="Password"
                        variant="outlined"
                        fullWidth
                        {...register("password", { required: "Password is required!" })}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={!!errors.password}
                        helperText={errors.password && <i>{errors.password.message}</i>}
                      />
                    </Box>

                    <div className='float-right mb-4'>
                      <Link to="/password/forgot">Forgot Password?</Link>
                    </div>
                    <br></br>
                    <Button
                      className="login_button"
                      type={loading ? 'button' : 'submit'}
                      // type="submit"
                      variant='contained'
                      fullWidth
                      sx={{ py: 3, ...pBtnColor }}
                      // disabled={!loading}
                    >
          
                     {loading ? <CircularProgress sx={{color: '#fff'}} size={20} /> : `Log In` } 
                    </Button>

                    <Button
                      className="login_button"
                      type="button"
                      variant='contained'
                      fullWidth
                      sx={{ py: 3, mt: 2, ...pBtnColor }}
                      onClick={!loading ? googlelogin : null}
                      disabled={loading}
                    >
                      <GoogleIcon sx={{ color: '#FFF', mr: 2 }} /> LOG IN WITH GOOGLE
                    </Button>
                  </form>
                  <p>Don't have an account yet?  <Link to="/register" className='mx-4'>Register Now</Link></p>
                </Paper>
              </Grid>
            </Grid>


          </Box>
      

    </Box>
  )
}

export default Login