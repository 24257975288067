import * as rk from '../constants/roomKeyConstants';

export const allRoomKeysReducer = (state = { roomKeys: [] }, action) => {
  switch (action.type) {
    case rk.ALL_ROOMKEYS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case rk.ALL_ROOMKEYS_SUCCESS:
      return {
        ...state,
        loading: false,
        roomKeys: action.payload,
      };
    case rk.ALL_ROOMKEYS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case rk.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  success: null,
  isDeleted: null,
  roomKey: {},
  isUpdated: null,
  error: null,
};

export const roomKeyReducer = (state = initialState, action) => {
  switch (action.type) {
    // New Bug Report
    case rk.NEW_ROOMKEY_REQUEST:
    case rk.UPDATE_ROOMKEY_REQUEST:
    case rk.DELETE_ROOMKEY_REQUEST:
      case rk.ROOMKEY_DETAILS_REQUEST:
      return { ...state, loading: true, success: null, error: null };

    case rk.NEW_ROOMKEY_SUCCESS:
      return { ...state, loading: false, success: action.payload, error: null };

    case rk.ROOMKEY_DETAILS_SUCCESS:
      return { ...state, loading: false, roomKey: action.payload, error: null };

    case rk.UPDATE_ROOMKEY_SUCCESS:
      return { ...state, loading: false, isUpdated: action.payload, error: null };

    case rk.NEW_ROOMKEY_FAIL:
    case rk.UPDATE_ROOMKEY_FAIL:
    case rk.DELETE_ROOMKEY_FAIL:
    case rk.ROOMKEY_DETAILS_FAIL:
      return { ...state, loading: false, success: null, error: action.payload };
    case rk.CLEAR_ERRORS:
      return {
        ...state,
        error: null
      }
    case rk.DELETE_ROOMKEY_RESET:
      return {
        ...state,
        isDeleted: false
      }
      case rk.NEW_ROOMKEY_RESET:
      return {
        ...state,
        success: false
      }

      case rk.ROOMKEY_DETAILS_RESET:
        return {
            ...state,
            loading: false,
            roomKey: {}
        };

        case rk.UPDATE_ROOMKEY_RESET:
          return {
              ...state,
              loading: false,
             isUpdated: false,
          };

    case rk.DELETE_ROOMKEY_SUCCESS:
      return { ...state, loading: false, isDeleted: action.payload, error: null };
    default:
      return state;
  }
};
