import axios from "axios";
import {
  FETCH_CHART_DATA_REQUEST,
  FETCH_CHART_DATA_SUCCESS,
  FETCH_CHART_DATA_FAILURE,
  FETCH_BUG_REPORTS_REQUEST,
  FETCH_BUG_REPORTS_SUCCESS,
  FETCH_BUG_REPORTS_FAILURE,
} from "../constants/chartConstants";

export const fetchChartUserData = () => async (dispatch) => {
  dispatch({ type: FETCH_CHART_DATA_REQUEST });

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/userChart`,
      {
        withCredentials: true,
      }
    );

    const processedData = processChartData(data);

    dispatch({
      type: FETCH_CHART_DATA_SUCCESS,
      payload: processedData,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CHART_DATA_FAILURE,
      payload: error.message,
    });
  }
};

// Helper function to process the received data
const processChartData = (data) => {
  // Extract unique roles
  const uniqueRoles = [
    ...new Set(
      data.flatMap((entry) => entry.datasets.map((dataset) => dataset.role))
    ),
  ];

  // Initialize a data structure to store role counts for each date
  const roleCountsByDate = {};

  // Process data to aggregate counts for each role and date
  data.forEach((entry) => {
    const dateKey = new Date(entry.timestamp).toLocaleDateString(undefined, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });

    if (!roleCountsByDate[dateKey]) {
      roleCountsByDate[dateKey] = {};
      uniqueRoles.forEach((role) => {
        roleCountsByDate[dateKey][role] = 0;
      });
    }

    entry.datasets.forEach((dataset) => {
      roleCountsByDate[dateKey][dataset.role] += dataset.count;
    });
  });

  // Sort dates in ascending order
  const sortedDates = Object.keys(roleCountsByDate).sort((a, b) => {
    return new Date(a) - new Date(b);
  });

  // Generate datasets based on role counts
  const datasets = uniqueRoles.map((role) => ({
    label: role,
    data: sortedDates.map((date) => roleCountsByDate[date][role] || 0),
  }));

  return {
    labels: sortedDates,
    datasets: datasets,
  };
};

export const fetchBugReports = () => async (dispatch) => {
  dispatch({ type: FETCH_BUG_REPORTS_REQUEST });

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/bugreportChart`, {
        //AxiosRequestConfig parameter
        withCredentials: true //correct
      });

    const processedData = processBugReportData(data);

    dispatch({
      type: FETCH_BUG_REPORTS_SUCCESS,
      payload: processedData,
    });
  } catch (error) {
    dispatch({
      type: FETCH_BUG_REPORTS_FAILURE,
      payload: error.message,
    });
  }
};

// Helper function to process the received bug report data
const processBugReportData = (data) => {
  // Check if the received data is an object and has a property named "bugReports"
  const bugReportsArray = Array.isArray(data) ? data : data.bugReports || [];

  // Extract unique types
  const uniqueTypes = [
    ...new Set(bugReportsArray.map((report) => report.type)),
  ];

  // Initialize a data structure to store type counts for each date
  const typeCountsByDate = {};

  // Process data to aggregate counts for each type and date
  bugReportsArray.forEach((report) => {
    const dateKey = new Date(report.createdAt).toLocaleDateString(undefined, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });

    if (!typeCountsByDate[dateKey]) {
      typeCountsByDate[dateKey] = {};
      uniqueTypes.forEach((type) => {
        typeCountsByDate[dateKey][type] = 0;
      });
    }

    typeCountsByDate[dateKey][report.type] += 1; // Assuming you want to count each report as 1
  });

  // Sort dates in ascending order
  const sortedDates = Object.keys(typeCountsByDate).sort((a, b) => {
    return new Date(a) - new Date(b);
  });

  // Generate datasets based on type counts
  const datasets = uniqueTypes.map((type) => ({
    label: type,
    data: sortedDates.map((date) => typeCountsByDate[date][type] || 0),
  }));

  return {
    labels: sortedDates,
    datasets: datasets,
  };
};
