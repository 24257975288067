import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loader from '../layout/loader'
import MetaData from '../layout/MetaData'
import { IconButton, Avatar, Button, Card, CardContent, Typography, Box, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import BugReportIcon from '@mui/icons-material/BugReport';
import { red, cyan } from '@mui/material/colors';
import AlarmIcon from '@mui/icons-material/Alarm';
import { Password } from '@mui/icons-material'

const Profile = () => {
    const { user, loading } = useSelector(state => state.auth)
    return (
        <Fragment>
            {loading ? <Loader /> : (
                <Fragment>
                    <MetaData title={'Your Profile'} />
                    <div class="thirteen">
                        <h1>Your Profile</h1>
                    </div>

                    <Box sx={{ paddingY: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Card sx={{ width: 500, height: 'auto', borderRadius: 8 }} elevation={8}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    overflow: 'hidden',
                                    backgroundColor: '#4CAF50',
                                    width: '100%',
                                    height: 320,
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'center', // Add this line
                                }}
                            >
                                <img src={user.avatar.url} alt="User Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />

                                <Box sx={{ position: 'absolute', bottom: 0, textAlign: 'center', width: '100%', textShadow: '0 0 15px white' }}>
                                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                                        {user.username}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ marginTop: -1.8 }} gutterBottom>
                                        <em>{user.status}</em>
                                    </Typography>
                                </Box>
                                <Link to="/me/update">
                                    <IconButton
                                        //   color="success"
                                        aria-label="edit-profile"
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Link>
                            </Box>
                            <CardContent>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                                    <Box sx={{ position: 'relative', marginBottom: 2 }}>
                                        {/* <Avatar alt="User Profile" src={user.avatar.url} sx={{ width: 200, height: 200 }} /> */}
                                        {/* <img src={user.avatar.url} alt="User Profile" style={{ width: '500px', height: '200px',  }} /> */}

                                    </Box>

                                    <Grid container spacing={1} alignItems="center" sx={{ textAlign: 'center' }} >
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1" gutterBottom >
                                                Role
                                            </Typography>
                                            <Typography variant="h6" gutterBottom fontWeight="bold" sx={{ color: '#404040', marginTop: -1 }}>
                                                {user.role}
                                            </Typography>
                                            <Typography variant="subtitle1" gutterBottom >
                                                Joined On
                                            </Typography>
                                            <Typography variant="h6" gutterBottom fontWeight="bold" sx={{ color: '#404040', marginTop: -1 }}>
                                                {String(user.createdAt).substring(0, 10)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1" gutterBottom >
                                                Email
                                            </Typography>
                                            <Typography variant="h6" gutterBottom fontWeight="bold" sx={{ color: '#404040', marginTop: -1 }}>
                                                {user.email}
                                            </Typography>
                                            <Typography variant="subtitle1" gutterBottom  >
                                                Course
                                            </Typography>
                                            <Typography variant="h6" gutterBottom fontWeight="bold" sx={{ color: '#404040', marginTop: -1 }}>
                                                {String(user.coYeSe).substring(0, 10)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, width: '100%' }}>
                                        <Button
                                            // variant="contained"
                                            color="success"
                                            startIcon={<HistoryIcon />}
                                            sx={{
                                                width: '48%',
                                                transition: 'background-color 0.3s',
                                                // '&:hover': {
                                                //     backgroundColor: '#43A047',
                                                // },
                                            }}
                                        >
                                            Delivery History
                                        </Button>
                                        <Button
                                            // variant="contained"
                                            color="success"
                                            startIcon={<BugReportIcon />}
                                            sx={{
                                                width: '48%',
                                                transition: 'background-color 0.3s',
                                                // '&:hover': {
                                                //     backgroundColor: '#43A047',
                                                // },
                                            }}
                                        >
                                            MY Bug Reports
                                        </Button>
                                    </Box> */}
                                    {(user.role === 'faculty' || user.role === 'admin' || user.role === 'tech') && (
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                                            <Link to='/my-scheduled-deliveries'>
                                                <Button
                                                    // variant="contained"

                                                    color="success"
                                                    startIcon={<AlarmIcon />}
                                                    sx={{
                                                        width: '100%',
                                                        transition: 'background-color 0.3s',
                                                        // '&:hover': {
                                                        //     backgroundColor: '#43A047',
                                                        // },
                                                    }}
                                                >
                                                    SCHEDULED DELIVERIES
                                                </Button></Link>
                                        </Box>
                                    )}
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                                        <Link to='/change-password'>
                                            <Button
                                                // variant="contained"

                                                color="success"
                                                startIcon={<Password />}
                                                sx={{
                                                    width: '100%',
                                                    transition: 'background-color 0.3s',
                                                    // '&:hover': {
                                                    //     backgroundColor: '#43A047',
                                                    // },
                                                }}
                                            >
                                                CHANGE PASSWORD
                                            </Button></Link>
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                                        <Link to='/request-history'>
                                            <Button
                                                // variant="contained"

                                                color="success"
                                                startIcon={<HistoryIcon />}
                                                sx={{
                                                    width: '100%',
                                                    transition: 'background-color 0.3s',
                                                    // '&:hover': {
                                                    //     backgroundColor: '#43A047',
                                                    // },
                                                }}
                                            >
                                                TRANSACTION HISTORY
                                            </Button></Link>
                                    </Box>

                                </Box>
                            </CardContent>
                        </Card>
                    </Box>

                </Fragment>

            )}

        </Fragment>

    )

}
export default Profile

