import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

const TermsOfUse = () => {
  let navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  return (

    <Container maxWidth="md" sx={{ my: 4 }}>
      <IconButton sx={{
        
        '&:hover': {
          // Add styles for hover state
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }} onClick={() => {
        goBack()
      }}><ArrowBack sx={{
        fontSize: 35, color: '#000',
        '&:hover': {
          // Add styles for hover state
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }}></ArrowBack></IconButton>
      <Typography variant="h4" fontWeight={'bold'} gutterBottom sx={{display: 'flex', justifyContent: 'center'}}>
        TERMS OF USE
      </Typography>
      
      <Box sx={{ my: 2 }}>
        <Typography variant="h5" gutterBottom>
          For Users:
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing and using this platform, you agree to provide accurate and genuine information, including your name, role, program, and section if applicable. A recognizable profile picture is required to facilitate clear communication and prevent misunderstandings.
        </Typography>
        <Typography variant="body1" paragraph>
          Please refrain from using inappropriate names or pictures when setting up your profile. We encourage users to report any encountered bugs or issues within the system using the designated bug reporting feature, ensuring concise and understandable descriptions.
        </Typography>
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="h5" gutterBottom>
          For Students:
        </Typography>
        <Typography variant="body1" paragraph>
          Students and class officers are expected to maintain updated program and section information in the event of transfers or advancements, ensuring accurate communication and notifications.
        </Typography>
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="h5" gutterBottom>
          For Professors:
        </Typography>
        <Typography variant="body1" paragraph>
          It is your responsibility to set your desk location on the app. Failure to do so will make you ineligible to request or receive a delivery.
        </Typography>
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="h5" gutterBottom>
          Robot Use:
        </Typography>
        <Typography variant="body1" paragraph>
          Wait for the bot to come to your desk before placing your items inside the bot's partition. Press the asterisk button on the bot's keypad to notify it that you have finished placing your items inside the compartment.
        </Typography>
        <Typography variant="body1" paragraph>
          Please refrain from:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Pressing random buttons on the keypad." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Manually moving the bot from its resting position." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Blocking the bot's lidar sensor during delivery." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Pushing, kicking, or hitting the prototype." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Attempting to cut wires or disassemble hardware components." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Pressing the power off button without reason." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Placing liquid materials inside the bot's compartments." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Sitting or riding on the prototype." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Leaving the cabinet door open after retrieving a room key." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Deliberately misplacing room keys." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Stealing hardware components." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Moving the lidar sensor position." />
          </ListItem>
        </List>
        <Typography variant="body1" paragraph>
          It is recommended to charge the robot when its battery level falls below 20%.
        </Typography>
        <Typography variant="body1" paragraph>
          In case of a short circuit or any emergencies, press the bot's emergency switch located at its rear. Avoid troubleshooting the bot yourself and contact the RAD4 team immediately.
        </Typography>
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="h5" gutterBottom>
          For Requests:
        </Typography>
        <Typography variant="body1" paragraph>
          Only authorized users can submit requests to professors who have indicated their desk location and availability status as "active" for request reception.
        </Typography>
        <Typography variant="body1" paragraph>
          Requests should include necessary information and a comprehensive message clarifying the intentions.
        </Typography>
        <Typography variant="body1" paragraph>
          Once a request is submitted, it cannot be rescinded. Users are advised to review requests before submission.
        </Typography>
        <Typography variant="body1" paragraph>
          Professors have the option to specify who receives notifications for requests, either individual students or class representatives.
        </Typography>
        <Typography variant="body1" paragraph>
          Professor requests are automatically granted and queued for delivery. Professors can manage requests from the Requests panel on the home page, choosing to grant, deny, or ignore them.
        </Typography>
        <Typography variant="body1" paragraph>
          Currently, class officers are unable to view their request history.
        </Typography>
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="h6" gutterBottom>
          Request Status:
        </Typography>
        <Typography variant="body1" paragraph>
          Each time a request is granted, denied, or ignored, the system will notify the respective class officer who submitted the request.
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="In queue: Waiting for the professor to take action." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Granted: Professor has approved the request for delivery." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Denied: Professor has denied the request (a reason for denial is required)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Ignored: Request has been in queue for more than an hour." />
          </ListItem>
        </List>
        <Typography variant="body1" paragraph>
          Requests are automatically removed from the queue once they are denied, granted, or ignored.
        </Typography>
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="h5" gutterBottom>
          Delivery:
        </Typography>
        <Typography variant="body1" paragraph>
          A delivery will only take place when the following conditions are met:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="The class officer's request has been granted by the professor." />
          </ListItem>
          <ListItem>
            <ListItemText primary="The professor submits a request." />
          </ListItem>
          <ListItem>
            <ListItemText primary="The professor sets a scheduled delivery." />
          </ListItem>
        </List>
        <Typography variant="body1" paragraph>
          The platform facilitates educational resource delivery with two dedicated partitions.
        </Typography>
        <Typography variant="body1" paragraph>
          Professors have the ability to modify or cancel delivery requests while in the queue.
        </Typography>
        <Typography variant="body1" paragraph>
          Once a delivery is "Ready" or "Ongoing," cancellation can only be done manually through the dedicated cancel button on the platform. Canceled deliveries cannot be reinstated.
        </Typography>
        <Typography variant="body1" paragraph>
          Items should not be placed in the bot's partitions without a corresponding delivery request.
        </Typography>
        <Typography variant="body1" paragraph>
          Students and professors can view their deliveries on the app to monitor their status.
        </Typography>
        <Typography variant="body1" paragraph>
          Users are expected to monitor their deliveries regularly if they have one.
        </Typography>
        <Typography variant="body1" paragraph>
          When you receive your delivery, you are expected to click "CLAIMED" on the delivery sidebar to finalize your transaction. This action informs the bot that your delivery is complete and allows it to serve other deliveries in the queue.
        </Typography>
        <Typography variant="body1" paragraph>
          Failure to click "CLAIMED" will result in a failed delivery. Even if a user successfully retrieves the item but fails to click the 'claimed' button, the system will count it as a failed delivery.
        </Typography>
        <Typography variant="body1" paragraph>
          Users have exactly 5 minutes to click the 'claimed' button before it is automatically marked as a failed delivery.
        </Typography>
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="h6" gutterBottom>
          For Scheduled Deliveries:
        </Typography>
        <Typography variant="body1" paragraph>
          Only faculty members can set scheduled deliveries.
        </Typography>
        <Typography variant="body1" paragraph>
          They can choose whether the scheduled delivery is repeated once, daily, or during a specified weekday.
        </Typography>
        <Typography variant="body1" paragraph>
          Scheduled deliveries will only be executed if they are currently active and the professor's status is set to 'active.' Please ensure your availability accordingly.
        </Typography>
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="h5" gutterBottom>
          Announcements:
        </Typography>
        <Typography variant="body1" paragraph>
          Professors can make announcements on the app to broadcast necessary information, news, and updates to all students.
        </Typography>
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="h5" gutterBottom>
          Inactive User Handling:
        </Typography>
        <Typography variant="body1" paragraph>
          If a user has been inactive for 3 months, their data will be deleted from the system's database.
        </Typography>
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="h5" gutterBottom>
          Messaging System:
        </Typography>
        <Typography variant="body1" paragraph>
          The platform features a basic messaging system solely for communication between students and professors. Users are reminded to maintain respectful communication and refrain from sharing personal or sensitive information, as the messaging system does not currently support end-to-end encryption.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfUse;
