import React, { useState, useCallback, useEffect, useRef } from 'react'
import MetaData from '../layout/MetaData';
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../layout/loader';
import { Slider, Fab, Tooltip, Popover, Box, IconButton, Avatar, Divider, Switch, Button, Card, CardContent, Typography, Grid, Paper, CardActions, Badge } from '@mui/material';
import { Link } from 'react-router-dom';
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Swal from 'sweetalert2';
import io from "socket.io-client";
import { markSeen, userConversations } from '../../actions/chatActions';
import { getBoxStyles4 } from './chatStyles';
import { StyledBadge } from '../styles/onlineUsers';
import { minimalDate, truncateText } from './formatFunctions';


const ConvosPanel = ({ handleConversationClick, user, sentMessage, loadConvo, setLoadConvo }) => {
    const location = useLocation();
    const profData = location.state ? location.state.profData : null;
    const navigate = useNavigate();
    const listRef = useRef({});
    const rowHeights = useRef({});
    const dispatch = useDispatch();
    const { uconvo, loading: uconvoload } = useSelector(state => state.uconvos)
    const { success: markedSeen, loading: seenload } = useSelector(state => state.seen)
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [convoWProf, setConvoWProf] = useState(null);


    useEffect(() => {
        loadConversations();
    }, [])

    useEffect(() => {
        if ((sentMessage == true) || (markedSeen == true)) {
            loadConversations();
        }
    }, [sentMessage, markedSeen])

    const loadConversations = () => {
        dispatch(userConversations());
    };

    // Check if childClicked state is true, then call handleClickFromParent function
    if (loadConvo) {
        loadConversations();
        // Reset childClicked state to false after handling click
        setLoadConvo(false);
    }

    //automatically select the latest conversastion on load
    useEffect(() => {
        if (uconvo[0]?.participants?._id) {
            const latestUser = uconvo[0]?.participants?._id;
            const latestConvo = uconvo[0];
            if (!selectedUserId) {
                // on first load, default to the latest conversation
                setSelectedUserId(latestUser);
                handleConversationClick(latestConvo)
                if (latestConvo.unreadCount > 0) {
                    dispatch(markSeen(latestConvo._id))
                }
            }
            if(selectedUserId === latestConvo.participants._id){
                handleConversationClick(latestConvo)
                if (latestConvo.unreadCount > 0) {
                    dispatch(markSeen(latestConvo._id))
                }
            }
        }

    }, [uconvo])

    const selectConversation = (userId, unread, convo_id) => {
        setSelectedUserId(userId)
        if(unread > 0){
            dispatch(markSeen(convo_id))
        }
    };

    useEffect(() => {
        if (profData) {
            setConvoWProf(uconvo.find(chat =>
                chat.participants && chat.participants._id === profData._id));
        }
    }, [uconvoload])

    useEffect(() => {
        if (profData) {
            setConvoWProf(uconvo.find(chat =>
                chat.participants && chat.participants._id === profData._id));
            setSelectedUserId(profData._id);
            if (convoWProf) {
                // Check if _id is defined before accessing it
                if (convoWProf._id) {
                    handleConversationClick(convoWProf);
                }
            } else {
                handleConversationClick(profData)
            }
        }
    }
        , [profData])




    function getRowHeight(index) {
        return rowHeights.current[index] + 8 || 82;
    }

    function Row({ index, style }) {
        const rowRef = useRef({});

        useEffect(() => {
            if (rowRef.current) {
                setRowHeight(index, rowRef.current.clientHeight);
            }
            // eslint-disable-next-line
        }, [rowRef]);

        // const uconvos = uconvo[uconvo?.length - index - 1];
        const uconvos = uconvo[index];
        return (
            <div style={style} key={uconvo?._id}>


                <Box ref={rowRef} >
                    <Box sx={getBoxStyles4(selectedUserId, uconvos?.participants?._id)}
                        onClick={() => {
                            handleConversationClick(uconvos);
                            selectConversation(uconvos?.participants?._id, uconvos?.unreadCount, uconvos?._id);
                        }}
                    >
                        <StyledBadge
                            status={uconvos?.participants?.status}
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                            sx={{ mr: 2, marginRight: '20px' }}
                        >
                            <Avatar
                                sx={{ width: '40px', height: '40px' }}
                                src={uconvos?.participants?.avatar && uconvos?.participants?.avatar.url}
                                elevation={2}
                            />
                        </StyledBadge>

                        <Box sx={{ width: '75%' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography>
                                    {(uconvos?.participants?.role !== 'student' && uconvos?.participants?.role !== 'CO') && uconvos?.participants?.title && `${uconvos?.participants?.title} `} {truncateText(uconvos?.participants?.username, 20)}
                                </Typography>
                                <Typography>
                                    {(uconvos?.participants?.role === 'student' || uconvos?.participants?.role === 'CO') && uconvos?.participants?.coYeSe && ` ${uconvos?.participants?.coYeSe}`}
                                </Typography>
                            </div>

                            {/* latest message */}
                            {uconvos?.latestMessage && (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="body2" sx={{ color: '#ffffff' }}>
                                        {uconvos?.latestMessage?.sender === user?._id
                                            ? `You: ${truncateText(uconvos?.latestMessage?.message, 20)}`
                                            : uconvos?.latestMessage?.seen
                                                ? `${truncateText(uconvos?.latestMessage?.message, 20)}`
                                                : <strong>{truncateText(uconvos?.latestMessage?.message, 20)}</strong>}
                                    </Typography><Typography variant="body2" sx={{ color: '#ffffff' }}>
                                        {uconvos?.latestMessage?.sender === user?._id
                                            ? minimalDate(uconvos?.latestMessage?.sentAt)
                                            : uconvos?.latestMessage?.seen
                                                ? minimalDate(uconvos?.latestMessage?.sentAt)
                                                : <strong> {minimalDate(uconvos?.latestMessage?.sentAt)}</strong>}
                                    </Typography></div>
                            )}

                        </Box>
                        <Badge badgeContent={uconvos?.unreadCount} color="info" sx={{ marginLeft: 'auto' }} />
                    </Box>
                </Box>

            </div>
        )
    }

    function setRowHeight(index, size) {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
    }



    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ overflow: 'auto', height: '67vh' }}>
                {(profData && !convoWProf) &&
                    <Box sx={{ ...getBoxStyles4(selectedUserId, profData._id), marginBottom: 1 }}
                        onClick={() => {
                            handleConversationClick(profData);
                            selectConversation(profData._id, 0, 'none');
                        }}
                    >
                        <StyledBadge
                            status={profData?.status}
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                            sx={{ mr: 2, marginRight: '20px' }}
                        >
                            <Avatar
                                sx={{ width: '40px', height: '40px' }}
                                src={profData?.avatar && profData?.avatar.url}
                                elevation={2}
                            />
                        </StyledBadge>

                        <Box sx={{ width: '75%' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography>
                                    {(profData?.role !== 'student' && profData?.role !== 'CO') && profData?.title && `${profData?.title} `} {truncateText(profData?.username, 20)}
                                </Typography>
                                <Typography>
                                    {(profData?.role === 'student' || profData?.role === 'CO') && profData?.coYeSe && ` ${profData?.coYeSe}`}
                                </Typography>
                            </div>

                            {/* latest message */}
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" sx={{ color: '#ffffff' }}>
                                    <strong>Start A Conversation</strong>
                                </Typography></div>

                        </Box>
                    </Box>
                }
                {uconvo?.length > 0 ?
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                height={height}
                                itemCount={uconvo?.length}
                                width={width}
                                itemSize={getRowHeight}
                                ref={listRef}
                            // onItemsRendered={handleItemsRendered}
                            >
                                {Row}

                            </List>
                        )}
                    </AutoSizer>
                    :
                    (!profData ? <Typography sx={{ height: '100%', color: '#404040', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Your conversations will show here.</Typography> : null)
                }

            </Box>

        </Box>
    );
}

export default ConvosPanel