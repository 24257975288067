import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import MetaData from '../layout/MetaData'
import DashSidebar from './sidebar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { getRoomKeyDetails, updateRoomKey, clearErrors } from '../../actions/roomKeyActions'
import { FormGroup, FormControlLabel, Checkbox, Grid, Paper, Typography, IconButton, Button, TextField, Avatar, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from "react-hook-form";
import { allRoomKeys } from '../../actions/roomKeyActions';
import { UPDATE_ROOMKEY_RESET } from '../../constants/roomKeyConstants';
import Loader from '../layout/loader';
import CircularProgress from '@mui/material/CircularProgress';



const UpdateRoomKey = () => {
  const { register, handleSubmit, watch, setValue, getValues,  formState: { errors } } = useForm();
//   const onSubmit = data => // console.log(data);
//   const [program, setProgram] = useState('')
//   const [year, setYear] = useState('')
//   const [section, setSection] = useState('')

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  const { error, roomKey, isUpdated, loading } = useSelector(state => state.roomKey);
  const { id } = useParams();

  const errMsg = (message = '') => toast.error(message, {
    position: toast.POSITION.BOTTOM_CENTER
  });
  const successMsg = (message = '') => toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER
  });


  useEffect(() => {
    // // console.log(user && user._id !== userId);
    if (roomKey && roomKey._id !== id) {
      dispatch(getRoomKeyDetails(id))
    } else {
    setValue('keyNo', roomKey.keyNo);
    setValue('keyQTY', roomKey.keyQTY);
    setValue('status', roomKey.status);
    }
    if (error) {
      errMsg(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      successMsg('Room Key updated successfully')
      navigate('/admin/roomkeys')
      dispatch({
        type: UPDATE_ROOMKEY_RESET
      })
    }
  }, [dispatch, error, navigate, isUpdated, id, roomKey])


  const submitHandler = (data) => {
    dispatch(updateRoomKey(id, data))
    // // console.log('submitted data: ', updatedData);
  }

  return (
    <Fragment>
      <IconButton sx={{
        ml: 3, mb: -20,
        '&:hover': {
          // Add styles for hover state
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }} onClick={() => {
        goBack()
      }}><ArrowBackIcon sx={{
        fontSize: 35, color: '#000',
        '&:hover': {
          // Add styles for hover state
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }}></ArrowBackIcon></IconButton>
{loading ? <Loader/>: 
    <Grid container spacing={2} direction="row" justifyContent="center">
    <Grid item xs={10} lg={5} my={5}>
      <Paper elevation={12} sx={{ padding: 3 }}>
        <Typography variant="h3" sx={{ marginBottom: '50px' }}>
          <div class="thirteen">
            <h1>UDPATE ROOM KEY</h1>
          </div>
        </Typography>

        <form className="shadow-lg" onSubmit={handleSubmit(submitHandler)}>
     
         

          <Box mb={3}>
            <TextField
              id="keyno_field"
              label="Room Key Number"
              variant="outlined"
              fullWidth
              type="number"
              defaultValue={getValues('keyNo') || ''}
              {...register('keyNo', {
                required: 'Room Key Number is required',
                validate: {
                  positive: (value) => value > 0 || 'Invalid Room Key Number',
                },
              })}
            // Add validation props if needed
            />
              {errors.keyNo && (
      <Typography variant="caption" color="error">
        {errors.keyNo.message}
      </Typography>
    )}
          </Box>

          <Box mb={3}>
            <TextField
              id="keyQTY_field"
            
              label="Quantity/Number of Duplicates"
              variant="outlined"
              fullWidth
              type="number"
            //   value={section}
            defaultValue={getValues('keyQTY') || ''}
              {...register('keyQTY', {
                required: 'Quantity is required',
                validate: {
                  positive: (value) => value > 0 || 'Section must be greater than 0',
                },
              })}
            //   onChange={(e) => setSection(e.target.value)}
            />
              {errors.keyQTY && (
      <Typography variant="caption" color="error">
        {errors.keyQTY.message}
      </Typography>
    )}
          </Box>

          <Box mb={3}>
          <FormControl fullWidth>
    <InputLabel id="status-select-label">Status</InputLabel>
    <Select
      labelId="status-select-label"
      id="type-select"
    //   value={age}
      label="Status"
      defaultValue={roomKey.status}
      {...register('status', {
        required: 'Please specify the Room Key status',
      })}
    //   onChange={handleChange}
    >
         <MenuItem value={'Available'}>Available</MenuItem>
         <MenuItem value={'Missing'}>Missing</MenuItem>
         <MenuItem value={'In Use'}>In Use</MenuItem>
    
    </Select>
    {errors.status && (
      <Typography variant="caption" color="error">
        {errors.status.message}
      </Typography>
    )}
    </FormControl>
          </Box>

          <Button
             type={loading ? 'button' : 'submit'}
            variant="contained"
            fullWidth
            sx={{ py: 3, mt: 2, backgroundColor: '#3f51b5', color: 'white' }}
          >
            {loading ? <CircularProgress sx={{color: '#fff'}} size={20} /> : `UPDATE` } 
       {/* UPDATE */}
          </Button>
        </form>
      </Paper>
    </Grid>
  </Grid>
}
  
    </Fragment>
  );
};

export default UpdateRoomKey

