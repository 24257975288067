export const boxStyles1 = {
    // height: '100vh', 
    justifyContent: 'center',  // Horizontally center
    alignContent: 'center',      // Vertically center
    display: { xs: "none", md: "flex" },
    flexWrap: 'wrap',
    '& > :not(style)': {
        m: 1,
        width: '70%',
        height: '83vh',
    },
    position: 'static',
};

export const boxStyles2 = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#014422',
    color: '#fff',
    height: '100%',
};

export const boxStyles2a = {
    width: '100%', height: '15%'
};

export const typoStyle1 = {
    color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'
};

export const boxStyles2b = {
    height: '6%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#345f3c',
    marginBottom: '20px'
};

export const searchStyle = {
    color: 'rgba(255, 255, 255, 0.8)',
    '&:hover': {
        borderBottom: 'none',}
};

export const boxStyles2c = {
    height: '79%',
    m: 0.5
};
export const boxStyles3 = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#c8ecc8',
    color: '#fff',
    height: '100%',
    justifyContent: 'space-between',
};

export const boxStyles3a = {
    display: 'flex',
    backgroundColor: '#fff',
    color: '#000',
    height: '10%',
    alignItems: 'center',
    position: 'relative',
};

export const getBoxStyles4 = (selectedUserId, participantId) => ({
    height: '10vh',
    // marginX: '1px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #345f3c',
    padding: '0 15px',
    cursor: 'pointer',
    // backgroundColor: 'transparent',
    backgroundColor: selectedUserId === participantId ? '#2e5239' : 'transparent',
    '&:hover': {
      backgroundColor: '#2e5239',
    },
  });

  export const boxStyles5 = {
    backgroundColor: '#014422', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#016a35',
    },
};

export const sendButtonStyle = {
    width: '100%', height: '6vh', backgroundColor: "#014422", borderRadius: 0, '&:hover': {
        backgroundColor: "#345f3c", // Change the background color on hover
    },
};