import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import { coordinatesReducer } from "./reducers/coordinateReducers";
import {
  mySchedDeliveryReducer,
  schedDeliveryReducer,
} from "./reducers/schedDeliveryReducers";
import {
  allCoursesReducer,
  courseReducer,
  courseDetailsReducer,
} from "./reducers/courseReducers";
import {
  allBugReportsReducer,
  bugReportReducer,
} from "./reducers/bugReportReducers";
import { allRoomKeysReducer, roomKeyReducer } from "./reducers/roomKeyReducers";
import {
  createConvoReducer,
  chatSendReducer,
  markSeenReducer,
  userConversationsReducer,
  userMessagesReducer,
} from "./reducers/chatReducers";
import {
  verifyStatusReducer,
  authReducer,
  userReducer,
  forgotPasswordReducer,
  allUsersReducer,
  activeProfsReducer,
  userDetailsReducer,
} from "./reducers/userReducers";
import {
  announcementsReducer,
  newAnnouncementReducer,
  announcementReducer,
  announcementDetailsReducer,
} from "./reducers/announcementReducers";
import { chartReducer, bugReportChartReducer } from "./reducers/chartReducers";

import { myRequestReducer, requestsReducer } from "./reducers/requestReducers";
import { myNotifReducer } from "./reducers/notifReducers";
import { requestStatReducer, deliveryStatReducer, requestHistoryReducer, deliveryHistoryReducer } from "./reducers/StatReducers";
import { deliveryReducer, myDeliveryReducer } from "./reducers/deliveryReducers";

const reducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  prof: activeProfsReducer,
  verifystat: verifyStatusReducer,
  umessages: userMessagesReducer,
  uconvos: userConversationsReducer,
  convocreate: createConvoReducer,
  chat: chatSendReducer,
  seen: markSeenReducer,
  forgotPassword: forgotPasswordReducer,
  allUsers: allUsersReducer,
  userDetails: userDetailsReducer,
  allCourses: allCoursesReducer,
  course: courseReducer,
  courseDetails: courseDetailsReducer,
  bugReports: allBugReportsReducer,
  bugReport: bugReportReducer,
  announcementDetails: announcementDetailsReducer,
  announcements: announcementsReducer,
  newAnnouncement: newAnnouncementReducer,
  announcement: announcementReducer,
  mySched: mySchedDeliveryReducer,
  sched: schedDeliveryReducer,
  coord: coordinatesReducer,
  roomKeys: allRoomKeysReducer,
  roomKey: roomKeyReducer,
  chart: chartReducer,
  bugreportchart: bugReportChartReducer,
  myRequests: myRequestReducer,
  requests: requestsReducer,
  myNotifs: myNotifReducer,
  reqstat: requestStatReducer,
  reqHis: requestHistoryReducer,
  delHis: deliveryHistoryReducer,
  dlvrystat: deliveryStatReducer,
  myDeliveries: myDeliveryReducer,
  deliveries: deliveryReducer,
});

const middlware = [thunk];
const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middlware))
);

export default store;
