import * as sched from '../constants/schedDeliveryConstants'

export const mySchedDeliveryReducer = (state = { schedDeliveries: [] }, action) => {
  switch (action.type) {
    case sched.MY_SCHEDULES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case sched.MY_SCHEDULES_SUCCESS:
      return {
        ...state,
        loading: false,
        schedDeliveries: action.payload,
      };
    case sched.MY_SCHEDULES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case sched.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  success: null,
  isDeleted: null,
  schedule: {},
  isUpdated: null,
  error: null,
};

export const schedDeliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    // New Bug Report
    case sched.NEW_SCHEDULE_REQUEST:
    case sched.UPDATE_SCHEDULE_REQUEST:
    case sched.DELETE_SCHEDULE_REQUEST:
    case sched.UPDATE_STATUS_REQUEST:
      case sched.SCHEDULE_DETAILS_REQUEST:
      return { ...state, loading: true, success: null, error: null };

    case sched.NEW_SCHEDULE_SUCCESS:
      return { ...state, loading: false, success: action.payload, error: null };

    case sched.SCHEDULE_DETAILS_SUCCESS:
      return { ...state, loading: false, schedule: action.payload, error: null };


    case sched.UPDATE_SCHEDULE_SUCCESS:
      return { ...state, loading: false, isUpdated: action.payload, error: null };

    case sched.NEW_SCHEDULE_FAIL:
    case sched.UPDATE_SCHEDULE_FAIL:
    case sched.DELETE_SCHEDULE_FAIL:
    case sched.UPDATE_STATUS_FAIL:
    case sched.SCHEDULE_DETAILS_FAIL:
      return { ...state, loading: false, success: null, error: action.payload };
    case sched.CLEAR_ERRORS:
      return {
        ...state,
        error: null
      }
    case sched.DELETE_SCHEDULE_RESET:
      return {
        ...state,
        isDeleted: false
      }
      case sched.SCHEDULE_DETAILS_RESET:
        return {
          ...state,
          schedule: {}
        }
      case sched.UPDATE_SCHEDULE_RESET:
        return {
          ...state,
          isUpdated: false
        }
        case sched.NEW_SCHEDULE_RESET:
        return {
          ...state,
          success: false
        }


    case sched.DELETE_SCHEDULE_SUCCESS:
      return { ...state, loading: false, isDeleted: action.payload, error: null };
    default:
      return state;
  }
};
