import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import Home from "./components/home";
import Login from "./components/user/Login";
import Register from "./components/user/Register";
import Profile from "./components/user/Profile";
import ProtectedRoute from "./components/route/ProtectedRoute";
import UpdateProfile from "./components/user/UpdateProfile";
import NewPassword from "./components/user/NewPassword";
import UsersList from "./components/admin/userLIst";
import UpdateUser from "./components/admin/updateUser";

import Chat from "./components/chat/chat";
import Dashboard from "./components/admin/dashboard";

import CoursesList from "./components/admin/courseList";
import CreateCourse from "./components/admin/createCourse";
import UpdateCourse from "./components/admin/updateCourse";

import CreateBugReport from "./components/bugreport/createReport";
import BugReportList from "./components/admin/bugReportList";

import AnnouncementsList from "./components/admin/announcementList";
import CreateAnnouncement from "./components/admin/createAnnouncement";
import UpdateAnnouncement from "./components/admin/updateAnnouncement";

import Reports from "./components/admin/reports";

import CoordinateList from "./components/admin/coordinateList";

import RoomKeyList from "./components/admin/roomKeyList";
import CreateRoomKey from "./components/admin/createRoomKey";
import UpdateRoomKey from "./components/admin/updateRoomKey";

import UpdateCoordinate from "./components/admin/updateCoordinate";

import ScheduledDeliveries from "./components/user/scheduledDeliveries";

import { loadUser, activeProfs } from "./actions/userActions";
import { useSelector } from "react-redux";
import store from "./store";
import CreateSchedule from "./components/user/createScheduledDelivery";
import { RequestPage } from "@mui/icons-material";
import RequestItem from "./components/request/request";
import Chatting from "./components/chat/chatting";
import UpdateSchedule from "./components/user/updateScheduledDelivery";
import TermsOfUse from "./components/TermsOfUse/termsOfUse"
import RequestHistory from "./components/user/requestHistory";
import DeliveryHistory from "./components/user/deliveryHistory";
import NotFound from "./components/layout/notFound";

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  const { user, isAuthenticated, loading } = useSelector((state) => state.auth);

  return (
    <div className="App">
      <Header />
      <Routes>
      <Route path="*" element={<NotFound/>} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/terms-of-use" element={<TermsOfUse/>} />
        <Route
          path="/me"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
          exact="true"
        />
        <Route
          path="/messages"
          element={
            <ProtectedRoute>
              {/* <Chat /> */}
              <Chatting/>
            </ProtectedRoute>
          }
          exact="true"
        />
        <Route
          path="/me/update"
          element={
            <ProtectedRoute>
              <UpdateProfile />
            </ProtectedRoute>
          }
          exact="true"
        />
         <Route
          path="/change-password"
          element={
            <ProtectedRoute>
              <NewPassword />
            </ProtectedRoute>
          }
          exact="true"
        />

<Route
          path="/request-history"
          element={
            <ProtectedRoute>
              <RequestHistory />
            </ProtectedRoute>
          }
          exact="true"
        />

<Route
          path="/delivery-history"
          element={
            <ProtectedRoute>
              <DeliveryHistory />
            </ProtectedRoute>
          }
          exact="true"
        />


        {/* <Route
          path="/dashboard"
          element={
            <ProtectedRoute isAdmin={true}>
              <Dashboard />
            </ProtectedRoute>
          }
        /> */}

        <Route
          path="/admin/user/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <UpdateUser />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/users"
          element={
            <ProtectedRoute isAdmin={true}>
              <UsersList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/courses"
          element={
            <ProtectedRoute isAdmin={true}>
              <CoursesList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/course-create"
          element={
            <ProtectedRoute isAdmin={true}>
              <CreateCourse />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/course/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <UpdateCourse />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/bugreports"
          element={
            <ProtectedRoute isAdmin={true}>
              <BugReportList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/submit-bugreport"
          element={
            <ProtectedRoute>
              <CreateBugReport />
            </ProtectedRoute>
          }
          exact="true"
        />

<Route
          path="/request-item"
          element={
            <ProtectedRoute>
              <RequestItem />
            </ProtectedRoute>
          }
          exact="true"
        />

        <Route
          path="/admin/announcements"
          element={
            <ProtectedRoute isAdmin={true}>
              <AnnouncementsList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/create-announcement"
          element={
            <ProtectedRoute>
              <CreateAnnouncement />
            </ProtectedRoute>
          }
          exact="true"
        />
        <Route
          path="/admin/announcement/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <UpdateAnnouncement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/roomkeys"
          element={
            <ProtectedRoute isAdmin={true}>
              <RoomKeyList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/roomkey-create"
          element={
            <ProtectedRoute isAdmin={true}>
              <CreateRoomKey />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/roomkey/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <UpdateRoomKey />
            </ProtectedRoute>
          }
        />

<Route
          path="/update-schedule/:id"
          element={
            <ProtectedRoute isAdmin={true}>
              <UpdateSchedule />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/reports"
          element={
            <ProtectedRoute isAdmin={true}>
              <Reports />
            </ProtectedRoute>
          }
        />

<Route
          path="/my-scheduled-deliveries"
          element={  
            <ProtectedRoute isAdmin={true}>
              <ScheduledDeliveries />
              </ProtectedRoute>
          }
        />
        <Route
          path="/create-schedule"
          element={  
            <ProtectedRoute isAdmin={true}>
              <CreateSchedule />
              </ProtectedRoute>
          }
        />
       
      </Routes>



      <Footer />
      {/* {!loading && (!isAuthenticated || user.role !== 'admin') && (
        <Footer />
      )} */}
    </div>
  );
}

export default App;
