// src/pages/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Container, Box } from '@mui/material';
import { styled } from '@mui/system';

const BackgroundBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  flexDirection: 'column',
  backgroundColor: '#f0f0f0',
  textAlign: 'center',
  padding: '20px',
});

const Image = styled('img')({
  maxWidth: '400px',
  width: '100%',
  marginBottom: '20px',
});

const NotFound = () => {
  return (
    <BackgroundBox>
      <Image src="/404.jpg" alt="404 Not Found" />
      <Typography variant="h3" component="h1" gutterBottom fontWeight={"bold"}>
        Oops! Page Not Found
      </Typography>
      <Typography variant="body1" color="textSecondary" paragraph>
        The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
        Go to Home
      </Button>
    </BackgroundBox>
  );
};

export default NotFound;
