import axios from 'axios'
import * as chatconst from'../constants/chatConstants';

export const createConversation = (participants) => async (dispatch) => {
    try {
        dispatch({ type: chatconst.NEW_CONVERSATION_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }

        const { data } = await axios.post(`${process.env.REACT_APP_API}/api/v1/conversation`, { participants }, config)
        dispatch({
            type: chatconst.NEW_CONVERSATION_SUCCESS,
            payload: data.data._id
        })
        // console.log('this is data.data._id: ', data.data._id)

         // Dispatch the CLEAR_SEND_STATE action to reset the send state
        //  dispatch({ type: chatconst.NEW_CONVERSATION_RESET });
    } catch (error) {
        dispatch({
            type: chatconst.NEW_CONVERSATION_FAIL,
            payload: error.response ? error.response.data.message : 'Unknown error'
        })
    }
}



export const userConversations = () => async (dispatch) => {
    try {
    
        dispatch({ type: chatconst.USER_CONVERSATIONS_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/my-conversations`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: chatconst.USER_CONVERSATIONS_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: chatconst.USER_CONVERSATIONS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const myConversations = () => async (dispatch) => {
    try {
    
        dispatch({ type: chatconst.MY_CONVERSATIONS_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/conversations`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: chatconst.MY_CONVERSATIONS_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: chatconst.MY_CONVERSATIONS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const sendMessage = (conversationId, message) => async (dispatch) => {
    try {
        dispatch({ type: chatconst.NEW_CHAT_REQUEST });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }

        const { data } = await axios.post(`${process.env.REACT_APP_API}/api/v1/message/new`, {conversationId, message}, config);

        dispatch({
            type: chatconst.NEW_CHAT_SUCCESS,
            payload: data.success
        });

        // Dispatch the CLEAR_SEND_STATE action to reset the send state
 
    } catch (error) {
        dispatch({
            type: chatconst.NEW_CHAT_FAIL,
            payload: error.response.data.message
        });
    }
};

export const getMessages = (convoId) => async (dispatch) => {
    try {
    
        dispatch({ type: chatconst.USER_MESSAGES_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/my-messages/${convoId}`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: chatconst.USER_MESSAGES_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: chatconst.USER_MESSAGES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const markSeen = (convoId) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }
        dispatch({ type: chatconst.MARK_SEEN_REQUEST });
        const { data } = await axios.patch(`${process.env.REACT_APP_API}/api/v1/mark-seen/${convoId}`, {}, config);

        dispatch({
            type: chatconst.MARK_SEEN_SUCCESS,
            payload: data.success
        });
    } catch (error) {
        dispatch({
            type: chatconst.MARK_SEEN_FAIL,
            payload: error.response.data.message
        });
    }
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: chatconst.CLEAR_ERRORS

    })
}

