import * as dlvry from '../constants/deliveryConstants';

export const myDeliveryReducer = (state = { myDeliveries: [], Deliveries: [] }, action) => {
    switch (action.type) {
        case dlvry.MY_DELIVERIES_REQUEST:
        case dlvry.ADMIN_DELIVERIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case dlvry.MY_DELIVERIES_SUCCESS:
            return {
                ...state,
                loading: false,
                myDeliveries: action.payload,
            };
        case dlvry.ADMIN_DELIVERIES_SUCCESS:
            return {
                ...state,
                loading: false,
                Deliveries: action.payload,
            };
        case dlvry.MY_DELIVERIES_FAIL:
        case dlvry.ADMIN_DELIVERIES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case dlvry.CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

const initialState = {
    loading: false,
    success: null,
    isDeleted: null,
    isUpdated: null,
    error: null,
};

export const deliveryReducer = (state = initialState, action) => {
    switch (action.type) {
        // New Bug Report
        case dlvry.UPDATE_DELIVERYQUEUE_REQUEST:
        case dlvry.DELETE_DELIVERY_REQUEST:
        case dlvry.UPDATE_STATUS_REQUEST:
        case dlvry.UPDATE_STATUS_REQUEST:
            return { ...state, loading: true, success: null, error: null };

        case dlvry.UPDATE_DELIVERYQUEUE_SUCCESS:
            return { ...state, loading: false, isUpdated: action.payload, error: null };

        case dlvry.UPDATE_STATUS_SUCCESS:
            return { ...state, loading: false, isUpdated: action.payload, error: null };

        case dlvry.UPDATE_DELIVERYQUEUE_FAIL:
        case dlvry.DELETE_DELIVERY_FAIL:
        case dlvry.UPDATE_STATUS_FAIL:
        case dlvry.UPDATE_STATUS_FAIL:
            return { ...state, loading: false, success: null, error: action.payload };
        case dlvry.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        case dlvry.DELETE_DELIVERY_RESET:
            return {
                ...state,
                isDeleted: false
            }
        case dlvry.UPDATE_DELIVERYQUEUE_RESET:
        case dlvry.UPDATE_STATUS_RESET:
            return {
                ...state,
                isUpdated: false
            }


        case dlvry.DELETE_DELIVERY_SUCCESS:
            return { ...state, loading: false, isDeleted: action.payload, error: null };
        default:
            return state;
    }
};