import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import MetaData from '../layout/MetaData'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { addBugReport, clearErrors } from '../../actions/bugReportActions';
// import { NEW_COURSE_RESET } from '../../constants/courseConstants'
import { NEW_BUGREPORT_RESET } from '../../constants/bugReportConstants';
import { FormGroup, FormControlLabel, Checkbox,  Grid, Paper, Typography, IconButton, Button, TextField, Avatar, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from "react-hook-form";
import { ErrorSharp } from '@mui/icons-material';
import Swal from 'sweetalert2';


const CreateBugReport = () => {
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
//   const onSubmit = data => // console.log(data);
//   const [program, setProgram] = useState('')
//   const [year, setYear] = useState('')
//   const [section, setSection] = useState('')

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  const { error, success } = useSelector(state => state.bugReport);
  const errMsg = (message = '') => toast.error(message, {
    position: toast.POSITION.BOTTOM_CENTER
  });
  const successMsg = (message = '') => toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER
  });

  const issueTypes = ['Technical Difficulty', 'Latency', 'Lag', 'Glitch', 'Hardware Failure', 'App Failure', 'Bug']
  const descPres = ['App Froze', 'Laggy Performance', 'Not Responsive', 'Bot not responding', 'Bot not moving', 'Bot overheating', 'Bot Delivering to the wrong prof', 'Others please specify...']

  const selectedDescription = watch('comment');
  useEffect(() => {
    if (error) {
      errMsg(error);
      dispatch(clearErrors());
    }
    if (success) {
        Swal.fire({
            title: 'Bug Report Submitted Successfully!',
            text: 'Thank you for your feedback!',
            icon: 'success',
        }).then(() => {
            // Navigate after the SweetAlert is closed
            navigate(-1);
        });
      dispatch({
        type: NEW_BUGREPORT_RESET
      })
    }
  }, [dispatch, error, navigate, success])


  const submitHandler = (data) => {
    let updatedData = { ...data };
    if(data.comment === 'Others please specify...'){
        updatedData = { ...data, comment: data.comment1 };
        delete updatedData.comment1; 
        // // console.log('submitted data: ', updatedData);
        dispatch(addBugReport(updatedData))
    } else{
        if(data.comment1){
            delete data.comment1; 
        }
        // // console.log('submitted data: ', data);
        dispatch(addBugReport(data))
    }
    // Optionally remove the old "program" field
  
  
  
  }

  return (
    <Fragment>
      <IconButton sx={{
        ml: 3, mb: -20,
        '&:hover': {
          // Add styles for hover state
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }} onClick={() => {
        goBack()
      }}><ArrowBackIcon sx={{
        fontSize: 35, color: '#000',
        '&:hover': {
          // Add styles for hover state
          color: '#49B265',
          // Add any other styles you want to apply on hover
        },
      }}></ArrowBackIcon></IconButton>

      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item xs={10} lg={5} my={5}>
          <Paper elevation={12} sx={{ padding: 3 }}>
            <Typography variant="h3" sx={{ marginBottom: '50px' }}>
              <div class="thirteen">
                <h1>REPORT A BUG</h1>
              </div>
            </Typography>

            <form className="shadow-lg" onSubmit={handleSubmit(submitHandler)}>
         
              <Box mb={3}>
                <TextField
                  id="title_name_field"
                  label="Title"
                  variant="outlined"
                  fullWidth
                //   value={program}
                //   onChange={(e) => setProgram(e.target.value)}
                {...register('title', {
                    required: 'Title is required',
                    minLength: {
                      value: 3,
                      message: 'Title must be at least 3 characters',
                    },
                    maxLength: {
                      value: 100,
                      message: 'Title must not exceed 100 characters',
                    },
                  })}
                // Add validation props if needed
                />
                 {errors.title && (
          <Typography variant="caption" color="error">
            {errors.title.message}
          </Typography>
        )}
              </Box>

              <Box mb={3}>
              <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Type of Issue</InputLabel>
        <Select
          labelId="type-select-label"
          id="type-select"
        //   value={age}
          label="Type of Issue"
          {...register('type', {
            required: 'Please specify the type of issue',
          })}
        //   onChange={handleChange}
        >
            {issueTypes.map((issueType) => (
             <MenuItem value={issueType}>{issueType}</MenuItem>
            )    
            )}
        </Select>
        {errors.type && (
          <Typography variant="caption" color="error">
            {errors.type.message}
          </Typography>
        )}
        </FormControl>
              </Box>

              <Box mb={3}>
              <FormControl fullWidth>
        <InputLabel id="description-select-label">Description</InputLabel>
        <Select
          labelId="description-select-label"
          id="type-select"
        //   value={age}
          label="Description"
          {...register('comment', {
            required: 'Please describe the issue',
            minLength: {
                value: 3,
                message: 'Description must be at least 3 characters',
              },
              maxLength: {
                value: 500,
                message: 'Description must not exceed 500 characters',
              },
          })}
        //   onChange={handleChange}
        >
            {descPres.map((descPre) => (
             <MenuItem value={descPre}>{descPre}</MenuItem>
            )    
            )}
        </Select>
        {selectedDescription === 'Others please specify...' && ( // Conditionally render the text field
        <TextField
          id="other-description"
          label="Other Description"
          variant="outlined"
          fullWidth
          {...register('comment1',  {
            required: 'Please describe the issue',
            minLength: {
                value: 3,
                message: 'Description must be at least 3 characters',
              },
              maxLength: {
                value: 500,
                message: 'Description must not exceed 500 characters',
              },
          })} // Register the text field with react-hook-form
          sx={{ mt: 2 }}
        />
      )}
        {(errors.comment1 || errors.comment) && (
  <Typography variant="caption" color="error">
    {(errors.comment1 && errors.comment1.message) || (errors.comment && errors.comment.message)}
  </Typography>
)}
        </FormControl>
        </Box>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{ py: 3, mt: 2, backgroundColor: '#3f51b5', color: 'white' }}
              >
         SUBMIT
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CreateBugReport
