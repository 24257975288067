import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataGrid } from "@mui/x-data-grid";
import MetaData from "../layout/MetaData";
import Loader from "../layout/loader";
import { useDispatch, useSelector } from "react-redux";
import { allCoordinates, clearErrors } from "../../actions/coordinateActions";
import DashSidebar from "./sidebar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography, Box, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { COORDINATE_DETAILS_RESET } from "../../constants/coordinateConstants";
import { dGridStyle } from "../chart/dataGridStyles";

const CoordinateList = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { loading, error, coordinates } = useSelector(
    (state) => state.coord
  );
  const { user } = useSelector((state) => state.auth);
  const errMsg = (message = "") =>
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const successMsg = (message = "") =>
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  useEffect(() => {
    dispatch({type: COORDINATE_DETAILS_RESET})
    dispatch(allCoordinates());
    if (error) {
      errMsg(error);
      dispatch(clearErrors());
    }
  }, [dispatch, error,  navigate]);


  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Label",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "xAxis",
      headerName: "X - Axis",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "yAxis",
      headerName: "Y - Axis",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.6,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Link
              to={`/admin/coordinate/${params.row.id}`} 
            className="btn btn-info py-1 px-2">
            <EditIcon />
          </Link>
          {/* <IconButton
            onClick={() => deleteBugReportHandler(params.row.id)}
            className="btn btn-danger py-1 px-2 ml-2"
          >
            <DeleteIcon color="error" />
          </IconButton> */}
        </>
      ),
    },
  ];

  const rows = coordinates.map((coordinate) => ({
    id: coordinate._id,
    name: coordinate.name,
    xAxis: coordinate.xAxis,
    yAxis: coordinate.yAxis,
    createdAt: coordinate.createdAt,
    // role: user.role,
    // isValidatedUser: user.isValidatedUser,
  }));

  return (
    <>
      <div sx={{ flexGrow: 1 }}>
        <Grid container direction="row">
          <Grid item xs={2} md={2}>
            <DashSidebar />
          </Grid>
          <Grid item xs={10} md={10}>
            <Fragment>
              <Typography variant="h4" sx={{ margin: 2 }}>
                <div class="thirteen">
                  <h1>Coordinates</h1>
                </div>
              </Typography>
              {loading ? (
                <Loader />
              ) : (
                <Box sx={{ height: 500, width: "90%", margin: "auto" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    autoPageSize
                    rowsPerPageOptions={[10, 25, 50]}
                    //   checkboxSelection
                    disableSelectionOnClick
                    sx={dGridStyle}
                  />
                </Box>
              )}
            </Fragment>
          </Grid>
        </Grid>
        {/* <Link to="/submit-bugreport">
          <Fab
            color="success"
            aria-label="add"
            sx={{ position: "fixed", top: 500, right: 40, zIndex: "20" }}
          >
            <AddIcon />
          </Fab>
        </Link> */}
      </div>
    </>
  );
};

export default CoordinateList;
