import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import MetaData from "../layout/MetaData";
import Loader from "../layout/loader";
import { useDispatch, useSelector } from "react-redux";
// import { allUsers, clearErrors, deleteUser, verifyUser } from '../../actions/userActions';
import {
  allCourses,
  clearErrors,
  deleteCourse,
} from "../../actions/courseActions";
import {
  DELETE_COURSE_RESET,
  COURSE_DETAILS_RESET,
  CLEAR_ERRORS,
} from "../../constants/courseConstants";
import DashSidebar from "./sidebar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography, Box, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import { dGridStyle } from "../chart/dataGridStyles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const CoursesList = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { loading, error, courses } = useSelector((state) => state.allCourses);
  const { user } = useSelector((state) => state.auth);
  const { isDeleted, error: err1 } = useSelector((state) => state.course);
  const errMsg = (message = "") =>
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  const successMsg = (message = "") =>
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });

  useEffect(() => {
    dispatch({ type: COURSE_DETAILS_RESET });
    dispatch(allCourses());
    if (error) {
      errMsg(error);
      dispatch(clearErrors());
    }
    if (err1) {
      errMsg(err1);
      dispatch(clearErrors());
    }
    if (isDeleted) {
      Swal.fire({
        title: "Deleted!",
        text: "Course Successfully Deleted.",
        icon: "success"
      });
      navigate("/admin/courses");
      dispatch({ type: DELETE_COURSE_RESET });
    }
  }, [dispatch, error, isDeleted, navigate, err1]);

  const deleteCourseHandler = (id) => {
    Swal.fire({
      title: "Delete this Course?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCourse(id));
      }
    });
    // 
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "course",
      headerName: "Course",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "year",
      headerName: "Years to Finish",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "section",
      headerName: "Sections",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.6,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Link
            to={`/admin/course/${params.row.id}`}
            className="btn btn-info py-1 px-2"
          >
            <EditIcon />
          </Link>
          <IconButton
            onClick={() => deleteCourseHandler(params.row.id)}
            className="btn btn-danger py-1 px-2 ml-2"
          >
            <DeleteIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = courses.map((course) => ({
    id: course._id,
    course: course.course,
    year: course.year,
    section: course.section,
    // role: user.role,
    // isValidatedUser: user.isValidatedUser,
  }));

  return (
    <><MetaData title={'Courses List'}/>
      <div sx={{ flexGrow: 1 }}>
        <Grid container direction="row">
          <Grid item xs={2} md={2}>
            <DashSidebar />
          </Grid>
          <Grid item xs={10} md={10}>
            <Fragment>
              <Typography variant="h4" sx={{ margin: 2 }}>
                <div class="thirteen">
                  <h1>Courses List</h1>
                </div>
              </Typography>
              {loading ? (
                <Loader />
              ) : (
                <Box sx={{ height: 500, width: "90%", margin: "auto" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    autoPageSize
                    rowsPerPageOptions={[10, 25, 50]}
                    //   checkboxSelection
                    disableSelectionOnClick
                    sx={dGridStyle}
                    slots={{
                      toolbar: CustomToolbar,
                    }}
                  />
                </Box>
              )}
            </Fragment>
          </Grid>
        </Grid>
        <Link to="/admin/course-create">
          <Fab
            color="success"
            aria-label="add"
            sx={{ position: "fixed", top: 500, right: 40, zIndex: "20" }}
          >
            <AddIcon />
          </Fab>
        </Link>
      </div>
    </>
  );
};

export default CoursesList;
