import axios from 'axios'
import * as sched from '../constants/schedDeliveryConstants'

export const getScheduleDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: sched.SCHEDULE_DETAILS_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
       const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/schedule/${id}`, config)
        dispatch({
            type: sched.SCHEDULE_DETAILS_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: sched.SCHEDULE_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateSchedStatus = (schedId, status) => async (dispatch) => {
    try {
        dispatch({ type: sched.UPDATE_STATUS_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }

        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/schedule-status/${schedId}`, {status}, config)
        dispatch({
            type: sched.UPDATE_STATUS_SUCCESS,
            payload: data.success
        })

         // Dispatch the CLEAR_SEND_STATE action to reset the send state
         dispatch({ type: sched.UPDATE_STATUS_RESET });
    } catch (error) {
        dispatch({
            type: sched.UPDATE_STATUS_FAIL,
            payload: error.response ? error.response.data.message : 'Unknown error'
        })
    }
}

export const myScheduledDeliveries = () => async (dispatch) => {
    try {
    
        dispatch({ type: sched.MY_SCHEDULES_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/my-scheduled-deliveries`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: sched.MY_SCHEDULES_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: sched.MY_SCHEDULES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const addScheduledDelivery = (scheduleData) => async (dispatch) => {
    try {
    
        dispatch({ type: sched.NEW_SCHEDULE_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.post(`${process.env.REACT_APP_API}/api/v1/schedule/create`, scheduleData, config)
         
        dispatch({
            type: sched.NEW_SCHEDULE_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: sched.NEW_SCHEDULE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateScheduledDelivery = (scheduleId, scheduleData) => async (dispatch) => {
    try {
    
        dispatch({ type: sched.UPDATE_SCHEDULE_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/schedule/${scheduleId}`, scheduleData, config)
         
        dispatch({
            type: sched.UPDATE_SCHEDULE_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: sched.UPDATE_SCHEDULE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deleteScheduledDelivery = (scheduleId) => async (dispatch) => {
    try {
    
        dispatch({ type: sched.DELETE_SCHEDULE_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/v1/schedule/${scheduleId}`, config)
         
        dispatch({
            type: sched.DELETE_SCHEDULE_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: sched.DELETE_SCHEDULE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: sched.CLEAR_ERRORS

    })
}