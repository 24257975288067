import axios from 'axios'
import * as br from '../constants/bugReportConstants';

export const allBugReports = () => async (dispatch) => {
    try {
    
        dispatch({ type: br.ALL_BUGREPORTS_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/bug-reports`, {
            //AxiosRequestConfig parameter
            withCredentials: true //correct
          })
         
        dispatch({
            type: br.ALL_BUGREPORTS_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: br.ALL_BUGREPORTS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const addBugReport = (bugReportData) => async (dispatch) => {
    try {
    
        dispatch({ type: br.NEW_BUGREPORT_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.post(`${process.env.REACT_APP_API}/api/v1/bug-report/new`, bugReportData, config)
         
        dispatch({
            type: br.NEW_BUGREPORT_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: br.NEW_BUGREPORT_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateBugReport = (bugReportId, bugReportData) => async (dispatch) => {
    try {
    
        dispatch({ type: br.UPDATE_BUGREPORT_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/bug-report/${bugReportId}`, bugReportData, config)
         
        dispatch({
            type: br.UPDATE_BUGREPORT_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: br.UPDATE_BUGREPORT_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deleteBugReport = (bugReportId) => async (dispatch) => {
    try {
    
        dispatch({ type: br.DELETE_BUGREPORT_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true

        }
        const { data } = await axios.delete(`${process.env.REACT_APP_API}/api/v1/bug-report/${bugReportId}`, config)
         
        dispatch({
            type: br.DELETE_BUGREPORT_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: br.DELETE_BUGREPORT_FAIL,
            payload: error.response.data.message
        })
    }
}

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: br.CLEAR_ERRORS

    })
}